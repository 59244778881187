import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  Switch,
  TablePagination,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { filterData, status } from "./Data";

import { TableSearch } from "../../component/search/SearchComponent";
import { AppDispatch, RootState } from "../../store/store";
import EditIcon from "@mui/icons-material/Edit";
import ListIcon from "@mui/icons-material/List";
import Looks3Icon from "@mui/icons-material/Looks3";
import {
  UpdateClinicStatus,
  deleteClinic,
  getClinicInfo,
  getClinicListing,
} from "../../api/clinic-api/Clinic.service";
import MobileCards, { ScreenType } from "../../component/cards/MobileCards";
import "./style.css";
import { SkeletonLoadingOverlay } from "../../utils/listingSkeleton";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow placement="top" {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    fontSize: 14,
    padding: 5,
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export interface DeleteClinicdataStruc {
  EncryptedClinicId: string;
}

export interface DeleteClinicPayload {
  data: DeleteClinicdataStruc;
  callback: Function;
}

export interface UpdateStatusPayload {
  data: { encryptedClinicId: string; status: number };
  callback: Function;
}

export interface SearchClinicPayload {
  SearchText?: string;
  status?: number | string;
  PageSize?: number | string;
  PageNumber?: number | string;
  SkipPagination?: boolean;
}

export interface GetClinicInfoPayload {
  data: { EncryptedClinicId: string };
  callback: Function;
}

export interface ClinicByDocListPayload {
  data: {
    EncryptedClinicId: string | undefined;
    PageSize?: number | string;
    PageNumber?: number | string;
    LocationID?: number | string | null;
  };
  callback?: Function;
}

export const ClinicScreen = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [deleteActionData, SetDeleteActionData] = useState<any>({});
  const [searchText, setSearchText] = useState("");
  const [statusState, setStatusState] = useState<number | string>("");
  const [enableFilter, setEnambleFilter] = useState(false);
  const { clinics, totalRecords, clinicLoading, clinicStausIsLoading } =
    useSelector((state: RootState) => state.ClinicState);
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 20,
  });
  useEffect(() => {
    dispatch(
      getClinicListing({
        SearchText: searchText,
        status: statusState === 2 ? "" : statusState,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  }, [pageState]);

  //@ts-ignore
  const onSearch = (value: string) => {
    setSearchText(value);
    dispatch(
      getClinicListing({
        SearchText: value,
        status: statusState === 2 ? "" : statusState,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const statusChangeMv = (value: any) => {
    setStatusState(value);
  };
  const statusChange = (value: any) => {
    setStatusState(value);

    dispatch(
      getClinicListing({
        SearchText: searchText,
        status: value === 2 ? "" : value,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };

  const columns: GridColDef[] = [
    { field: "clinicName", headerName: "Clinic Name", width: 350 },
    {
      field: "clinicLocation",
      headerName: "Clinic Locations",
      width: 160,
      renderCell: (params: any) => {
        const locationNames = () => {
          const currentRow = params.row;
          let name = "";
          currentRow?.clinicLocations?.map((clinic: any, key: any) => {
            if (key !== 0) {
              name += ", ";
            }
            name += clinic.name;
          });
          return name;
        };
        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <LightTooltip title={locationNames()}>
              <Box
                sx={{
                  width: "20px",
                  background: "#d32f2f",
                  textAlign: "center",
                  color: "white",
                }}
              >
                {params?.row?.clinicLocations?.length}
              </Box>
            </LightTooltip>
            {/* <Tooltip title="Location 1, Location 2, Location 3"></Tooltip> */}
          </Stack>
        );
      },
    },
    {
      field: "status",
      headerName: clinicStausIsLoading ? "Loading..." : "Status",
      width: 150,
      renderCell: (params: any) => {
        const handleToggle = () => {
          let { encryptedClinicId, status } = params.row;
          let statusUpdatePayload;
          if (status === 1) {
            statusUpdatePayload = {
              callback: encryptedClinicId,
              data: { encryptedClinicId: encryptedClinicId, status: 0 },
            };
          } else {
            statusUpdatePayload = {
              callback: encryptedClinicId,
              data: {
                encryptedClinicId: encryptedClinicId,
                status: 1,
              },
            };
          }
          dispatch(UpdateClinicStatus(statusUpdatePayload));
        };

        const checkstate = () => {
          const currentRow = params.row;
          // return currentRow?.status;

          if (currentRow?.status === 0) {
            return true;
          } else if (currentRow?.status === 1) {
            return false;
          } else {
            return true;
          }
        };
        return (
          <Stack direction="row" spacing={2}>
            <Switch
              size={"small"}
              checked={checkstate()}
              onChange={handleToggle}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span>{checkstate() ? "Active" : "Inactive"}</span>
          </Stack>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,

      renderCell: (params: any) => {
        const onDelete = (e: any) => {
          const currentRow = params.row;
          SetDeleteActionData(currentRow);
          handleOpen();
        };

        const onEdit = () => {
          const currentRow = params.row;
          const navigateback = () => {
            return navigate(
              `/clinicManagement/${currentRow.encryptedClinicId}`
            );
          };
          let editClinicInfoPayload = {
            callback: navigateback,
            data: {
              EncryptedClinicId: currentRow?.encryptedClinicId,
            },
          };
          dispatch(getClinicInfo(editClinicInfoPayload));
        };

        const listDocs = () => {
          const currentRow = params.row;
          navigate(
            `/clinicManagement/listofdoctors/${currentRow.encryptedClinicId}`,
            { state: { clinicName: currentRow?.clinicName } }
          );
        };

        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title="Edit">
              <IconButton>
                <EditIcon color="error" onClick={onEdit}></EditIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton>
                <DeleteForeverIcon
                  color="error"
                  onClick={onDelete}
                ></DeleteForeverIcon>
              </IconButton>
            </Tooltip>

            {params?.row?.hasDoctorsAssigned && (
              <Tooltip title="List Doctors">
                <IconButton>
                  <ListIcon color="error" onClick={listDocs}></ListIcon>
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];
  const applyFilter = () => {
    dispatch(
      getClinicListing({
        SearchText: searchText,
        status: statusState === 2 ? "" : statusState,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };
  const resetFilter = () => {
    setSearchText("");
    setStatusState("");
    dispatch(
      getClinicListing({
        SearchText: "",
        status: "",
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };
  return (
    <Box>
      <div
        style={{
          height: isMobileView ? "100%" : "77vh",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        {isMobileView ? (
          <div className="cards-contaienr">
            <TableSearch
              onSearchFn={onSearch}
              statusData={status}
              statusState={statusState}
              statusChange={statusChange}
              searchText={searchText}
              setSearchText={setSearchText}
              componentName={"Clinic"}
              filterData={filterData}
              resetFilter={resetFilter}
              applyFilter={applyFilter}
              enableFilter={enableFilter}
              setEnambleFilter={setEnambleFilter}
              statusChangeMv={statusChangeMv}
            />

            {clinicLoading ? (
              <Stack sx={{ display: "flex", gap: "30px" }}>
                {[...Array(1)].map(() => {
                  return (
                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                      }}
                      spacing={2}
                    >
                      <div className="mobilecard-outline">
                        <div className="card-top-skeleton-outline">
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "2vh", width: "80%" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "2vh", width: "30%" }}
                          />
                        </div>

                        <div className="card-bot-skeleton-outline">
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                        </div>
                      </div>
                    </Grid>
                  );
                })}
                {[...Array(2)].map(() => {
                  return (
                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                      }}
                      spacing={2}
                    >
                      <div className="mobilecard-outline">
                        <div className="card-top-skeleton-outline">
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "2vh", width: "60%" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "2vh", width: "90%" }}
                          />
                        </div>

                        <div className="card-bot-skeleton-outline">
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                        </div>
                      </div>
                    </Grid>
                  );
                })}
                {[...Array(4)].map(() => {
                  return (
                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                      }}
                      spacing={2}
                    >
                      <div className="mobilecard-outline">
                        <div className="card-top-skeleton-outline">
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "2vh", width: "40%" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "2vh", width: "50%" }}
                          />
                        </div>

                        <div className="card-bot-skeleton-outline">
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "6vh", width: "6vh" }}
                          />
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Stack>
            ) : (
              <div className="card-scroll">
                {clinics?.length === 0 ? (
                  <Typography
                    variant="subtitle2"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "rgb(60, 60, 60)",
                    }}
                  >
                    <strong>No Records Available </strong>
                  </Typography>
                ) : (
                  clinics?.map((clinic: any) => {
                    return (
                      <>
                        <MobileCards
                          screen={ScreenType.CLINIC_MANAGEMENT}
                          data={clinic}
                          searchText={searchText}
                          statusState={statusState}
                          pageState={pageState}
                        />
                      </>
                    );
                  })
                )}
              </div>
            )}
            <div>
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={totalRecords}
                page={pageState.page - 1}
                onPageChange={(
                  event: React.MouseEvent<HTMLButtonElement> | null,
                  newPage: number
                ) => {
                  setPageState((old) => ({ ...old, page: newPage + 1 }));
                }}
                rowsPerPage={pageState.pageSize}
              />
            </div>
          </div>
        ) : (
          <DataGrid
            sx={{
              border: 1,
              borderColor: "#e0e0e07a",
              "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                outline: "none !important",
              },
              ".MuiDataGrid-columnHeader": {
                position: "unset",
              },
            }}
            rows={clinicLoading ? [] : clinics?.length > 0 ? clinics : []}
            columns={columns}
            density={"standard"}
            disableColumnMenu={true}
            components={{
              LoadingOverlay: SkeletonLoadingOverlay,
              Toolbar: TableSearch,
              NoRowsOverlay: () => (
                <Stack
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  No Records Available
                </Stack>
              ),
            }}
            disableSelectionOnClick={true}
            rowCount={totalRecords}
            rowsPerPageOptions={[]}
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            componentsProps={{
              toolbar: {
                onSearchFn: onSearch,
                statusData: status,
                statusState: statusState,
                statusChange: statusChange,
                searchText: searchText,
                setSearchText: setSearchText,
                componentName: "Clinic",
                filterData: filterData,
                resetFilter: resetFilter,
                applyFilter: applyFilter,
                enableFilter: enableFilter,
                setEnambleFilter: setEnambleFilter,
              },
            }}
            loading={clinicLoading}
          />
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>

            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>

            <div
              className="button-delete-modal-container"
              style={{ marginTop: "20px" }}
            >
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  const navigateback = () => {
                    return dispatch(
                      getClinicListing({
                        SearchText: "",
                        status: "",
                        PageSize: pageState.pageSize,
                        PageNumber: pageState.page,
                      })
                    );
                  };
                  const payloadData: DeleteClinicPayload = {
                    data: {
                      EncryptedClinicId: deleteActionData.encryptedClinicId,
                    },
                    callback: navigateback,
                  };
                  dispatch(deleteClinic(payloadData));
                  handleClose();
                  SetDeleteActionData({});
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={() => {
                  handleClose();
                  SetDeleteActionData({});
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};
