import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Button, useMediaQuery } from "@mui/material";
import { AppDispatch, RootState } from "../../store/store";
import { authLogin } from "../../api/Api.service";
import { useNavigate } from "react-router-dom";
import { toggleSnacker } from "../../domian/app/AppSlice";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import "./style.css";

var ImgWithDoc = require("../../assets/famdocwithdoc.jpg");
var ImgWithoutDoc = require("../../assets/famdoclogo.jpg");

interface Values {
  username: string;
  password: string;
}

const SignIn = () => {
  const navigate = useNavigate();

  const dispatch: AppDispatch = useDispatch();
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );

  return (
    <>
      <div className="login-container">
        {!isMobileView && (
          <div className="left-login-container">
            <img src={ImgWithDoc} alt="" className="left-login-container-img" />
          </div>
        )}

        <div className="right-login-container">
          <img
            src={ImgWithoutDoc}
            alt=""
            className="right-login-container-img"
          />
          <div className="login-form-container">
            <div style={{ padding: 30 }}>
              <Formik
                initialValues={{
                  username: "",
                  password: "",
                }}
                validate={(values) => {
                  let errors: Partial<Values> = {};
                  if (!values.username) {
                    errors.username = "Please Enter Username";
                  }
                  if (!values.password) {
                    errors.password = "Please Enter Password";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  dispatch(authLogin(values));
                }}
              >
                {({
                  values,
                  submitForm,
                  resetForm,
                  isSubmitting,
                  touched,
                  errors,
                }) => (
                  <>
                    <Form>
                      <Grid container gap={4}>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            fullWidth
                            name="username"
                            id="username"
                            label="Username"
                            variant="outlined"
                            required
                            InputLabelProps={{ shrink: true }}
                            size="small"
                          ></Field>
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            fullWidth
                            name="password"
                            id="password"
                            label="Password"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            required
                            type={"password"}
                          ></Field>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="login-form-forgot">
                            <span
                              onClick={() => {
                                navigate("./forgotpassword");
                              }}
                            >
                              Forgot Password?
                            </span>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            onClick={submitForm}
                            fullWidth
                            variant="contained"
                            disabled={isSubmitting}
                          >
                            Login
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;

// const postData = {
//   username: "rahul.balodi@classicinformatics.com",
//   password: "admin123",
// };
