import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import PageLoaderComponent from "./component/loader/PageLoaderComponent";
import { Agreement } from "./pages/agreementManagement/AgreementManagement";
import { ViewAgreement } from "./pages/agreementManagement/ViewAgreement";
import ClinicManagementAdd from "./pages/clinicManagement/add/ClinicManagementAdd";
import ClinicManagement from "./pages/clinicManagement/ClinicManagement";
import ClinicManagementEdit from "./pages/clinicManagement/edit/ClinicManagementEdit";
import ConfirmPwd from "./pages/confirmPassword/ConfirmPassword";
import { Dashboard } from "./pages/dashboard";
import { AddDoctorScreen } from "./pages/doctorManagement/AddDoctorScreen";
import { Doctor } from "./pages/doctorManagement/Doctor";
import { AddFeeScreen } from "./pages/feesManagement/add/AddFeeScreen";
import { Fee } from "./pages/feesManagement/Fee";
import HomeLayout from "./pages/home/HomeLayout";
import LoginPage from "./pages/login/SignIn";
import ResetPwd from "./pages/resetPassword/ResetPassword";
import UploadInvoice from "./pages/uploadInvoice/UploadInvoice";
import { AppDispatch, RootState } from "./store/store";
import SnackbarComponent from "./component/snackbar/SnackbarComponent";
import { useEffect, useRef, useState } from "react";
import { refreshStatePersist, triggrViewChange } from "./domian/app/AppSlice";
import "./App.css";
import { AddAgreement } from "./pages/agreementManagement/AddAgreement";
import { EditFeeScreen } from "./pages/feesManagement/edit/EditFeeScreen";
import { refreshUserData } from "./api/Api.service";
import DoctorsByClinic from "./pages/clinicManagement/listofdocs/DoctorsByClinic";
import { PersonalInformation } from "./pages/doctorDashboard/PersonalInformation";
import { UpdatePassword } from "./pages/doctorDashboard/updatePassword/UpdatePassword";
import { DoctorDashboard } from "./pages/doctorDashboard/DoctorDashboard";
import { DoctorDetail } from "./pages/doctorManagement/DoctorDetail";
import useMediaQuery from "@mui/material/useMediaQuery";

function App() {
  const [isRefreshed, setIsRefreshed] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { globalAppState } = useSelector((state: RootState) => state);
  const { isAuthenticated, authToken, refreshToken } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const isRunned = useRef(false);

  const matches = useMediaQuery("(min-width:900px)");
  useEffect(() => {
    if (!isRefreshed && authToken && refreshToken && isRunned.current) {
      const reqData = {
        accessToken: authToken,
        refreshToken: refreshToken,
      };
      dispatch(refreshUserData(reqData));
      setIsRefreshed(true);
      return;
    }
    isRunned.current = true;
  }, []);

  useEffect(() => {
    if (!matches) {
      dispatch(triggrViewChange(true));
    } else {
      dispatch(triggrViewChange(false));
    }
  }, [window.innerWidth]);

  return (
    <div className="App">
      <SnackbarComponent />
      <PageLoaderComponent />
      <div>
        {isAuthenticated ? (
          <div>
            <Routes>
              <Route path="/" element={<HomeLayout />}>
                {globalAppState?.userInfo?.Role === "2" ? (
                  <>
                    <Route index path="/" element={<ClinicManagement />} />
                    <Route
                      path="clinicManagement"
                      element={<ClinicManagement />}
                    />
                    {/* <Route path="clinicManagement/list" element={<ClinicManagement />} /> */}

                    <Route
                      path="clinicManagement/add"
                      element={<ClinicManagementAdd />}
                    />
                    <Route
                      path="/clinicManagement/:id"
                      element={<ClinicManagementEdit />}
                    />
                    <Route
                      path="/clinicManagement/listofdoctors/:id"
                      element={<DoctorsByClinic />}
                    />
                    <Route
                      path="/doctorManagement/:id"
                      element={<AddDoctorScreen />}
                    />
                    <Route
                      path="/doctorManagement/add"
                      element={<AddDoctorScreen />}
                    />
                    <Route
                      path="/doctorManagement/detail/:id"
                      element={<DoctorDetail />}
                    />

                    <Route path="doctorManagement" element={<Doctor />} />

                    <Route path="feeManagement" element={<Fee />} />
                    <Route
                      path="/feeManagement/add"
                      element={<AddFeeScreen />}
                    />
                    <Route
                      path="/feeManagement/add/:id"
                      element={<AddFeeScreen />}
                    />

                    <Route
                      path="/feeManagement/:id"
                      element={<EditFeeScreen />}
                    />
                    <Route
                      path="/agreementManagement"
                      element={<Agreement />}
                    />
                    <Route
                      path="/agreementManagement/view/:id"
                      element={<ViewAgreement />}
                    />
                    <Route
                      path="/agreementManagement/add"
                      element={<AddAgreement />}
                    />
                    <Route
                      path="/agreementManagement/add/:id"
                      element={<AddAgreement />}
                    />
                    <Route
                      path="/agreementManagement/add/:id"
                      element={<AddAgreement />}
                    />
                    <Route path="UploadInvoice" element={<UploadInvoice />} />
                    <Route path="*" element={<Dashboard />} />
                  </>
                ) : (
                  <>
                    <Route index path="/" element={<PersonalInformation />} />
                    <Route
                      index
                      path="/personalInformation"
                      element={<PersonalInformation />}
                    />
                    <Route
                      path="/ProfileSettings"
                      element={<UpdatePassword />}
                    />
                    <Route
                      path="/doctorManagement/:id"
                      element={<AddDoctorScreen />}
                    />
                    <Route path="*" element={<DoctorDashboard />} />
                  </>
                )}
              </Route>
            </Routes>
          </div>
        ) : (
          <div>
            <Routes>
              <Route path="/" element={<LoginPage />} />
              <Route path="/forgotpassword" element={<ConfirmPwd />} />
              <Route path="/resetpassword/:id" element={<ResetPwd />} />
              <Route path="*" element={<LoginPage />} />
            </Routes>
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
