import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Grid,
  Button,
  Box,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TextField } from "formik-mui";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { resetPassword, verifyResetPasswordLink } from "../../api/Api.service";
import ReportIcon from "@mui/icons-material/Report";
import "./style.css";
import { useLocation } from "react-router-dom";

var ImgWithDoc = require("../../assets/famdocwithdoc.jpg");
var ImgWithoutDoc = require("../../assets/famdoclogo.jpg");

interface Values {
  encrypted_Request_Id?: string;
  newPassword: string;
  confirmPassword: string;
}

export interface ResetPasswordPayload {
  data: Values;
  callback: Function;
}

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .required("New Password is Required")
    .min(8, "Must Be 8 Characters Or More")
    .matches(/[a-z]+/, "One Lowercase Character Is Required")
    .matches(/[A-Z]+/, "One Uppercase Character Is Required")
    .matches(
      /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/,
      "One Special Character Is Required"
    )
    .matches(/\d+/, "One Numeric Character Is Required"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is Required")
    .oneOf(
      [yup.ref("newPassword")],
      "Confirm Password Should Be Same As New Password"
    ),
});

const ResetPwd = () => {
  let { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const payloadVerify = {
      data: pathname.split("/")[2],
    };
    dispatch(verifyResetPasswordLink(payloadVerify));
  }, []);
  const { verifyResetLinkFailed } = useSelector(
    (state: RootState) => state.globalAppState
  );
  return (
    <>
      <div className="login-container">
        {!isMobileView && (
          <div className="left-login-container">
            <img src={ImgWithDoc} alt="" className="left-login-container-img" />
          </div>
        )}

        <div className="right-login-container">
          <img
            src={ImgWithoutDoc}
            alt=""
            className="right-login-container-img"
          />

          {verifyResetLinkFailed ? (
            <div className="login-form-container">
              <div className="icon-container">
                <ReportIcon style={{ fontSize: "80px", color: "brown" }} />
              </div>
              <div
                className="warning-container-text"
                style={{ fontSize: isMobileView ? "22px" : "30px" }}
              >
                Sorry, Your Link Has Expired!
              </div>

              <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                <Button
                  onClick={() => {
                    navigate("/");
                  }}
                  variant="contained"
                >
                  Back to Login
                </Button>
              </Grid>
            </div>
          ) : (
            <div className="login-form-container">
              <div className="heading-reset-pwd-container">
                <Box component="main">
                  <Stack spacing={3}>
                    <Stack direction="row" justifyContent="center" spacing={4}>
                      <Stack spacing={1}>
                        <Typography variant="h5">Reset Password</Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Box>
              </div>
              <div style={{ padding: 30 }}>
                <Formik
                  initialValues={{
                    encrypted_Request_Id: id,
                    newPassword: "",
                    confirmPassword: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(false);
                    const navigateback = () => {
                      return navigate(`/`);
                    };
                    const payloadData = {
                      data: values,
                      callback: navigateback,
                    };
                    dispatch(resetPassword(payloadData));
                  }}
                >
                  {({
                    values,
                    submitForm,
                    resetForm,
                    isSubmitting,
                    touched,
                    errors,
                  }) => (
                    <>
                      <Form>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              fullWidth
                              name="newPassword"
                              id="newPassword"
                              label="Enter your New Password"
                              type={"password"}
                              variant="outlined"
                              required
                              size="small"
                            ></Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Field
                              component={TextField}
                              fullWidth
                              name="confirmPassword"
                              id="confirmPassword"
                              label="Confirm your New Password"
                              type={"password"}
                              variant="outlined"
                              required
                              size="small"
                            ></Field>
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              onClick={submitForm}
                              fullWidth
                              variant="contained"
                              disabled={isSubmitting}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResetPwd;
