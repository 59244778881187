import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Box,
  Button,
  Container,
  Chip,
  Tooltip,
  IconButton,
  TablePagination,
  Skeleton,
  Grid,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AgreementManagementHeader from "./AgreementManagementHeader";
import { TableSearch } from "../../component/search/SearchComponent";
import { AppDispatch, RootState } from "../../store/store";
import "./styles.css";
import {
  getAgreementList,
  viewAgreementData,
} from "../../api/agreement-api/Agreement.service";
import { agreementStatus } from "./Data";
import DownloadIcon from "@mui/icons-material/Download";
import MobileCards, { ScreenType } from "../../component/cards/MobileCards";
import { SkeletonLoadingOverlay } from "../../utils/listingSkeleton";
export interface AgreementManagementPayload {
  doctor_name: string;
  agreement_status: number | string;
  PageSize: number | string;
  PageNumber: number | string;
}

export const Agreement = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { agreements, totalRecords, agreementIsLoading } = useSelector(
    (state: RootState) => state.agreementState
  );

  const { agreementUrl } = useSelector(
    (state: RootState) => state.agreementState
  );
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const [DoctorName, setDoctorName] = useState("");
  const [statusState, setStatusState] = useState<number | string>("");
  const [enableFilter, setEnambleFilter] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 20,
  });

  useEffect(() => {
    dispatch(
      getAgreementList({
        doctor_name: DoctorName,
        agreement_status: statusState,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  }, [pageState]);

  useEffect(() => {
    getAgreementList({
      doctor_name: "",
      agreement_status: "",
      PageSize: pageState.pageSize,
      PageNumber: pageState.page,
    });

    return () => {
      setStatusState("");
      setDoctorName("");
    };
  }, []);

  //@ts-ignore
  const onSearch = (value: string) => {
    setDoctorName(value);
    let statusfix =
      statusState === "completed" ? 2 : statusState === "in process" ? 1 : "";
    dispatch(
      getAgreementList({
        doctor_name: value,
        agreement_status: statusfix,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };

  const statusChangeMv = (value: string) => {
    setStatusState(value);
  };

  const statusChange = (value: string) => {
    setStatusState(value);
    let statusfix = value === "completed" ? 2 : value === "in process" ? 1 : "";
    dispatch(
      getAgreementList({
        doctor_name: DoctorName,
        agreement_status: statusfix,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };

  const addNot = (num: any) => {
    return num < 10 ? "0" + num : num;
  };

  const formatDate = (date: Date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date);
    return [addNot(d.getDate()), months[d.getMonth()], d.getFullYear()].join(
      " "
    );
  };

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Doctor Name",
      width: 300,
      renderCell: (params: any) => {
        const currentRow = params.row;
        const hrefLink = `/doctorManagement/detail/${currentRow?.encryptedDoctorId}`;
        return <Link to={hrefLink}>{currentRow.fullName}</Link>;
      },
    },
    {
      field: "agreementStatusName",
      headerName: "Agreement Status",
      width: 300,
      renderCell: (params: any) => {
        const currentRow = params.row;
        const status =
          currentRow.agreementStatusName == "completed" ? "active" : "inactive";
        return (
          <Chip
            sx={{ textTransform: "capitalize" }}
            label={currentRow.agreementStatusName}
            className={status}
          />
        );
      },
    },
    {
      field: "modifiedAt",
      headerName: "Last Updated On",
      width: 300,
      renderCell: (paramss: any) => {
        const currentRow = paramss.row;
        const date = new Date(currentRow.modifiedAt);
        //const day = date.getMonth();
        return formatDate(new Date(currentRow.modifiedAt));
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      sortable: false,
      // disableClickEventBubbling: false,

      renderCell: (params: any) => {
        const viewPdf = (agreementUrl: any) => {
          if (agreementUrl) {
            window.open(agreementUrl, "_blank", "noopener,noreferrer");
          }
        };
        const onEdit = (e: any) => {
          const currentRow = params.row;
          dispatch(
            viewAgreementData({ id: currentRow.agreementId, callback: viewPdf })
          );

          //dispatch(viewAgreement(currentRow));
          //navigate(`/agreementManagement/view/${currentRow.agreementId}`);
        };

        return (
          <Stack direction="row" spacing={2}>
            <Tooltip title="Download Agreement">
              <IconButton color="error">
                <DownloadIcon onClick={onEdit}></DownloadIcon>
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  const onClickHandle = () => {
    navigate(`/agreementManagement/add`);
  };
  const applyFilter = () => {
    let statusfix =
      statusState === "completed" ? 2 : statusState === "in process" ? 1 : "";
    dispatch(
      getAgreementList({
        doctor_name: DoctorName,
        agreement_status: statusfix,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };
  const resetFilter = () => {
    setDoctorName("");
    setStatusState("");
    dispatch(
      getAgreementList({
        doctor_name: "",
        agreement_status: "",
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };
  return (
    <Box>
      <Container maxWidth={false}>
        <Box py={2}>
          <AgreementManagementHeader onClickHandle={onClickHandle} />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "77vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          {isMobileView ? (
            <div className="cards-contaienr">
              <TableSearch
                onSearchFn={onSearch}
                statusData={agreementStatus}
                statusState={statusState}
                statusChange={statusChange}
                searchText={DoctorName}
                setSearchText={setDoctorName}
                componentName={"Doctor"}
                resetFilter={resetFilter}
                applyFilter={applyFilter}
                enableFilter={enableFilter}
                setEnambleFilter={setEnambleFilter}
                statusChangeMv={statusChangeMv}
              />

              {agreementIsLoading ? (
                <Stack sx={{ display: "flex", gap: "30px" }}>
                  {[...Array(1)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "80%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "30%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                  {[...Array(2)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "60%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "90%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                  {[...Array(4)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "40%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "50%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Stack>
              ) : (
                <div className="card-scroll">
                  {agreements?.length === 0 ? (
                    <Typography
                      variant="subtitle2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "rgb(60, 60, 60)",
                      }}
                    >
                      <strong>No Records Available </strong>
                    </Typography>
                  ) : (
                    agreements?.map((agreement: any) => {
                      return (
                        <>
                          <MobileCards
                            screen={ScreenType.AGREEMENT_MANAGEMENT}
                            data={agreement}
                            searchText={DoctorName}
                            statusState={statusState}
                            pageState={pageState}
                          />
                        </>
                      );
                    })
                  )}
                </div>
              )}

              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={totalRecords}
                page={pageState.page - 1}
                onPageChange={(
                  event: React.MouseEvent<HTMLButtonElement> | null,
                  newPage: number
                ) => {
                  setPageState((old) => ({ ...old, page: newPage + 1 }));
                }}
                rowsPerPage={pageState.pageSize}
              />
            </div>
          ) : (
            <DataGrid
              sx={{
                border: 1,
                borderColor: "#e0e0e07a",
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                  outline: "none !important",
                },
                ".MuiDataGrid-columnHeader": {
                  position: "unset",
                },
                "& .MuiDataGrid-cell a": {
                  color: "primary.main",
                },
              }}
              rows={
                agreementIsLoading
                  ? []
                  : agreements?.length > 0
                  ? agreements
                  : []
              }
              loading={agreementIsLoading}
              columns={columns}
              density={"standard"}
              disableColumnMenu={true}
              components={{
                LoadingOverlay: SkeletonLoadingOverlay,

                Toolbar: TableSearch,
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No Records Available
                  </Stack>
                ),
              }}
              disableSelectionOnClick={true}
              rowCount={totalRecords}
              rowsPerPageOptions={[]}
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              paginationMode="server"
              onPageChange={(newPage) => {
                setPageState((old) => ({ ...old, page: newPage + 1 }));
              }}
              componentsProps={{
                toolbar: {
                  onSearchFn: onSearch,
                  statusData: agreementStatus,
                  statusState: statusState,
                  statusChange: statusChange,
                  searchText: DoctorName,
                  setSearchText: setDoctorName,
                  componentName: "Doctor",
                  resetFilter: resetFilter,
                  applyFilter: applyFilter,
                  enableFilter: enableFilter,
                  setEnambleFilter: setEnambleFilter,
                },
              }}
            />
          )}
        </div>
      </Container>
    </Box>
  );
};
