import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Button,
  Card,
  Chip,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  SvgIcon,
  TextField,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import * as React from "react";
import { HiSearch } from "react-icons/hi";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { InputSearch } from "./InputSearch";
import "./style.css";
import { QuickSearchToolbarProps } from "./types";

export const SearchComponent = () => (
  <Card sx={{ p: 2 }}>
    <OutlinedInput
      defaultValue=""
      fullWidth
      placeholder="Search customer"
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon color="action" fontSize="small">
            <HiSearch />
          </SvgIcon>
        </InputAdornment>
      }
      sx={{ maxWidth: 500 }}
    />
  </Card>
);

export const LargeScreenTableSearch = (props: QuickSearchToolbarProps) => {
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const {
    componentName = "",
    NStatus,
    isFilterByClinic = false,
    enableFilter,
    setEnambleFilter,
    searchText = "",
    setSearchText,
  } = props;

  const applyFilter = () => {
    props.applyFilter();
  };
  const resetFilter = () => {
    props.resetFilter();
  };

  return (
    <Box>
      {props.DoctorByClinicCheck ? (
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <Box className={"resp-select"}>
            <TextField
              // component={TextField}
              type="text"
              name="status"
              label={componentName}
              select
              variant="outlined"
              fullWidth
              value={props.statusState}
              onChange={(e) => props.statusChange(e.target.value)}
              style={{ minWidth: "280px" }}
              size="small"
            >
              {props.statusData.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
      ) : (
        <Box p={2} className={isMobileView ? "mobileViewCls" : "normalFilter"}>
          <div>
            <InputSearch
              onSearchFn={props.onSearchFn}
              componentName={componentName}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
          <Box className={"resp-select"}>
            <TextField
              // component={TextField}
              type="text"
              name="status"
              label={`Filter By ${NStatus ? "JA Status" : "Status"}`}
              select
              variant="outlined"
              fullWidth
              value={props.statusState}
              onChange={(e) => props.statusChange(e.target.value)}
              style={{ minWidth: "180px" }}
              size="small"
            >
              {props.statusData.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          {NStatus ? (
            <Box className={"resp-select"}>
              <TextField
                // component={TextField}
                type="text"
                name="JAstatus"
                label={`Filter By Status`}
                select
                variant="outlined"
                fullWidth
                value={props.NStatusValue}
                onChange={(e) => props.handleNStatus(e.target.value)}
                style={{ minWidth: "180px" }}
                size="small"
              >
                {props.NstatusData.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          ) : (
            <></>
          )}
          {isFilterByClinic ? (
            <Box className={"resp-select"}>
              <TextField
                // component={TextField}
                type="text"
                name="clinicData"
                label={`Filter By Clinic`}
                select
                variant="outlined"
                fullWidth
                value={props.filterByClinicId}
                onChange={(e) => props.onClinicChange(e.target.value)}
                style={{ minWidth: "180px", textOverflow: "ellipsis" }}
                size="small"
              >
                {props.cliniclist?.map((option: any) => (
                  <MenuItem
                    key={option.encryptedClinicId}
                    value={option.encryptedClinicId}
                  >
                    {option.clinicName}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  );
};

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export function MobileFilter(props: QuickSearchToolbarProps) {
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    props.resetFilter();
  };
  const {
    componentName = "",
    NStatus,
    isFilterByClinic = false,
    searchText = "",
    setSearchText,
  } = props;

  const applyFilter = () => {
    setOpen(false);
    props.applyFilter();
  };

  const resetFilter = () => {
    props.resetFilter();
  };

  const isClearAllVisible = () => {
    let flag = false;
    if (!searchText) {
      if (
        !props.statusState?.toString() ||
        props.statusState?.toString() === "2" ||
        props.statusState?.toString().toLocaleLowerCase() === "none" ||
        props.statusState?.toString().toLocaleLowerCase() === "all"
      ) {
        if (
          !props.NStatusValue?.toString() ||
          props.NStatusValue?.toString().toLocaleLowerCase() === "none"
        ) {
          if (
            !props.filterByClinicId ||
            props.filterByClinicId?.toString().toLocaleLowerCase() === "all"
          ) {
            flag = false;
          } else {
            flag = true;
          }
        } else {
          flag = true;
        }
      } else {
        flag = true;
      }
    } else {
      flag = true;
    }

    return flag;
  };

  return (
    <Box>
      <Box display={"flex"} justifyContent={"space-between"} gap={2} p={1}>
        <Box>
          {!open && (
            <Box
              display={"flex"}
              gap={1}
              className="chipsClass"
              alignItems={"center"}
            >
              {props.locationByClinicLabel !== "" &&
                props.locationByClinicLabel !== undefined && (
                  <Chip label={props.locationByClinicLabel} />
                )}
              {searchText && <Chip label={searchText} />}
              {props.statusState?.toString() === "2" ||
              props.statusState?.toString().toLocaleLowerCase() === "none" ||
              props.statusState?.toString().toLocaleLowerCase() ===
                "all" ? null : props.statusState?.toString() ? (
                <Chip
                  label={
                    props.statusState?.toString() === "0"
                      ? "Active"
                      : props.statusState?.toString() === "1"
                      ? "Inactive"
                      : props.statusState
                  }
                />
              ) : null}
              {props.NStatusValue?.toString().toLocaleLowerCase() === "none"
                ? null
                : props.NStatusValue && <Chip label={props.NStatusValue} />}
              {props.filterByClinicId?.toString().toLocaleLowerCase() === "all"
                ? null
                : props.filterByClinicId && <Chip label={props.clinicName} />}
              {props.locationByClinicLabel !== "" &&
                props.locationByClinicLabel !== undefined && (
                  <>
                    <span
                      onClick={handleDelete}
                      style={{ color: "#1976d2", fontSize: "14px" }}
                    >
                      Clear Location
                    </span>
                  </>
                )}

              {isClearAllVisible() && (
                <span
                  onClick={handleDelete}
                  style={{ color: "#1976d2", fontSize: "14px" }}
                >
                  Clear All Filters
                </span>
              )}
            </Box>
          )}
        </Box>
        <IconButton>
          <FilterAltIcon
            color="error"
            onClick={() => {
              handleClickOpen();
            }}
          ></FilterAltIcon>
        </IconButton>
      </Box>

      <Dialog onClose={handleClose} open={open} fullWidth maxWidth={"sm"}>
        <DialogTitle>Select filters</DialogTitle>
        <Box
          pb={2}
          px={2}
          className={isMobileView ? "mobileViewCls" : "normalFilter"}
        >
          {props.DoctorByClinicCheckResponsive ? (
            <>
              <Box className={"resp-select"}>
                <TextField
                  // component={TextField}
                  type="text"
                  name="status"
                  label={componentName}
                  select
                  variant="outlined"
                  fullWidth
                  value={props.statusState}
                  onChange={(e) => {
                    props.DocotorChangeLocationMV(e.target.value);
                  }}
                  style={{ minWidth: "280px" }}
                  size="small"
                >
                  {props.statusData.map((option: any) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      onClick={() => {
                        props.setlocationByClinicLabel(option.label);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </>
          ) : (
            <>
              <Box className={"resp-select"}>
                <TextField
                  variant="outlined"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  value={searchText}
                  placeholder={`Search By ${componentName} Name`}
                  size="small"
                  fullWidth
                  InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    //   endAdornment: (
                    //     <IconButton
                    //       title="Clear"
                    //       aria-label="Clear"
                    //       size="small"
                    //       style={{ visibility: props.value ? "visible" : "hidden" }}
                    //       onClick={props.clearSearch}
                    //     >
                    //       <ClearIcon fontSize="small" />
                    //     </IconButton>
                    //   ),
                  }}
                />
              </Box>
              <Box className={"resp-select"}>
                <TextField
                  // component={TextField}
                  type="text"
                  name="status"
                  label={`Filter By ${NStatus ? "JA Status" : "Status"}`}
                  select
                  variant="outlined"
                  fullWidth
                  value={props.statusState}
                  onChange={(e) => props.statusChangeMv(e.target.value)}
                  style={{ minWidth: "180px" }}
                  size="small"
                >
                  {props.statusData.map((option: any) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              {NStatus ? (
                <Box className={"resp-select"}>
                  <TextField
                    // component={TextField}
                    type="text"
                    name="JAstatus"
                    label={`Filter By Status`}
                    select
                    variant="outlined"
                    fullWidth
                    value={props.NStatusValue}
                    onChange={(e) => props.handleNStatusMV(e.target.value)}
                    style={{ minWidth: "100%" }}
                    size="small"
                  >
                    {props.NstatusData.map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              ) : (
                <></>
              )}
              {isFilterByClinic ? (
                <Box className={"resp-select"}>
                  <TextField
                    // component={TextField}
                    type="text"
                    name="clinicData"
                    label={`Filter By Clinic`}
                    select
                    variant="outlined"
                    fullWidth
                    value={props.filterByClinicId}
                    onChange={(e) => props.onClinicChangeMV(e.target.value)}
                    // onChange={(e) => props.onClinicChangeMV(e.target.value)}
                    style={{ minWidth: "180px", textOverflow: "ellipsis" }}
                    size="small"
                  >
                    {props.cliniclist?.map((option: any) => (
                      <MenuItem
                        key={option.encryptedClinicId}
                        value={option.encryptedClinicId}
                        onClick={() => props.setClinicName(option.clinicName)}
                      >
                        {option.clinicName}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
          {isMobileView ? (
            <Box className={isMobileView ? "mobileViewCls" : "normalFilter"}>
              <Button variant="contained" onClick={applyFilter}>
                Apply
              </Button>
              <Button onClick={resetFilter} variant="outlined">
                Reset
              </Button>
              <Button
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Dialog>
    </Box>
  );
}

export const TableSearch = (props: QuickSearchToolbarProps) => {
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  return (
    <Box>
      {!isMobileView ? (
        <LargeScreenTableSearch {...props} />
      ) : (
        <MobileFilter {...props} />
      )}
    </Box>
  );
};
