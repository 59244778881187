import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { array, object, string, number } from "yup";
import {
  addDoctorServicesAndFees,
  feeAddAllApiResolve,
} from "../../../api/fee-management-api/Fee.service";
import { AppDispatch, RootState } from "../../../store/store";
import CustomSelect from "./CustomSelect";
import "./style.css";
import { CategorySelect } from "../../../utils/CategorySelect";
interface ServiceAndFeesArray {
  encryptedServicesIds: string[];
  fees: number | null;
}

interface ClinicLocationInterface {
  encryptedLocationIds: string;
}

export interface AddServiceAndFeeCollection {
  encryptedDoctorId: string;
  encryptedLocationIds: ClinicLocationInterface[];
  publicHolidayFee: number | null;
  defaultFee: number | null;
  afterHoursFee: number | null;
  weekendFee: number | null;
  servicesAndFees: ServiceAndFeesArray[];
}

export interface AddDocServiceFeesPayload {
  data: AddServiceAndFeeCollection;
  callback: Function;
}

const emptyServiceObject: ServiceAndFeesArray = {
  encryptedServicesIds: [],
  fees: null,
};

export function AddFeeScreen() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  let { id } = useParams();
  const [flowChecker, setFlowChecker] = useState("");

  const triggerNavigateNormal = () => {
    return navigate(`/feeManagement`);
  };
  const triggerNavigateAgreementFlow = () => {
    return navigate(`/agreementManagement/add/${id}`);
  };

  useEffect(() => {
    dispatch(feeAddAllApiResolve());
  }, []);

  const { ClinicState, feeState } = useSelector((state: RootState) => state);

  const ServiceOptions = () => {
    return feeState?.allServices?.map((e: any, i: number) => {
      return { value: e.encryptedServiceId, label: e.serviceName };
    });
  };

  const formatMultiSelectData = () => {
    return ClinicState.multiSelectClinicLoc.map((e: any) => {
      return {
        label: e.clinicName,
        value: e.encryptedClinicId,
        options: e.clinicLocations.map((e: any) => {
          return {
            value: e.encryptedLocationId,
            label: e.locationName,
          };
        }),
      };
    });
  };

  let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
  const { isMobileView } = useSelector((state: any) => state.globalAppState);

  return (
    <Box>
      <Container sx={{ p: 2, ml: 0 }} className={"fee-mobile-responsive"}>
        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color="inherit"
          mb={2}
          noWrap
        >
          Add Fee
        </Typography>
        <Formik
          initialValues={{
            ...(id === undefined
              ? { encryptedDoctorId: "" }
              : { encryptedDoctorId: id }),
            encryptedLocationIds: [],
            defaultFee: null,
            publicHolidayFee: null,
            afterHoursFee: null,
            weekendFee: null,
            servicesAndFees: [
              {
                encryptedServicesIds: [],
                fees: null,
              },
            ],
          }}
          validationSchema={object({
            encryptedDoctorId: string().required("Please Select Doctor"),
            encryptedLocationIds: array().min(
              1,
              "Please Select Atleast One Location"
            ),
            defaultFee: string()
              .test(
                "is-negative",
                "Default Fee Should Not Be Negative",
                (val: any) => {
                  if (Number(val) < 0) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "out-of-bound-1",
                "Default Fee Must Be Greater Than Or Equal To 1",
                (val: any) => {
                  if (Number(val) < 1) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "out-of-bound-100",
                "Default Fee Must Be Less Than Or Equal To 100",
                (val: any) => {
                  if (Number(val) > 100) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "is-decimal",
                "Default Fee Should Contain Only 2 Decimal Points.",
                (val: any) => {
                  if (val != undefined) {
                    return patternTwoDigisAfterComma.test(val);
                  }
                  return true;
                }
              )
              .required("Please Enter Default Fee"),
            publicHolidayFee: string()
              .test(
                "is-negative",
                "Public Holiday Fee Should Not Be Negative",
                (val: any) => {
                  if (val === null || undefined) {
                    return true;
                  } else if (Number(val) < 0) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "out-of-bound-1",
                "Public Holiday Fee Must Be Greater Than Or Equal To 1",
                (val: any) => {
                  if (val === null || undefined) {
                    return true;
                  } else if (Number(val) < 1) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "out-of-bound-100",
                "Public Holiday Fee Must Be Less Than Or Equal To 100",
                (val: any) => {
                  if (val === null || undefined) {
                    return true;
                  } else if (Number(val) > 100) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "is-decimal",
                "Public Holiday Fee Should Contain Only 2 Decimal Points.",
                (val: any) => {
                  if (val != undefined) {
                    return patternTwoDigisAfterComma.test(val);
                  }
                  return true;
                }
              )
              //@ts-ignore
              .nullable(true),
            weekendFee: string()
              .test(
                "is-negative",
                "Weekend Fee Should Not Be Negative",
                (val: any) => {
                  if (val === null || undefined) {
                    return true;
                  } else if (Number(val) < 0) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "out-of-bound-1",
                "Weekend Fee Must Be Greater Than Or Equal To 1",
                (val: any) => {
                  if (val === null || undefined) {
                    return true;
                  } else if (Number(val) < 1) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "out-of-bound-100",
                "Weekend Fee Must Be Less Than Or Equal To 100",
                (val: any) => {
                  if (val === null || undefined) {
                    return true;
                  } else if (Number(val) > 100) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "is-decimal",
                "Weekend Fee Should Contain Only 2 Decimal Points.",
                (val: any) => {
                  if (val != undefined) {
                    return patternTwoDigisAfterComma.test(val);
                  }
                  return true;
                }
              )
              //@ts-ignore
              .nullable(true),
            afterHoursFee: string()
              .test(
                "is-negative",
                "After Hours Fee Should Not Be Negative",
                (val: any) => {
                  if (val === null || undefined) {
                    return true;
                  } else if (Number(val) < 0) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "out-of-bound-1",
                "After Hours Fee Must Be Greater Than Or Equal To 1",
                (val: any) => {
                  if (val === null || undefined) {
                    return true;
                  } else if (Number(val) < 1) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "out-of-bound-100",
                "After Hours Fee Must Be Less Than Or Equal To 100",
                (val: any) => {
                  if (val === null || undefined) {
                    return true;
                  } else if (Number(val) > 100) {
                    return false;
                  }
                  return true;
                }
              )
              .test(
                "is-decimal",
                "After Hours Fee Should Contain Only 2 Decimal Points.",
                (val: any) => {
                  if (val != undefined) {
                    return patternTwoDigisAfterComma.test(val);
                  }
                  return true;
                }
              )
              //@ts-ignore
              .nullable(true),
            servicesAndFees: array().of(
              object().shape({
                encryptedServicesIds: array().test(
                  "fees-validation",
                  "Please Select The Service For The Fees Added",
                  (value, context) => {
                    if (context.parent.fees === null) {
                      return true;
                    } else if (context.parent.fees === undefined) {
                      return true;
                    } else if (
                      (context.parent.fees > 0 || context.parent.fees < 100) &&
                      context.parent.encryptedServicesIds.length > 0
                    ) {
                      return true;
                    }
                    return false;
                  }
                ),
                fees: string()
                  .test(
                    "is-negative",
                    "Fee Should Not Be Negative",
                    (val: any) => {
                      if (val === null || undefined) {
                        return true;
                      } else if (Number(val) < 0) {
                        return false;
                      }
                      return true;
                    }
                  )
                  .test(
                    "out-of-bound-1",
                    "Fee Must Be Greater Than Or Equal To 1",
                    (val: any) => {
                      if (val === null || undefined) {
                        return true;
                      } else if (Number(val) < 1) {
                        return false;
                      }
                      return true;
                    }
                  )
                  .test(
                    "out-of-bound-100",
                    "Fee Must Be Less Than Or Equal To 100",
                    (val: any) => {
                      if (val === null || undefined) {
                        return true;
                      } else if (Number(val) > 100) {
                        return false;
                      }
                      return true;
                    }
                  )
                  .test(
                    "is-decimal",
                    "Fee Should Contain Only 2 Decimal Points.",
                    (val: any) => {
                      if (val != undefined) {
                        return patternTwoDigisAfterComma.test(val);
                      }
                      return true;
                    }
                  )
                  //@ts-ignore
                  .nullable(true)
                  .test(
                    "encryptedServicesIds-validation",
                    "Please Add The Fees For The Service Selected",
                    (value, context) => {
                      if (context.parent.encryptedServicesIds.length === 0) {
                        return true;
                      } else if (
                        (context.parent.fees !== null || undefined) &&
                        (context.parent.fees > 0 ||
                          context.parent.fees < 100) &&
                        context.parent.encryptedServicesIds.length > 0
                      ) {
                        return true;
                      }
                      return false;
                    }
                  ),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);

            let formattedValue = {
              ...values,
              publicHolidayFee:
                values.publicHolidayFee === "" ? null : values.publicHolidayFee,
              afterHoursFee:
                values.afterHoursFee === "" ? null : values.afterHoursFee,
              weekendFee: values.weekendFee === "" ? null : values.weekendFee,
              servicesAndFees: values.servicesAndFees.map((e: any) => {
                if (e.fees === "") {
                  return {
                    encryptedServicesIds: e.encryptedServicesIds,
                    fees: null,
                  };
                } else {
                  return e;
                }
              }),
              encryptedLocationIds: values.encryptedLocationIds.map(
                (e: any) => {
                  return { encryptedLocationIds: e.value };
                }
              ),
            };
            const payloadData: AddDocServiceFeesPayload = {
              //@ts-ignore
              callback:
                flowChecker === "Normal"
                  ? triggerNavigateNormal
                  : triggerNavigateAgreementFlow,
              data: formattedValue,
            };

            dispatch(addDoctorServicesAndFees(payloadData));
          }}
        >
          {({ values, errors, isSubmitting, isValid }) => (
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                width: isMobileView ? "95%" : "60vw",
                ml: 0,
              }}
              className={"mobile-responsive"}
            >
              {feeState.feeAddPageLoading ? (
                <>
                  <Stack spacing={3}>
                    {[...Array(2)].map(() => (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        spacing={2}
                      >
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3vh", width: "50%" }}
                          animation={"wave"}
                        />
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3vh", width: "50%" }}
                          animation={"wave"}
                        />
                      </Grid>
                    ))}
                    <div className="add-fee-header">
                      <Typography
                        variant={isMobileView ? "h6" : "h5"}
                        color="inherit"
                        noWrap
                      >
                        Default Fees
                      </Typography>
                    </div>
                    {[...Array(2)].map(() => (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        spacing={2}
                      >
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3vh", width: "50%" }}
                          animation={"wave"}
                        />
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3vh", width: "50%" }}
                          animation={"wave"}
                        />
                      </Grid>
                    ))}
                    <div className="add-fee-header">
                      <Typography
                        variant={isMobileView ? "h6" : "h5"}
                        color="inherit"
                        noWrap
                      >
                        Flat Fees
                      </Typography>
                    </div>
                    {[...Array(3)].map(() => (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        spacing={2}
                      >
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3vh", width: "50%" }}
                          animation={"wave"}
                        />
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3vh", width: "50%" }}
                          animation={"wave"}
                        />
                      </Grid>
                    ))}
                    <div className="add-fee-header">
                      <Typography
                        variant={isMobileView ? "h6" : "h5"}
                        color="inherit"
                        noWrap
                      >
                        Service Fees
                      </Typography>
                    </div>
                    <div className="add-more-skeleton">
                      {[...Array(2)].map(() => (
                        <Grid
                          sx={{
                            gap: "30px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Skeleton
                            variant="rounded"
                            sx={{
                              height: "3vh",
                              width: "50%",
                              marginBottom: "20px",
                            }}
                            animation={"wave"}
                          />
                          <Skeleton
                            variant="rounded"
                            sx={{ height: "3vh", width: "50%" }}
                            animation={"wave"}
                          />
                        </Grid>
                      ))}
                    </div>
                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                      spacing={2}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "6vh", width: "150px" }}
                      />
                    </Grid>

                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                        marginTop: "20px",
                        marginLeft: "20px",
                      }}
                      spacing={2}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "6vh", width: "150px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "6vh", width: "150px" }}
                      />
                    </Grid>
                  </Stack>
                </>
              ) : (
                <Form autoComplete="off">
                  <Grid
                    container
                    direction="column"
                    spacing={2}
                    sx={{ width: "95%" }}
                  >
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        type="text"
                        name="encryptedDoctorId"
                        id="encryptedDoctorId"
                        label="Select Doctor"
                        disabled={id === undefined ? false : true}
                        select
                        required
                        variant="outlined"
                        fullWidth
                        size="small"
                      >
                        {feeState?.dropDownDoctors?.map((option: any) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid
                      container
                      item
                      sx={{ width: isMobileView ? "90vw" : "60vw" }}
                    >
                      <Grid item xs={12}>
                        <Field
                          component={CategorySelect}
                          type="text"
                          name="encryptedLocationIds"
                          id="encryptedLocationIds"
                          placeholder="Select A Location*"
                          options={formatMultiSelectData()}
                          isMulti={true}
                          className="custom-select"
                        />
                        <ErrorMessage
                          className="error-class-message"
                          component="div"
                          name={`encryptedLocationIds`}
                        />
                      </Grid>
                    </Grid>

                    <div className="add-fee-header">
                      <Typography
                        variant={isMobileView ? "h6" : "h5"}
                        color="inherit"
                        noWrap
                      >
                        Default Fees
                      </Typography>
                    </div>

                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="defaultFee"
                        name="defaultFee"
                        size="small"
                        label="Default Fees(%)"
                        fullWidth
                        required
                        type="number"
                        variant="outlined"
                      />
                    </Grid>

                    <div className="add-fee-header">
                      <Typography
                        variant={isMobileView ? "h6" : "h5"}
                        color="inherit"
                        noWrap
                      >
                        Flat Fees
                      </Typography>
                    </div>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="publicHolidayFee"
                        name="publicHolidayFee"
                        size="small"
                        label="Public Holiday Fees(%)"
                        fullWidth
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="afterHoursFee"
                        name="afterHoursFee"
                        size="small"
                        label="After Hour Fees(%)"
                        fullWidth
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={TextField}
                        id="weekendFee"
                        name="weekendFee"
                        size="small"
                        label="Weekend Fees(%)"
                        fullWidth
                        type="number"
                        variant="outlined"
                      />
                    </Grid>
                    <div className="add-fee-header">
                      <Typography
                        variant={isMobileView ? "h6" : "h5"}
                        color="inherit"
                        noWrap
                      >
                        Service Fees
                      </Typography>
                    </div>

                    <FieldArray name="servicesAndFees">
                      {({ push, remove }) => (
                        <React.Fragment>
                          {values?.servicesAndFees?.map((_, index) => (
                            <div className="add-more-container">
                              <div className="remove-add-more-item">
                                {values?.servicesAndFees.length > 1 ? (
                                  <span
                                    onClick={() => remove(index)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    X
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </div>
                              <Grid container item key={index} spacing={2}>
                                <Grid item xs={12}>
                                  <Field
                                    component={CustomSelect}
                                    type="text"
                                    name={`servicesAndFees[${index}].encryptedServicesIds`}
                                    id={`servicesAndFees[${index}].encryptedServicesIds`}
                                    placeholder="Select A Service"
                                    options={ServiceOptions()}
                                    className="custom-select"
                                    isMulti={true}
                                  />
                                  <ErrorMessage
                                    className="error-class-message"
                                    component="div"
                                    name={`servicesAndFees[${index}].encryptedServicesIds`}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Field
                                    fullWidth
                                    size="small"
                                    id={`servicesAndFees[${index}].fees`}
                                    name={`servicesAndFees[${index}].fees`}
                                    component={TextField}
                                    type="number"
                                    label="Fees(%)"
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          ))}

                          <Grid item>
                            {typeof errors.servicesAndFees === "string" ? (
                              <Typography color="error">
                                {errors.servicesAndFees}
                              </Typography>
                            ) : null}
                          </Grid>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginBottom: "20px",
                            }}
                          >
                            <Grid item>
                              <Button
                                disabled={isSubmitting}
                                variant="contained"
                                onClick={() => push(emptyServiceObject)}
                              >
                                <span style={{ marginRight: "3px" }}>
                                  <FaPlus size={10} />
                                </span>
                                Add More
                              </Button>
                            </Grid>
                          </div>
                        </React.Fragment>
                      )}
                    </FieldArray>

                    <div className="clinic-management-add-footer">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          paddingLeft: "15px",
                          flexWrap: "wrap",
                          marginTop: "25px",
                        }}
                      >
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setFlowChecker("Normal");
                          }}
                          startIcon={
                            isSubmitting ? (
                              <CircularProgress size="0.9rem" />
                            ) : undefined
                          }
                        >
                          {isSubmitting ? "Submitting" : "Save"}
                        </Button>
                        {id && (
                          <Button
                            disabled={isSubmitting}
                            type="submit"
                            style={{ marginLeft: "15px" }}
                            variant="contained"
                            onClick={() => {
                              setFlowChecker("Special");
                            }}
                            color="primary"
                            startIcon={
                              isSubmitting ? (
                                <CircularProgress size="0.9rem" />
                              ) : undefined
                            }
                          >
                            {isSubmitting
                              ? "Submitting"
                              : "Save & Add Agreement"}
                          </Button>
                        )}

                        <Button
                          variant="outlined"
                          color="primary"
                          style={{ marginLeft: "15px" }}
                          onClick={() => {
                            triggerNavigateNormal();
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </div>
                  </Grid>
                </Form>
              )}
            </Box>
          )}
        </Formik>
      </Container>
    </Box>
  );
}
