import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { DoctorSliceReducer } from "../pages/doctorManagement/slice/DoctorSlice";
import { FeeSliceReducer } from "../pages/feesManagement/slice/FeeSlice";
import { appSliceReducer } from "../domian/app/AppSlice";
import { ClinicSliceReducer } from "../pages/clinicManagement/slice/ClinicSlice";
import { AgreementSliceReducer } from "../pages/agreementManagement/slice/AgreementSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import logger from "redux-logger";

const persistConfig = {
  key: "root",
  storage,
};

const combinedReducer = combineReducers({
  globalAppState: appSliceReducer,
  doctorState: DoctorSliceReducer,
  ClinicState: ClinicSliceReducer,
  feeState: FeeSliceReducer,
  agreementState: AgreementSliceReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === "SIGNOUT_REQUEST") {
    // for all keys defined in your persistConfig(s)
    storage.removeItem("persist:root");
    // storage.removeItem('persist:otherKey')

    return combinedReducer(undefined, action);
  }
  return combinedReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: true,
});

export type RootState = ReturnType<typeof store.getState>; //contains the state of the whole store
export type AppDispatch = typeof store.dispatch; //use this when you need to dispatch any action in lower components
