import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { useLocation } from "react-router-dom";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MedicationIcon from "@mui/icons-material/Medication";
import AssignmentIcon from "@mui/icons-material/Assignment";
import KeyIcon from "@mui/icons-material/Key";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AppLogo from "../../assets/doctorLogo.png";
import "./style.css";

const drawerWidth = 250;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const { pathname } = location;
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            sx={{
              marginTop: 3,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon style={{ color: "brown" }} />
          </IconButton>

          <div>
            <img
              style={{ cursor: "pointer", display: !open && "none" }}
              onClick={() => {
                navigate("/");
              }}
              src={AppLogo}
              alt="none"
              className="fam-doc-logo"
            />
          </div>
          <IconButton
            onClick={handleDrawerClose}
            style={{ display: !open && "none" }}
          >
            <ChevronLeftIcon
              sx={{
                ...(!open && { display: "none" }),
              }}
            />
          </IconButton>
        </DrawerHeader>
        <Divider
          sx={{
            ...(!open && { display: "none" }),
          }}
        />
        {state.globalAppState?.userInfo?.Role === "2" ? (
          <div className="sidebar-container">
            <div
              onClick={() => {
                navigate("./clinicManagement");
              }}
              className={
                pathname.split("/").includes("clinicManagement") ||
                pathname == "/"
                  ? "sidebar-container-items active-sidebar"
                  : "sidebar-container-items"
              }
            >
              <span style={{ marginLeft: !open && "1rem" }}>
                <MedicationIcon />
              </span>
              <span style={{ display: !open && "none" }}>
                Clinic Management
              </span>
            </div>
            <div
              onClick={() => {
                navigate("./doctorManagement");
              }}
              className={
                pathname.split("/").includes("doctorManagement")
                  ? "sidebar-container-items active-sidebar"
                  : "sidebar-container-items"
              }
            >
              <span style={{ marginLeft: !open && "1rem" }}>
                <PermIdentityIcon />
              </span>
              <span style={{ display: !open && "none" }}>
                Doctor Management
              </span>
            </div>
            <div
              onClick={() => {
                navigate("./feeManagement");
              }}
              className={
                pathname.split("/").includes("feeManagement")
                  ? "sidebar-container-items active-sidebar"
                  : "sidebar-container-items"
              }
            >
              <span style={{ marginLeft: !open && "1rem" }}>
                <RequestQuoteIcon />
              </span>
              <span style={{ display: !open && "none" }}>Fees Management</span>
            </div>
            <div
              onClick={() => {
                navigate("./agreementManagement");
              }}
              className={
                pathname.split("/").includes("agreementManagement")
                  ? "sidebar-container-items active-sidebar"
                  : "sidebar-container-items"
              }
            >
              <span style={{ marginLeft: !open && "1rem" }}>
                <AssignmentIcon />
              </span>
              <span style={{ display: !open && "none" }}>
                Agreement Management
              </span>
            </div>
            {/* <div
        onClick={() => {
          navigate("./UploadInvoice");
        }}
        className="sidebar-container-items"
      >
        <span>
          <FaCog />
        </span>
        <span>Upload invoice</span>
      </div> */}
          </div>
        ) : (
          <div className="sidebar-container">
            <div
              onClick={() => {
                navigate("/personalInformation");
              }}
              className={
                pathname.split("/").includes("personalInformation")
                  ? "sidebar-container-items active-sidebar"
                  : "sidebar-container-items"
              }
            >
              <span style={{ marginLeft: !open && "1rem" }}>
                <PermIdentityIcon />
              </span>
              <span style={{ display: !open && "none" }}>
                Personal Information
              </span>
            </div>
            <div
              onClick={() => {
                navigate("/ProfileSettings");
              }}
              className={
                pathname.split("/").includes("ProfileSettings")
                  ? "sidebar-container-items active-sidebar"
                  : "sidebar-container-items"
              }
            >
              <span style={{ marginLeft: !open && "1rem" }}>
                <KeyIcon />
              </span>
              <span style={{ display: !open && "none" }}>Change Password</span>
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default Sidebar;
