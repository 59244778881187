import React from "react";
import "./style.css";
import IconButton from "@mui/material/IconButton";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import MedicationIcon from "@mui/icons-material/Medication";
import AssignmentIcon from "@mui/icons-material/Assignment";
import KeyIcon from "@mui/icons-material/Key";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Footer = () => {
  const state = useSelector((state: any) => state);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="footer-container">
      {state.globalAppState?.userInfo?.Role === "2" ? (
        <>
          <div
            onClick={() => {
              navigate("./clinicManagement");
            }}
            className={
              pathname.split("/").includes("clinicManagement") ||
              pathname == "/"
                ? "footer-container-items active-footer"
                : "footer-container-items"
            }
          >
            <MedicationIcon />
          </div>
          <div
            onClick={() => {
              navigate("./doctorManagement");
            }}
            className={
              pathname.split("/").includes("doctorManagement")
                ? "footer-container-items active-footer"
                : "footer-container-items"
            }
          >
            <PermIdentityIcon />
          </div>
          <div
            onClick={() => {
              navigate("./feeManagement");
            }}
            className={
              pathname.split("/").includes("feeManagement")
                ? "footer-container-items active-footer"
                : "footer-container-items"
            }
          >
            <RequestQuoteIcon />
          </div>
          <div
            onClick={() => {
              navigate("./agreementManagement");
            }}
            className={
              pathname.split("/").includes("agreementManagement")
                ? "footer-container-items active-footer"
                : "footer-container-items"
            }
          >
            <AssignmentIcon />
          </div>
        </>
      ) : (
        <>
          <div
            onClick={() => {
              navigate("/personalInformation");
            }}
            className={
              pathname.split("/").includes("personalInformation") ||
              pathname.split("/").includes("doctorManagement")
                ? "footer-container-items active-footer"
                : "footer-container-items"
            }
          >
            <PermIdentityIcon />
          </div>
          <div
            onClick={() => {
              navigate("/ProfileSettings");
            }}
            className={
              pathname.split("/").includes("ProfileSettings")
                ? "footer-container-items active-footer"
                : "footer-container-items"
            }
          >
            <KeyIcon />
          </div>
        </>
      )}
    </div>
  );
};

export default Footer;
