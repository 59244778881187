import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getAgreementList,
  getDoctorData,
  getDoctorDataIsotope,
  getDoctorNameList,
  viewAgreementData,
} from "../../../api/agreement-api/Agreement.service";
import {
  AgreementDoctorData,
  AgreementStateData,
  AgreementUrl,
  DoctorNameList,
} from "../types";
import { ViewAgreement } from "../ViewAgreement";

export interface DoctorList {
  encryptedId: string;
  firstName: string;
  lastName: string;
}

interface AgreementState {
  agreements: AgreementStateData[];
  doctorNameList: DoctorNameList[];
  agreementDoctorData: AgreementDoctorData;
  agreementUrl: AgreementUrl;
  editeAgreementData: AgreementStateData;
  totalRecords: number;
  agreementIsLoading: boolean;
  addAgreementIsLoading: boolean;
}

const initialState: AgreementState = {
  agreements: [],
  doctorNameList: [],
  agreementDoctorData: {},
  agreementUrl: {},
  editeAgreementData: {},
  totalRecords: 0,
  agreementIsLoading: false,
  addAgreementIsLoading: false,
};

const AgreementSlice = createSlice({
  name: "agreement",
  initialState,
  reducers: {
    agreementTableIsLoading: (state, { payload }) => {
      state.agreementIsLoading = payload;
    },
    agreementAddIsLoading: (state, { payload }) => {
      state.addAgreementIsLoading = payload;
    },
    clearAgreementDoctorDataAgreements: (state) => {
      //state.agreements = state.agreements;
      state.agreementDoctorData = {};
    },
    searchAgreements: (state, { payload }) => {
      state.agreements = payload;
    },
    addAgreement: (state, { payload }) => {
      /* state.agreements = [...state.agreements, payload];
      state.isSnackbarOpen = true;
      state.snackbarMsg = "sucessfully Added"; */
    },
    deleteAgreement: (state, { payload }) => {
      const newData = state.agreements.filter(
        (e: AgreementStateData, i: number) => {
          return payload !== e.id;
        }
      );
      state.agreements = newData;
    },
    editAgreement: (state, { payload }) => {
      state.editeAgreementData = payload;
    },
    viewAgreement: (state, { payload }) => {
      state.editeAgreementData = payload;
    },
    clearAgreement: (state) => {
      state.editeAgreementData = {};
    },
    closeSnackbar: (state) => {},
    updateAgreement: (state, { payload }) => {
      const newData = state.agreements.map(
        (e: AgreementStateData, i: number) => {
          return payload.id === e.id ? payload : e;
        }
      );
      state.agreements = newData;
    },
    clearAgreements: (state) => {
      state.agreements = [];
    },
    SignoutTrigger: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgreementList.fulfilled, (state, { payload }) => {
        state.totalRecords = payload?.totalRecords;
        state.agreements = payload?.data?.map((e: any, i: any) => ({
          id: i,
          ...e,
        }));
      })
      .addCase(getDoctorNameList.fulfilled, (state, { payload }) => {
        state.doctorNameList = payload;
      })
      .addCase(getDoctorData.fulfilled, (state, { payload }) => {
        if (payload === null) {
          state.agreementDoctorData = {};
        } else {
          state.agreementDoctorData = payload;
        }
      })
      .addCase(getDoctorDataIsotope.fulfilled, (state, { payload }) => {
        if (payload === null) {
          state.agreementDoctorData = {};
        } else {
          state.agreementDoctorData = payload;
        }
      })
      .addCase(viewAgreementData.fulfilled, (state, { payload }) => {
        state.agreementUrl = payload;
      });
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(deleteTodo.pending, (state) => {

  //     })
  //     .addCase(deleteTodo.fulfilled, (state, action) => {

  //     })
  //     .addCase(deleteTodo.rejected, (state, action) => {

  //     });
  //   }
});

export const AgreementSliceReducer = AgreementSlice.reducer;
export const {
  addAgreement,
  clearAgreements,
  deleteAgreement,
  updateAgreement,
  editAgreement,
  clearAgreement,
  clearAgreementDoctorDataAgreements,
  searchAgreements,
  closeSnackbar,
  viewAgreement,
  agreementAddIsLoading,
  agreementTableIsLoading,
} = AgreementSlice.actions;
