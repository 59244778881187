import { createAsyncThunk } from "@reduxjs/toolkit";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import {
  CHANGE_DOCTOR_STATUS,
  DELETE_DOCTOR,
  GET_ALL_DOCTORS,
  GET_DOCTOR,
  SEARCH_DOCTOR,
  UPDATE_DOCTOR,
  GET_DOCTORS_NAME,
  UPDATE_DOCTOR_PASSWORD,
  GET_DOCTOR_DETAIL,
} from "../ActionConstants";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import API from "../Api.utils";
import {
  doctorEditIsLoading,
  doctorListIsLoading,
  doctorPersonalInfoLoading,
  doctorStatusIsLoading,
  doctorStatusToggle,
  doctorTableIsLoading,
  passwordIsUpdating,
} from "../../pages/doctorManagement/slice/DoctorSlice";
import { getStates } from "../clinic-api/Clinic.service";

export const getDoctorListing = createAsyncThunk(
  GET_ALL_DOCTORS,
  async (_, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(doctorTableIsLoading(true));
      const response = await API.get(`doctor/GetAllDoctors`, { signal });
      const data = response.data;
      // dispatch(
      //   toggleSnacker(
      //     successOpen(
      //       data?.responseMessage ? data?.responseMessage : "sucessfully done"
      //     )
      //   )
      // );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Doctors Details ...!"
          )
        )
      );
      dispatch(doctorTableIsLoading(false));
      return err;
    } finally {
      dispatch(doctorTableIsLoading(false));
    }
  }
);
export const getDoctorData = createAsyncThunk(
  GET_DOCTOR,
  async (doctorData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(doctorPersonalInfoLoading(true));
      const response = await API.get(
        `doctor/GetDoctor?UserID=${doctorData.encrypted_ID}`,
        {
          signal,
        }
      );
      let data;
      if (response.data) {
        data = response.data.result[0];
      } else {
        data = response.data;
      }

      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Doctor Details ...!"
          )
        )
      );
      dispatch(doctorPersonalInfoLoading(false));
      return err;
    } finally {
      dispatch(doctorPersonalInfoLoading(false));
    }
  }
);
export const getDoctorDetail = createAsyncThunk(
  GET_DOCTOR_DETAIL,
  async (doctorId: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(doctorListIsLoading(true));
      const response = await API.get(
        `doctor/Doctordetails?EncryptedDoctorID=${doctorId}`,
        {
          signal,
        }
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Getting Doctor Details ...!"
          )
        )
      );
      dispatch(doctorListIsLoading(false));

      return err;
    } finally {
      dispatch(doctorListIsLoading(false));
    }
  }
);
export const updateDoctor = createAsyncThunk(
  UPDATE_DOCTOR,
  async (updateData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(`doctor/EditDoctor`, updateData, {
        signal,
      });
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Doctor Information Updated Sucessfully"
          )
        )
      );
      updateData.callback();
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Updating Doctor Information, Please Try Again...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const deleteDoctor = createAsyncThunk(
  DELETE_DOCTOR,
  async ({ userId, callback }: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response: any = await API.delete(
        `doctor/DeleteDoctor?UserID=${userId}`,
        {
          signal,
        }
      );
      const data = response.data;
      callback();
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Doctor Deleted Sucessfully"
          )
        )
      );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Deleting Doctor, Please Try Again...!"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const searchDoctor = createAsyncThunk(
  SEARCH_DOCTOR,
  async (searchParams: any, thunkApi) => {
    let clinicID = searchParams?.clinicID === "all" ? "" : searchParams.clinicID
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(doctorTableIsLoading(true));

      const url =
        searchParams.Status !== 2
          ? `doctor/Filterdoctor?Firstname=${searchParams.firstName}&JAStatus=${searchParams.JAStatus}&Status=${searchParams.Status}&clinicID=${clinicID}&PageNumber=${searchParams.PageNumber}&PageSize=${searchParams.PageSize}`
          : `doctor/Filterdoctor?Firstname=${searchParams.firstName}&JAStatus=${searchParams.JAStatus}&clinicID=${clinicID}&PageNumber=${searchParams.PageNumber}&PageSize=${searchParams.PageSize}`;
      // searchParams.key === "firstName"
      //   ? `doctor/Filterdoctor?Firstname=${searchParams.firstName}`
      //   : `doctor/Filterdoctor?JAStatus=${searchParams.JAStatus}`;
      const response = await API.get(url);
      const data = response.data;
      // dispatch(
      //   toggleSnacker(
      //     successOpen(
      //       data?.responseMessage ? data?.responseMessage : "sucessfully done"
      //     )
      //   )
      // );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Searching Doctors Details...!"
          )
        )
      );
      dispatch(doctorTableIsLoading(false));
      return err;
    } finally {
      dispatch(doctorTableIsLoading(false));
    }
  }
);
export const changeStatus = createAsyncThunk(
  CHANGE_DOCTOR_STATUS,
  async (statusData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(doctorStatusIsLoading(true));
      const response = await API.post(`doctor/ChangeStatus`, statusData, {
        signal,
      });
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Doctor Status Updated Sucessfully"
          )
        )
      );
      dispatch(doctorStatusToggle(statusData.callback));
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Updating Doctor Status, Please Try Again...!"
          )
        )
      );
      dispatch(doctorStatusIsLoading(false));
      return err;
    } finally {
      dispatch(doctorStatusIsLoading(false));
    }
  }
);

export const getDoctorsName = createAsyncThunk(
  GET_DOCTORS_NAME,
  async (_, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const response = await API.get(`doctor/GetDoctorsName`, { signal });
      const data = response.data;
      return data;
    } catch (error) {
      dispatch(
        toggleSnacker(
          errorOpen(
            "Error While Fetching Doctors Details, Please Try Again...!"
          )
        )
      );

      return error;
    } finally {
    }
  }
);
export const updatePassword = createAsyncThunk(
  UPDATE_DOCTOR_PASSWORD,
  async (updateData: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(passwordIsUpdating(true));
      const response = await API.post(
        `doctor/ChangeDoctorPassword`,
        updateData,
        {
          signal,
        }
      );
      const data = response.data;
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Doctor Password Updated Sucessfully"
          )
        )
      );
      return data;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Updating Password ...!"
          )
        )
      );
      dispatch(passwordIsUpdating(false));
      return err;
    } finally {
      dispatch(passwordIsUpdating(false));
    }
  }
);

export const dotorEditAllApiResolve = createAsyncThunk(
  "DOCTOR_EDIT_API",
  async (payload: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(doctorEditIsLoading(true));
      payload.callback(payload.encrypted_ID);
      await Promise.all([
        dispatch(getDoctorData(payload)),
        // dispatch(getStates()),
      ]);
    } catch (error) {
      dispatch(doctorEditIsLoading(false));
      return [];
    } finally {
      dispatch(doctorEditIsLoading(false));
    }
  }
);
