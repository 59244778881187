import { Outlet } from "react-router-dom";
import "./style.css";
import Navbar from "../../component/navbar/Navbar";
import Sidebar from "../../component/sidebar/Sidebar";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Footer from "../../component/footer/Footer";

const Home = () => {
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );

  return (
    <div
      className="home-container"
      style={{ flexDirection: !isMobileView ? "row" : "column" }}
    >
      <>
        <div className="home-hero-container-left">
          {!isMobileView && <Sidebar />}
        </div>
        <div className="home-hero-container-right">
          <div className="home-hero-container-right-top">
            <Navbar />
          </div>
          <div className="home-hero-container-right-bottom">
            <Outlet />
          </div>
        </div>
      </>
      {isMobileView && (
        <div className="footer">
          <Footer />
        </div>
      )}
    </div>
  );
};

export default Home;
