import AddIcon from "@mui/icons-material/Add";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const FeeManagementHeader = ({ onClickHandle }: any): JSX.Element => {
  const { isMobileView } = useSelector((state: any) => state.globalAppState);

  return (
    <div>
      <Box component="main">
        <Stack spacing={3}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"baseline"}
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant={isMobileView ? "h5" : "h4"}>
                Fee Management
              </Typography>
            </Stack>

            {isMobileView ? (
              <div
                style={{
                  borderRadius: "50px",
                  backgroundColor: "brown",
                  height: "35px",
                  width: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 0px 2px 1px black",
                }}
                onClick={onClickHandle}
              >
                <AddIcon fontSize="small" style={{ color: "white" }}></AddIcon>
              </div>
            ) : (
              <Button
                variant="contained"
                sx={{ pl: 1 }}
                onClick={onClickHandle}
              >
                <AddIcon fontSize="small"></AddIcon>
                Add
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default FeeManagementHeader;
