import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import Switch from "@mui/material/Switch";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import "./style.css";
import {
  GetDoctorByClinicApiResolve,
  getDoctorsByClinic,
} from "../../../api/clinic-api/Clinic.service";
import { SkeletonLoadingOverlay } from "../../../utils/listingSkeleton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MobileCards, { ScreenType } from "../../../component/cards/MobileCards";
import { TableSearch } from "../../../component/search/SearchComponent";

const DoctorsByClinic = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const {
    doctorsByClinic,
    totalRecordsOnDoc,
    clinicLoading,
    locationsByClinic,
  } = useSelector((state: RootState) => state.ClinicState);

  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 20,
  });

  const [locationByClinic, setlocationByClinic] = useState("");
  const [locationByClinicLabel, setlocationByClinicLabel] = useState("");

  useEffect(() => {
    dispatch(
      GetDoctorByClinicApiResolve({
        data: {
          EncryptedClinicId: id,
          PageSize: pageState.pageSize,
          PageNumber: pageState.page,
          LocationID: locationByClinic === "All" ? "" : locationByClinic,
        },
      })
    );
  }, [pageState]);

  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Doctor Name",
      width: 300,
      renderCell: (params: any) => {
        const currentRow = params.row;
        const hrefLink = `/doctorManagement/detail/${currentRow?.encryptedDoctorID}`;
        return <Link to={hrefLink}>{currentRow.fullName}</Link>;
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 250,
      renderCell: (params: any) => {
        const currentRow = params.row;
        return <p>{currentRow.locationName}</p>;
      },
    },
    {
      field: "jAstatus",
      headerName: "JA Status",
      width: 250,
      renderCell: (params: any) => {
        const currentRow = params.row;
        return <p>{currentRow.jAstatus}</p>;
      },
    },
    {
      field: "is_form_filled",
      headerName: "Form Filled",
      width: 250,
      renderCell: (params: any) => {
        const currentRow = params.row;
        return (
          <span>
            {currentRow?.is_form_filled ? <p>Filled</p> : <p>Not Filled</p>}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      renderCell: (params: any) => {
        return (
          <Stack direction="row" spacing={2}>
            <p>{params.row?.status === true ? "Active" : "Inactive"}</p>
          </Stack>
        );
      },
    },
  ];

  function addDots(str: any) {
    if (str.length > 30) {
      return (
        <Tooltip title={str} placement="top">
          <span>{str.slice(0, 30) + "..."}</span>
        </Tooltip>
      );
    } else {
      return str;
    }
  }
  const statusChange = (value: any) => {
    let checker = "";
    if (value === "All") {
      setlocationByClinic("All");
      checker = "";
    } else {
      setlocationByClinic(value);
      checker = value;
    }
    dispatch(
      getDoctorsByClinic({
        data: {
          EncryptedClinicId: id,
          PageSize: pageState.pageSize,
          PageNumber: pageState.page,
          LocationID: checker,
        },
      })
    );
  };

  const resetFilter = () => {
    setlocationByClinicLabel("");
    dispatch(
      getDoctorsByClinic({
        data: {
          EncryptedClinicId: id,
          PageSize: pageState.pageSize,
          PageNumber: pageState.page,
          LocationID: "",
        },
      })
    );
  };

  const applyFilter = () => {
    dispatch(
      getDoctorsByClinic({
        data: {
          EncryptedClinicId: id,
          PageSize: pageState.pageSize,
          PageNumber: pageState.page,
          LocationID: locationByClinic,
        },
      })
    );
  };

  return (
    <Box>
      <Container maxWidth={false}>
        <Box py={2}>
          <Box component="main">
            <Stack spacing={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={4}
                pl={2}
                pr={1}
              >
                <Box sx={{ width: "60vw" }}>
                  <Typography
                    component="span"
                    variant={isMobileView ? "h6" : "h4"}
                  >
                    <Box
                      sx={{
                        display: !isMobileView ? "inline-flex" : "block",
                        wordBreak: isMobileView ? "break-word" : "keep-all",
                        textTransform: "capitalize",
                      }}
                    >
                      {"List of Doctors in "}
                    </Box>
                    <Box
                      sx={{
                        ml: isMobileView ? 0 : 1,
                        display: !isMobileView ? "inline-flex" : "block",
                        wordBreak: isMobileView ? "break-word" : "keep-all",

                        color: (theme) => "firebrick",
                      }}
                    >
                      {addDots(location?.state?.clinicName)}
                    </Box>
                  </Typography>
                </Box>

                {isMobileView ? (
                  <div
                    style={{
                      borderRadius: "50px",
                      backgroundColor: "brown",
                      height: "35px",
                      width: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeftIcon
                      fontSize={"medium"}
                      sx={{ color: "white" }}
                    ></ChevronLeftIcon>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    sx={{
                      pl: 1,
                    }}
                    onClick={() => navigate(-1)}
                  >
                    <ChevronLeftIcon fontSize={"medium"}></ChevronLeftIcon>
                    Back
                  </Button>
                )}
              </Stack>
            </Stack>
          </Box>
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "77vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          {isMobileView ? (
            <div className="cards-contaienr">
              <TableSearch
                DoctorByClinicCheckResponsive={true}
                componentName={"Filter By Locations"}
                statusData={locationsByClinic}
                DocotorChangeLocationMV={setlocationByClinic}
                CurrentDoctorLocationMV={locationByClinic}
                setlocationByClinicLabel={setlocationByClinicLabel}
                locationByClinicLabel={locationByClinicLabel}
                resetFilter={resetFilter}
                applyFilter={applyFilter}
              />

              {clinicLoading ? (
                <Stack sx={{ display: "flex", gap: "30px" }}>
                  {[...Array(1)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "80%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "30%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                  {[...Array(2)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "60%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "90%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Stack>
              ) : (
                <div className="card-scroll">
                  {doctorsByClinic?.length === 0 ? (
                    <Typography
                      variant="subtitle2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "rgb(60, 60, 60)",
                      }}
                    >
                      <strong>No Records Available </strong>
                    </Typography>
                  ) : (
                    doctorsByClinic?.map((clinicbydoc: any) => {
                      return (
                        <>
                          <MobileCards
                            screen={ScreenType.CLINIC_BY_DOC}
                            data={clinicbydoc}
                            pageState={pageState}
                          />
                        </>
                      );
                    })
                  )}
                </div>
              )}
              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={totalRecordsOnDoc}
                page={pageState.page - 1}
                onPageChange={(
                  event: React.MouseEvent<HTMLButtonElement> | null,
                  newPage: number
                ) => {
                  setPageState((old) => ({ ...old, page: newPage + 1 }));
                }}
                rowsPerPage={pageState.pageSize}
              />
            </div>
          ) : (
            <DataGrid
              sx={{
                border: 1,
                borderColor: "#e0e0e07a",
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                  outline: "none !important",
                },
                ".MuiDataGrid-columnHeader": {
                  position: "unset",
                },
                "& .MuiDataGrid-cell a": {
                  color: "primary.main",
                },
              }}
              rows={clinicLoading ? [] : doctorsByClinic}
              loading={clinicLoading}
              columns={columns}
              density={"standard"}
              components={{
                LoadingOverlay: SkeletonLoadingOverlay,
                Toolbar: TableSearch,
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No Records Available
                  </Stack>
                ),
              }}
              disableColumnMenu={true}
              disableSelectionOnClick={true}
              rowCount={totalRecordsOnDoc}
              rowsPerPageOptions={[]}
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              paginationMode="server"
              onPageChange={(newPage) => {
                setPageState((old) => ({ ...old, page: newPage + 1 }));
              }}
              componentsProps={{
                toolbar: {
                  ClinicByDoctorData: "",
                  componentName: "Filter by Clinic Locations",
                  DoctorByClinicCheck: true,
                  statusData: locationsByClinic,
                  statusChange: statusChange,
                  // resetFilter: resetFilter,
                  // applyFilter: applyFilter,
                  // enableFilter: enableFilter,
                  // setEnambleFilter: setEnambleFilter,
                },
              }}
            />
          )}
        </div>
      </Container>
    </Box>
  );
};

export default DoctorsByClinic;
