import React from "react";
import "./style.css";

import {
  AgreementMobileCompoennt,
  ClinicByDocComponent,
  ClinicMobileCompoennt,
  DoctorMobileCompoennt,
  FeeManagementMobileCompoennt,
} from "./Data";

export enum ScreenType {
  CLINIC_MANAGEMENT = "CLINIC_MANAGEMENT",
  DOCTOR_MANAGEMENT = "DOCOTR_MANAGEMENT",
  FEE_MANAGEMENT = "FEE_MANAGEMENT",
  AGREEMENT_MANAGEMENT = "AGREEMENT_MANAGEMENT",
  CLINIC_BY_DOC = "CLINIC_BY_DOC",
}

const MobileCards = (data: any) => {
  return (
    <div className="mobile-card-container">
      {(() => {
        switch (data.screen) {
          case ScreenType.CLINIC_MANAGEMENT:
            return (
              <ClinicMobileCompoennt
                clinic={data.data}
                searchText={data.searchText}
                statusState={data.statusState}
                pageState={data.pageState}
              />
            );
          case ScreenType.DOCTOR_MANAGEMENT:
            return (
              <DoctorMobileCompoennt
                doctordata={data.data}
                searchText={data.searchText}
                statusState={data.statusState}
                pageState={data.pageState}
                NStatusValue={data.NStatusValue}
                filterByClinicId={data.filterByClinicId}
              />
            );
          case ScreenType.FEE_MANAGEMENT:
            return (
              <FeeManagementMobileCompoennt
                feedata={data.data}
                searchText={data.searchText}
                statusState={data.statusState}
                pageState={data.pageState}
              />
            );
          case ScreenType.AGREEMENT_MANAGEMENT:
            return (
              <AgreementMobileCompoennt
                agreementsdata={data.data}
                searchText={data.searchText}
                statusState={data.statusState}
                pageState={data.pageState}
              />
            );
          case ScreenType.CLINIC_BY_DOC:
            return (
              <ClinicByDocComponent
                clinicByDoc={data.data}
                pageState={data.pageState}
              />
            );
          default:
            return null;
        }
      })()}
    </div>
  );
};

export default MobileCards;
