import { Box, Container, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
export const Dashboard = ():JSX.Element => {
  return (
    <Box>
      <Container maxWidth={false}>
        <Box mt={5}>
            <Typography variant="body2">Welcome Admin</Typography>
          </Box>  
      </Container>
    </Box>
  )
};

