import React from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ClinicScreen } from "./ClinicScreen";
import "./style.css";
import ClinicManagementHeader from "./ClinicManagementHeader";

const ClinicManagement = () => {
  const navigate = useNavigate();
  const onClickHandle = () => {
    navigate(`/clinicManagement/add`);
  };
  return (
    <>
      <Box component="main">
        <Container maxWidth={false}>
          <Box py={2}>
            <ClinicManagementHeader onClickHandle={onClickHandle} />
          </Box>
          <ClinicScreen />
        </Container>
      </Box>
    </>
  );
};

export default ClinicManagement;
