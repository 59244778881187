import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Switch from "@mui/material/Switch";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import {
  changeStatus,
  deleteDoctor,
  dotorEditAllApiResolve,
  searchDoctor,
} from "../../api/doctor-api/Api.service";
import { TableSearch } from "../../component/search/SearchComponent";
import { AppDispatch, RootState } from "../../store/store";
import { NstatusData, status } from "./Data";
import DoctorManagementHeader from "./DoctorManagementHeader";
import "./styles.css";

import { RxCross2 } from "react-icons/rx";
import { MdDone } from "react-icons/md";

import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { SkeletonLoadingOverlay } from "../../utils/listingSkeleton";
import { getClinicListing } from "../../api/clinic-api/Clinic.service";
import MobileCards, { ScreenType } from "../../component/cards/MobileCards";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};
export const Doctor = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { doctors, totalRecords, isDoctorLoading, isDoctorStatusLoading } =
    useSelector((state: any) => state.doctorState);
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const { clinics } = useSelector((state: RootState) => state.ClinicState);
  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [statusState, setStatusState] = useState("");
  const [NStatusValue, setNStatusValue] = useState("");
  const [filterByClinicId, setFilterByClinicId] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 20,
  });
  const [enableFilter, setEnambleFilter] = useState(false);
  useEffect(() => {
    refreshDoctor();
  }, [pageState]);
  useEffect(() => {
    dispatch(getClinicListing({ SkipPagination: true, status: "" }));
  }, [pageState]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };
  function escapeRegExp(value: string): string {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  }
  const redirectCallback = (id: string) => {
    navigate(`/doctorManagement/${id}`);
  };
  const onClinicChangeMV = (value: string) => {
    setFilterByClinicId(value);
  };
  const onClinicChange = (value: string) => {
    setFilterByClinicId(value);
    const JAStatus =
      statusState === "Offered"
        ? 1
        : statusState === "Hired"
        ? 2
        : statusState === "Active"
        ? 3
        : 0;
    const newStatus =
      NStatusValue === "Active" ? 0 : NStatusValue === "Inactive" ? 1 : 2;
    const searchData = {
      firstName: searchText,
      JAStatus: JAStatus,
      PageSize: pageState.pageSize,
      PageNumber: pageState.page,
      Status: newStatus,
      clinicID: value,
    };
    dispatch(searchDoctor(searchData));
  };
  const onSearch = (value: string) => {
    setSearchText(value);
    const JAStatus =
      statusState === "Offered"
        ? 1
        : statusState === "Hired"
        ? 2
        : statusState === "Active"
        ? 3
        : 0;
    const newStatus =
      NStatusValue === "Active" ? 0 : NStatusValue === "Inactive" ? 1 : 2;
    const searchData = {
      firstName: value,
      JAStatus: JAStatus,
      PageSize: pageState.pageSize,
      PageNumber: pageState.page,
      Status: newStatus,
      clinicID: filterByClinicId,
    };
    dispatch(searchDoctor(searchData));
  };
  const refreshDoctor = () => {
    const JAStatus =
      statusState === "Offered"
        ? 1
        : statusState === "Hired"
        ? 2
        : statusState === "Active"
        ? 3
        : 0;
    const newStatus =
      NStatusValue === "Active" ? 0 : NStatusValue === "Inactive" ? 1 : 2;
    const searchData = {
      firstName: searchText,
      JAStatus: JAStatus,
      PageSize: pageState.pageSize,
      PageNumber: pageState.page,
      Status: newStatus,
      clinicID: filterByClinicId,
    };
    dispatch(searchDoctor(searchData));
  };
  const columns: GridColDef[] = [
    {
      field: "firstname",
      headerName: "Doctor Name",
      width: 180,
      renderCell: (params: any) => {
        const currentRow = params.row;
        const hrefLink = `/doctorManagement/detail/${currentRow.encrypted_ID}`;
        return (
          <Link to={hrefLink}>
            {currentRow.firstname} {currentRow.lastname}
          </Link>
        );
      },
    },
    {
      field: "jAstatus",
      headerName: "JA Status",
      width: 150,
      renderCell: (params: any) => {
        const currentRow = params.row;
        return (
          <>
            {currentRow.jAstatus === "Hired" ? (
              <Tooltip title="Hired">
                <HandshakeIcon style={{ color: "#d32f2f" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Offered">
                <ReceiptLongIcon style={{ color: "#d32f2f" }} />
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: "is_form_filled",
      headerName: "Form Filled",
      width: 180,

      renderCell: (params: any) => {
        const currentRow = params.row;
        return (
          <span>
            {currentRow.is_form_filled ? (
              <Chip
                icon={<MdDone size={20} />}
                label="Filled"
                className="filled"
                variant="outlined"
              />
            ) : (
              <Chip
                icon={<RxCross2 size={20} />}
                className="Not-filled"
                label="Not filled"
                variant="outlined"
              />
            )}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: isDoctorStatusLoading ? "Loading..." : "Status",
      width: 150,
      renderCell: (params: any) => {
        const handleToggle = () => {
          let { encrypted_ID, status } = params.row;
          const updatedData = {
            userID: encrypted_ID,
            status: status ? 1 : 0,
            callback: encrypted_ID,
          };
          dispatch(changeStatus(updatedData));
        };

        return (
          <Stack direction="row" spacing={2}>
            <Switch
              size={"small"}
              checked={params.row.status}
              onChange={handleToggle}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span>{params.row.status ? "Active" : "Inactive"}</span>
          </Stack>
        );
      },
    },
    {
      field: "clinic_Allocated",
      headerName: "Clinic Allocated",
      width: 150,
      renderCell: (params: any) => {
        const currentRow = params.row;
        return (
          <span>
            {currentRow.clinic_Allocated ? (
              <Tooltip title="Clinic Allocated">
                <CheckCircleIcon style={{ color: "green" }} />
              </Tooltip>
            ) : (
              <Tooltip title="Clinic Not Allocated">
                <CancelIcon style={{ color: "#d32f2f" }} />
              </Tooltip>
            )}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      sortable: false,
      // disableClickEventBubbling: false,

      renderCell: (params: any) => {
        const { status, is_form_filled } = params.row;
        const onClick = (e: any) => {
          const currentRow = params.row;
          setDeletId(currentRow.encrypted_ID);
          handleOpen();
        };
        const onEdit = (e: any) => {
          const currentRow = params.row;
          dispatch(
            dotorEditAllApiResolve({
              encrypted_ID: currentRow.encrypted_ID,
              callback: redirectCallback,
            })
          );
        };

        const handleNav = (e: any) => {
          const currentRow = params.row;
          navigate(`/feeManagement/add/${currentRow.encrypted_ID}`);
        };

        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title="Edit">
              <IconButton>
                <EditIcon color="error" onClick={onEdit}></EditIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton>
                <DeleteForeverIcon
                  color="error"
                  onClick={onClick}
                ></DeleteForeverIcon>
              </IconButton>
            </Tooltip>

            {status && is_form_filled ? (
              <Tooltip title="Add New Fee">
                <IconButton>
                  <AddBoxIcon color="error" onClick={handleNav}></AddBoxIcon>
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </Stack>
        );
      },
    },
  ];

  const onClickHandle = () => {
    navigate(`/doctorManagement/add`);
  };
  const handleNStatusMV = (value: string) => {
    setNStatusValue(value);
  };
  const handleNStatus = (value: string) => {
    const JAStatus =
      statusState === "Offered"
        ? 1
        : statusState === "Hired"
        ? 2
        : statusState === "Active"
        ? 3
        : 0;
    const NStatusValueData =
      value === "Active" ? 0 : value === "Inactive" ? 1 : 2;
    setNStatusValue(value);
    const searchData = {
      firstName: searchText,
      JAStatus: JAStatus,
      PageSize: pageState.pageSize,
      PageNumber: pageState.page,
      Status: NStatusValueData,
      clinicID: filterByClinicId,
    };
    dispatch(searchDoctor(searchData));
  };
  const statusChangeMv = (value: string) => {
    setStatusState(value);
  };
  const statusChange = (value: string) => {
    const JAStatus =
      value === "Offered"
        ? 1
        : value === "Hired"
        ? 2
        : value === "Active"
        ? 3
        : 0;
    const newStatus =
      NStatusValue === "Active" ? 0 : NStatusValue === "Inactive" ? 1 : 2;
    setStatusState(value);
    const searchData = {
      firstName: searchText,
      JAStatus: JAStatus,
      PageSize: pageState.pageSize,
      PageNumber: pageState.page,
      Status: newStatus,
      clinicID: filterByClinicId,
    };
    dispatch(searchDoctor(searchData));
  };
  const applyFilter = () => {
    const JAStatus =
      statusState === "Offered"
        ? 1
        : statusState === "Hired"
        ? 2
        : statusState === "Active"
        ? 3
        : 0;
    const newStatus =
      NStatusValue === "Active" ? 0 : NStatusValue === "Inactive" ? 1 : 2;
    const searchData = {
      firstName: searchText,
      JAStatus: JAStatus,
      PageSize: pageState.pageSize,
      PageNumber: pageState.page,
      Status: newStatus,
      clinicID: filterByClinicId,
    };
    dispatch(searchDoctor(searchData));
  };
  const resetFilter = () => {
    setSearchText("");
    setStatusState("");
    setNStatusValue("");
    setFilterByClinicId("");
    setClinicName("");
    const searchData = {
      firstName: "",
      JAStatus: 0,
      PageSize: pageState.pageSize,
      PageNumber: pageState.page,
      Status: 2,
      clinicID: "",
    };
    dispatch(searchDoctor(searchData));
  };

  return (
    <Box sx={{ borderColor: "#e0e0e07a" }}>
      <Container sx={{ borderColor: "#e0e0e07a" }} maxWidth={false}>
        <Box py={2}>
          <DoctorManagementHeader onClickHandle={onClickHandle} />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "77vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          {isMobileView ? (
            <div className="cards-contaienr">
              <TableSearch
                onSearchFn={onSearch}
                statusData={status}
                statusState={statusState}
                statusChange={statusChange}
                searchText={searchText}
                setSearchText={setSearchText}
                componentName={"Doctor"}
                NStatus={true}
                isFilterByClinic={true}
                NStatusValue={NStatusValue}
                handleNStatus={handleNStatus}
                NstatusData={NstatusData}
                filterByClinicId={filterByClinicId}
                resetFilter={resetFilter}
                applyFilter={applyFilter}
                enableFilter={enableFilter}
                setEnambleFilter={setEnambleFilter}
                statusChangeMv={statusChangeMv}
                handleNStatusMV={handleNStatusMV}
                onClinicChangeMV={onClinicChangeMV}
                setClinicName={setClinicName}
                clinicName={clinicName}
                cliniclist={[
                  { encryptedClinicId: "all", clinicName: "All Clinics" },
                  ...clinics,
                ]}
                onClinicChange={onClinicChange}
              />

              {isDoctorLoading ? (
                <Stack sx={{ display: "flex", gap: "30px" }}>
                  {[...Array(1)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "80%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "30%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                  {[...Array(2)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "60%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "90%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                  {[...Array(4)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "40%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "50%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Stack>
              ) : (
                <div className="card-scroll">
                  {doctors?.length === 0 ? (
                    <Typography
                      variant="subtitle2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "rgb(60, 60, 60)",
                      }}
                    >
                      <strong>No Records Available </strong>
                    </Typography>
                  ) : (
                    doctors?.map((doctor: any) => {
                      return (
                        <>
                          <MobileCards
                            screen={ScreenType.DOCTOR_MANAGEMENT}
                            data={doctor}
                            searchText={searchText}
                            statusState={statusState}
                            pageState={pageState}
                            NStatusValue={NStatusValue}
                            filterByClinicId={filterByClinicId}
                          />
                        </>
                      );
                    })
                  )}
                </div>
              )}

              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={totalRecords}
                page={pageState.page - 1}
                onPageChange={(
                  event: React.MouseEvent<HTMLButtonElement> | null,
                  newPage: number
                ) => {
                  setPageState((old) => ({ ...old, page: newPage + 1 }));
                }}
                rowsPerPage={pageState.pageSize}
              />
            </div>
          ) : (
            <DataGrid
              sx={{
                border: 1,
                borderColor: "#e0e0e07a",
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                  outline: "none !important",
                },
                ".MuiDataGrid-columnHeader": {
                  position: "unset",
                },
                "& .MuiDataGrid-cell a": {
                  color: "primary.main",
                },
              }}
              rows={isDoctorLoading ? [] : doctors}
              loading={isDoctorLoading}
              columns={columns}
              density={"standard"}
              disableColumnMenu={true}
              components={{
                LoadingOverlay: SkeletonLoadingOverlay,
                Toolbar: TableSearch,
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No Records Available
                  </Stack>
                ),
              }}
              disableSelectionOnClick={true}
              rowCount={totalRecords}
              rowsPerPageOptions={[]}
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              paginationMode="server"
              onPageChange={(newPage) => {
                setPageState((old) => ({ ...old, page: newPage + 1 }));
              }}
              componentsProps={{
                toolbar: {
                  onSearchFn: onSearch,
                  statusData: status,
                  statusState: statusState,
                  statusChange: statusChange,
                  searchText: searchText,
                  setSearchText: setSearchText,
                  componentName: "Doctor",
                  NStatus: true,
                  isFilterByClinic: true,
                  NStatusValue: NStatusValue,
                  handleNStatus: handleNStatus,
                  NstatusData: NstatusData,
                  filterByClinicId: filterByClinicId,
                  resetFilter: resetFilter,
                  applyFilter: applyFilter,
                  enableFilter: enableFilter,
                  setEnambleFilter: setEnambleFilter,
                  cliniclist: [
                    { encryptedClinicId: "all", clinicName: "All Clinics" },
                    ...clinics,
                  ],
                  onClinicChange: onClinicChange,
                },
              }}
            />
          )}
        </div>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  dispatch(
                    deleteDoctor({ userId: deletId, callback: refreshDoctor })
                  );
                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};
