export const status = [
  {
    label: "None",
    value: 2,
  },
  {
    label: "Active",
    value: 0,
  },
  {
    label: "Inactive",
    value: 1,
  },
];

export const filterData = [
  {
    id: "1",
    label: "Seach",
    value: "SEARCH",
    checked: true
  },
  {
    id: "2",
    label: "Seach",
    value: "JASTATUS",
    checked: false
  },
  {
    id: "3",
    label: "Seach",
    value: "STATUS",
    checked: false
  },
  {
    id: "4",
    label: "Seach",
    value: "CLINICNAME",
    checked: false
  },
];

