export const agreementStatus = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "In Process",
    value: "in process",
  },
  {
    label: "Completed",
    value: "completed",
  },
];
