import Typography from "@mui/material/Typography";
import * as React from "react";
// import TextField from "@mui/material/TextField";
import { Box, Button, Divider, Grid, Skeleton, Stack } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { upadatePasswordData } from "./Data";
import { AppDispatch, RootState } from "../../../store/store";
import { updatePassword } from "../../../api/doctor-api/Api.service";
import { Container } from "@mui/system";

export interface StateStruct {
  state: string;
  abbrevation: string;
}
export const UpdatePassword = ({}): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const { states } = useSelector((state: RootState) => state.ClinicState);
  const { isPasswordUpdating } = useSelector(
    (state: RootState) => state.doctorState
  );
  const { userInfo } = useSelector((state: RootState) => state.globalAppState);
  // const location = useLocation();
  const navigate = useNavigate();

  const validationSchema = yup.object({
    oldPassword: yup.string().required("Old Password Is Required"),
    newPassword: yup
      .string()
      .required("New Password is Required")
      .min(8, "Must Be 8 Characters Or More")
      .matches(/[a-z]+/, "One Lowercase Character Is Required")
      .matches(/[A-Z]+/, "One Uppercase Character Is Required")
      .matches(
        /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+/,
        "One Special Character Is Required"
      )
      .matches(/\d+/, "One Numeric Character Is Required"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is Required")
      .oneOf(
        [yup.ref("newPassword")],
        "Confirm Password Should Be Same As New Password"
      ),
  });

  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  return (
    <React.Fragment>
      <Box>
        <Container sx={{ width: isMobileView ? "100%" : "70%", p: 2, ml: 0 }}>
          <Typography
            variant={isMobileView ? "h5" : "h4"}
            color="inherit"
            mb={2}
            noWrap
          >
            Update Doctor Password
          </Typography>

          <Formik
            initialValues={upadatePasswordData}
            // initialValues={editeDoctorData?params.id ? editeDoctorData : {}:intialDoctorData}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(false);
              const id = userInfo?.ID;

              const data = {
                ...values,
                userId: id,
              };
              dispatch(updatePassword(data));
              resetForm();
              // navigate(`/doctorManagement`);
            }}
          >
            {({
              values,
              submitForm,
              resetForm,
              isSubmitting,
              touched,
              errors,
              setFieldValue,
            }) => (
              <>
                <Box
                  ml={0}
                  sx={{
                    p: 3,
                    backgroundColor: "#fff ",
                    height: "100%",
                    ml: 0,
                  }}
                >
                  {isPasswordUpdating ? (
                    <>
                      <Stack spacing={3} p={3}>
                        {[...Array(3)].map(() => (
                          <>
                            <Grid
                              sx={{
                                gap: "30px",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              spacing={2}
                            >
                              <Skeleton
                                variant="rounded"
                                sx={{ height: "3", width: "50%" }}
                                animation={"wave"}
                              />
                              <Skeleton
                                variant="rounded"
                                sx={{ height: "3", width: "50%" }}
                                animation={"wave"}
                              />
                            </Grid>
                          </>
                        ))}
                      </Stack>
                      <Skeleton
                        variant="rounded"
                        sx={{
                          height: "4vh",
                          width: "150px",
                          marginLeft: "25px",
                        }}
                        animation={"wave"}
                      />
                    </>
                  ) : (
                    <Form>
                      <Box pt={3}>
                        <Field
                          component={TextField}
                          required
                          id="oldPassword"
                          name="oldPassword"
                          size="small"
                          label="Old Password"
                          fullWidth
                          variant="outlined"
                          error={
                            touched.oldPassword && Boolean(errors.oldPassword)
                          }
                          helperText={touched.oldPassword && errors.oldPassword}
                        />
                      </Box>

                      <Box pt={3}>
                        <Field
                          component={TextField}
                          id="newPassword"
                          name="newPassword"
                          size="small"
                          label="New Password"
                          fullWidth
                          required
                          variant="outlined"
                          error={
                            touched.newPassword && Boolean(errors.newPassword)
                          }
                          helperText={touched.newPassword && errors.newPassword}
                        />
                      </Box>
                      <Box pt={3}>
                        <Field
                          component={TextField}
                          required
                          id="confirmPassword"
                          name="confirmPassword"
                          size="small"
                          label="Confirm Password"
                          fullWidth
                          variant="outlined"
                        />
                      </Box>

                      <div className="clinic-management-add-footer">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{ mt: 3, ml: 1 }}
                            style={{
                              marginLeft: "0px",
                              marginBottom: "20px",
                            }}
                            disabled={isSubmitting}
                            onClick={submitForm}
                          >
                            Update
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{ mt: 3, ml: 1, mx: 2 }}
                            style={{
                              marginLeft: "20px",
                              marginBottom: "20px",
                            }}
                            onClick={() => {
                              resetForm();
                            }}
                          >
                            Reset
                          </Button>
                        </Box>
                      </div>
                    </Form>
                  )}
                </Box>
              </>
            )}
          </Formik>
        </Container>
      </Box>
    </React.Fragment>
  );
};
