export const LOGIN = "LOGIN";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const GET_CLINICS = "GET_CLINICS";
export const ADD_CLINICS = "ADD_CLINICS";
export const EDIT_CLINICS = "EDIT_CLINICS";
export const UPDATE_CLINIC_STATUS = "UPDATE_CLINIC_STATUS";
export const GET_DOCTOR = "GET_DOCTOR";
export const GET_DOCTOR_DETAIL = "GET_DOCTOR_DETAIL";
export const UPDATE_DOCTOR = "UPDATE_DOCTOR";
export const DELETE_DOCTOR = "DELETE_DOCTOR";
export const SEARCH_DOCTOR = "SEARCH_DOCTOR";
export const GET_FEES = "GET_FEES";
export const ADD_DOC_SER_FEE = "ADD_DOC_SER_FEE";
export const GET_STATES = "GET_STATES";
export const DELETE_CLINIC = "DELETE_CLINIC";
export const EDIT_DOC_SER_FEE = "EDIT_DOC_SER_FEE";
export const GET_ALL_DOCTORS = "GET_ALL_DOCTORS";
export const CHANGE_DOCTOR_STATUS = "CHANGE_DOCTOR_STATUS";
export const GET_DOC_SER_FEE = "GET_DOC_SER_FEE";
export const GET_DOC_SERVICES = "GET_DOC_SERVICES";
export const DELETE_FEES = "DELETE_FEES";
export const GET_CLINICS_INFO = "GET_CLINICS_INFO";
export const UPDATE_DOC_FEE_STATUS = "UPDATE_DOC_FEE_STATUS";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const FORGOT_PWD = "FORGOT_PWD";
export const RESET_PWD = "RESET_PWD";
export const GET_DOCTORS_NAME = "GET_DOCTORS_NAME";
export const GET_DOC_BY_CLINICS = "GET_DOC_BY_CLINICS";
export const UPDATE_DOCTOR_PASSWORD = "UPDATE_DOCTOR_PASSWORD";
export const VERIFY_PWD_LINK = "VERIFY_PWD_LINK";
export const GET_CLINIC_LOC = "GET_CLINIC_LOC";
