import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Formik, Form, Field, FieldArray } from "formik";
import { TextField } from "formik-mui";
import { Box, Button, MenuItem, Skeleton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import { array, object, string } from "yup";
import { AddClinic, getStates } from "../../../api/clinic-api/Clinic.service";
import { AddLocations, StateStruct } from "../slice/ClinicSlice";
import { Container, Stack, width } from "@mui/system";
import { FaPlus } from "react-icons/fa";
import "./style.css";

interface AddFormObject {
  clinicName: string;
  clinicLocations: AddLocations[];
}

export interface AddPayloadData {
  data: AddFormObject;
  callback: Function;
}

const emptyClinicObject: any = {
  code: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  myobCode: "",
  name: "",
  postalCode: "",
};

const spaceregex = /\S/;
const specialCharRegex = /[`!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;

export default function ClinicManagementAdd() {
  const dispatch: AppDispatch = useDispatch();
  const { states } = useSelector((state: RootState) => state.ClinicState);
  const { isMobileView } = useSelector((state: any) => state.globalAppState);

  React.useEffect(() => {
    dispatch(getStates());
  }, []);

  const navigate = useNavigate();
  return (
    <Box>
      <Container sx={{ width: isMobileView ? "100%" : "76%", p: 2, ml: 0 }}>
        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color="inherit"
          mb={isMobileView ? 3 : 2}
          noWrap
        >
          Add Clinic
        </Typography>
        <Formik
          initialValues={{
            clinicName: "",
            clinicLocations: [
              {
                code: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                myobCode: "",
                name: "",
                postalCode: "",
              },
            ],
          }}
          validationSchema={object({
            clinicName: string()
              .matches(spaceregex, "Only Spaces Are Not Allowed")
              .test(
                "special-character-test",
                "Invalid Clinic Name",
                (val: any) => {
                  if (val != undefined) {
                    return !specialCharRegex.test(val);
                  }
                  return true;
                }
              )
              .required("Please Enter Clinic Name"),
            clinicLocations: array().of(
              object().shape({
                code: string()
                  .matches(spaceregex, "Only Spaces Are Not Allowed")
                  .test(
                    "special-character-test",
                    "Invalid Code",
                    (val: any) => {
                      if (val != undefined) {
                        return !specialCharRegex.test(val);
                      }
                      return true;
                    }
                  )
                  .required("Please Enter Clinic Code"),
                address1: string()
                  .matches(spaceregex, "Only Spaces Are Not Allowed")
                  .test(
                    "special-character-test",
                    "Invalid Address1",
                    (val: any) => {
                      if (val != undefined) {
                        return !specialCharRegex.test(val);
                      }
                      return true;
                    }
                  )
                  .required("Please Enter address1"),
                postalCode: string()
                  .matches(spaceregex, "Only Spaces Are Not Allowed")

                  .test(
                    "special-character-test",
                    "Invalid PostalCode",
                    (val: any) => {
                      if (val != undefined) {
                        return !specialCharRegex.test(val);
                      }
                      return true;
                    }
                  )
                  .matches(
                    /(?:\bdigit-|\s|^)(\d{4})(?=[.?\s]|-digit\b|$)/gi,
                    "Postal Code Should Be 4 digits"
                  )
                  .required("Please Enter PostalCode"),
                city: string()
                  .matches(spaceregex, "Only Spaces Are Not Allowed")

                  .test(
                    "special-character-test",
                    "Invalid City",
                    (val: any) => {
                      if (val != undefined) {
                        return !specialCharRegex.test(val);
                      }
                      return true;
                    }
                  ),
                state: string()
                  .matches(spaceregex, "Only Spaces Are Not Allowed")
                  .test(
                    "special-character-test",
                    "Invalid state",
                    (val: any) => {
                      if (val != undefined) {
                        return !specialCharRegex.test(val);
                      }
                      return true;
                    }
                  )
                  .required("Please Enter state"),
                address2: string()
                  .matches(spaceregex, "Only Spaces Are Not Allowed")
                  .test(
                    "special-character-test",
                    "Invalid Address2",
                    (val: any) => {
                      if (val != undefined) {
                        return !specialCharRegex.test(val);
                      }
                      return true;
                    }
                  ),

                myobCode: string()
                  .matches(spaceregex, "Only Spaces Are Not Allowed")
                  .test(
                    "special-character-test",
                    "Invalid Myob Code",
                    (val: any) => {
                      if (val != undefined) {
                        return !specialCharRegex.test(val);
                      }
                      return true;
                    }
                  )
                  .required("Please Enter myobCode"),
                name: string()
                  .matches(spaceregex, "Only Spaces Are Not Allowed")
                  .test(
                    "special-character-test",
                    "Invalid name",
                    (val: any) => {
                      if (val != undefined) {
                        return !specialCharRegex.test(val);
                      }
                      return true;
                    }
                  )
                  .required("Please Enter name"),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            const navigateback = () => {
              return navigate(`/clinicManagement`);
            };
            const payloadData = { data: values, callback: navigateback };
            dispatch(AddClinic(payloadData));
          }}
        >
          {({
            values,
            submitForm,
            resetForm,
            isSubmitting,
            touched,
            errors,
          }) => (
            <>
              <Box
                ml={0}
                sx={{
                  p: 3,
                  backgroundColor: "white",
                  height: "100%",
                  ml: 0,
                }}
              >
                {states?.length === 0 ? (
                  <Stack spacing={3}>
                    {[...Array(5)].map(() => (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        spacing={2}
                      >
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3vh", width: "50%" }}
                          animation={"wave"}
                        />
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3vh", width: "50%" }}
                          animation={"wave"}
                        />
                      </Grid>
                    ))}

                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                        marginTop: "20px",
                        marginLeft: "20px",
                      }}
                      spacing={2}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "6vh", width: "150px" }}
                      />
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "6vh", width: "150px" }}
                      />
                      {/* <Skeleton
                variant="rounded"
                sx={{ height: "6vh", width: "150px" }}
              /> */}
                    </Grid>
                  </Stack>
                ) : (
                  <Form autoComplete="off">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field
                          component={TextField}
                          required
                          id="clinicName"
                          name="clinicName"
                          size="small"
                          label="Clinic Name"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <div
                        style={{
                          width: "100%",
                          padding: "1rem",
                          marginBottom: "0.2rem",
                          marginTop: "1rem",
                        }}
                      >
                        <Typography
                          variant={isMobileView ? "h6" : "h5"}
                          color="inherit"
                          noWrap
                          sx={{
                            borderBottom: "1.3px solid brown",
                          }}
                        >
                          Add Locations
                        </Typography>
                      </div>

                      <FieldArray name="clinicLocations">
                        {({ push, remove }) => (
                          <React.Fragment>
                            {values?.clinicLocations?.map((_, index) => (
                              <div className="add-more-container">
                                <div className="remove-add-more-item">
                                  {values?.clinicLocations.length > 1 ? (
                                    <span
                                      onClick={() => remove(index)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      X
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                <Grid container item key={index} spacing={2}>
                                  <>
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        component={TextField}
                                        id={`clinicLocations[${index}].name`}
                                        name={`clinicLocations[${index}].name`}
                                        required
                                        label="Name"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        component={TextField}
                                        id={`clinicLocations[${index}].myobCode`}
                                        name={`clinicLocations[${index}].myobCode`}
                                        required
                                        label="MYOB Code"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        component={TextField}
                                        id={`clinicLocations[${index}].code`}
                                        name={`clinicLocations[${index}].code`}
                                        required
                                        label="Code"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        component={TextField}
                                        id={`clinicLocations[${index}].address1`}
                                        name={`clinicLocations[${index}].address1`}
                                        required
                                        label="Address1"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        component={TextField}
                                        id={`clinicLocations[${index}].postalCode`}
                                        name={`clinicLocations[${index}].postalCode`}
                                        required
                                        label="Post Code"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        component={TextField}
                                        id={`clinicLocations[${index}].address2`}
                                        name={`clinicLocations[${index}].address2`}
                                        label="Address2"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        component={TextField}
                                        id={`clinicLocations[${index}].city`}
                                        name={`clinicLocations[${index}].city`}
                                        label="City"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                      <Field
                                        component={TextField}
                                        type="text"
                                        id={`clinicLocations[${index}].state`}
                                        name={`clinicLocations[${index}].state`}
                                        label="State"
                                        select
                                        required
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                      >
                                        {states?.map(
                                          (
                                            option: StateStruct,
                                            index: number
                                          ) => (
                                            <MenuItem
                                              key={index}
                                              value={option.abbrevation}
                                            >
                                              {option.state}
                                            </MenuItem>
                                          )
                                        )}
                                      </Field>
                                    </Grid>
                                  </>
                                </Grid>
                              </div>
                            ))}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                marginTop: "30px",
                                width: "100%",
                              }}
                            >
                              <Grid item>
                                <Button
                                  disabled={isSubmitting}
                                  variant="contained"
                                  onClick={() => push(emptyClinicObject)}
                                >
                                  <span style={{ marginRight: "3px" }}>
                                    <FaPlus size={10} />
                                  </span>{" "}
                                  Add More
                                </Button>
                              </Grid>
                            </div>
                          </React.Fragment>
                        )}
                      </FieldArray>
                    </Grid>
                    <div className="clinic-management-add-footer">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{ mt: 3, ml: 1 }}
                          style={{
                            marginLeft: "0px",
                          }}
                          disabled={isSubmitting}
                          onClick={submitForm}
                        >
                          Save
                        </Button>
                        <Button
                          variant="outlined"
                          sx={{ mt: 3, ml: 1 }}
                          style={{
                            marginLeft: "15px",
                          }}
                          onClick={() => {
                            navigate(`/clinicManagement`);
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </div>
                  </Form>
                )}
              </Box>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </>
          )}
        </Formik>
      </Container>
    </Box>
  );
}
