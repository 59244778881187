import {
  Container,
  Paper,
  Typography,
  Grid,
  Divider,
  List,
  ListItem,
  Button,
  Stack,
  Chip,
  Link,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDoctorDetail } from "../../api/doctor-api/Api.service";
import { AppDispatch, RootState } from "../../store/store";
import Box, { BoxProps } from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import moment from "moment";

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: "100%",
  color: theme.palette.text.primary,
}));

function Item(props: BoxProps) {
  const { sx, ...other } = props;
  return (
    <Box
      sx={{
        bgcolor: (theme) =>
          theme.palette.mode === "dark" ? "#101010" : "#fff",
        color: (theme) =>
          theme.palette.mode === "dark" ? "grey.300" : "grey.800",

        borderColor: (theme) =>
          theme.palette.mode === "dark" ? "grey.800" : "grey.300",
        p: 1,
        fontSize: "0.875rem",
        fontWeight: "500",
        ...sx,
      }}
      {...other}
    />
  );
}

/* interface ServicesArray {
  encryptedServiceId?: string;
  serviceName?: string;
}

interface ServiceAndFeesArray {
  services: ServicesArray[];
  fees: number | null;
}

export interface ServiceAndFeeCollection {
  encryptedClinicId?: string;
  clinicName?: string;
  publicHolidayFee?: string;
  afterHoursFee?: string;
  weekendFee?: string;
  allServicesAndFee?: ServiceAndFeesArray[];
} */

interface ServicesArray {
  encryptedServiceId?: string;
  serviceName?: string;
}

interface ServiceAndFeesArray {
  services: ServicesArray[];
  fees: number | null;
}

interface ServiceAndFeesLocationArray {
  locationName?: string;
  publicHolidayFee?: string;
  afterHoursFee?: string;
  weekendFee?: string;
  defaultFee?: string;
  allServicesAndFee?: ServiceAndFeesArray[];
}

export interface ServiceAndFeeCollection {
  encryptedClinicId?: string;
  clinicName?: string;
  allServicesAndFeeLocation?: ServiceAndFeesLocationArray[];
}

export interface DoctorDetail {
  jAstatus?: string | number;
  firstname?: string;
  middlename?: string;
  lastname?: string;
  dateofbirth?: string;
  mobile?: string;
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  postalcode?: string;
  abn?: string;
  abnassociatedname?: string;
  ahpraregistrationnumber?: string;
  prescribernumber?: string | number;
  currentorpreviousprovidernumber?: string;
  account_name?: string;
  account_number?: string;
  bsb_number?: string;
  financial_institution_name?: string;
}

export interface AgreementDetailInformation {
  viewAgreementLink?: {
    url?: string;
  };
  currentAgreementStatus?: string;
}

export interface DoctorDetailInit {
  AgreementDetailInformation: AgreementDetailInformation;
  callback: Function;
}

export const DoctorDetail = (): JSX.Element => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const {
    DoctorDetails,
    ClinicsAndServices,
    AgreementDetailInformation,
    isDoctorListLoading,
  } = useSelector((state: RootState) => state.doctorState);

  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  useEffect(() => {
    if (params.id) {
      dispatch(getDoctorDetail(params.id));
    }
  }, []);
  const addNot = (num: any) => {
    return num < 10 ? "0" + num : num;
  };
  const formatDate = (date: Date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date);
    return [addNot(d.getDate()), months[d.getMonth()], d.getFullYear()].join(
      " "
    );
  };
  const viewPdf = () => {
    window.open(
      AgreementDetailInformation?.viewAgreementLink?.url,
      "_blank",
      "noopener,noreferrer"
    );
  };
  function addDots(str: any) {
    return (
      <Tooltip title={str} placement="top">
        <span>{str}</span>
      </Tooltip>
    );
  }

  return (
    <Box>
      <Container maxWidth={false}>
        <Box
          mt={2}
          mb={3}
          sx={{
            flexGrow: 1,
            px: 3,
            display: "flex",
            gap: "30px",
            maxHeight: "800px",
            width: "90%",
            justifyContent: "end",
          }}
        >
          {isMobileView ? (
            <div
              style={{
                borderRadius: "50px",
                backgroundColor: "brown",
                height: "35px",
                width: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon
                fontSize={"medium"}
                sx={{ color: "white" }}
              ></ChevronLeftIcon>
            </div>
          ) : (
            <Button
              variant="contained"
              sx={{ pl: 1 }}
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon fontSize="medium"></ChevronLeftIcon>
              Back
            </Button>
          )}
        </Box>

        <Box
          mt={2}
          mb={3}
          sx={{
            flexGrow: 1,
            px: 3,
            display: "flex",
            flexDirection: isMobileView ? "column" : "row",
            gap: "30px",
            width: "90%",
          }}
        >
          {isDoctorListLoading ? (
            <>
              <Stack
                spacing={3}
                sx={{ boxShadow: "1px 1px 5px 0px black", p: 2, flex: "1" }}
              >
                <>
                  <Grid
                    sx={{
                      gap: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    spacing={2}
                  >
                    <Typography variant="h5" noWrap mt={2}>
                      Doctor Details
                    </Typography>
                    <Skeleton
                      variant={"circular"}
                      sx={{ height: "50px", width: "50px" }}
                    />
                  </Grid>
                  <Box sx={{ mb: "1rem" }}>
                    <Divider></Divider>
                  </Box>
                  {[...Array(5)].map(() => (
                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      spacing={2}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "3vh", width: "90%" }}
                        animation={"wave"}
                      />
                    </Grid>
                  ))}
                </>
                <>
                  <Typography variant="h5" noWrap>
                    Agreement Detail
                  </Typography>

                  <Box sx={{ mb: "1rem" }}>
                    <Divider></Divider>
                  </Box>
                  {[...Array(2)].map(() => (
                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      spacing={2}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "3vh", width: "90%" }}
                        animation={"wave"}
                      />
                    </Grid>
                  ))}
                  <Grid
                    sx={{
                      gap: "30px",
                      display: "flex",
                    }}
                    spacing={2}
                  >
                    <Box
                      component="span"
                      sx={{ fontWeight: 600, color: "#464646" }}
                    >
                      Agreement Status:
                    </Box>
                    <Skeleton
                      variant={"rounded"}
                      sx={{ height: "30px", width: "150px" }}
                    />
                  </Grid>
                </>
              </Stack>
            </>
          ) : (
            <StyledPaper
              sx={{
                my: 1,
                ml: "0",
                p: 2,
                flex: 1,
                boxShadow: "1px 1px 5px 0px black",
                position: "relative",
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs zeroMinWidth>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems={"center"}
                    spacing={4}
                  >
                    <Stack spacing={1}>
                      <Typography variant="h5" noWrap mt={2}>
                        Doctor Details
                      </Typography>
                    </Stack>
                    <div>
                      {DoctorDetails?.jAstatus ? (
                        <Chip
                          label={DoctorDetails?.jAstatus}
                          variant="outlined"
                          style={{
                            color:
                              DoctorDetails?.jAstatus === "Hired"
                                ? "green"
                                : "orange",

                            border: "1px solid",
                            borderColor:
                              DoctorDetails?.jAstatus === "Hired"
                                ? "green"
                                : "orange",
                          }}
                        />
                      ) : (
                        <Chip
                          label="Offered"
                          variant="outlined"
                          style={{
                            color: "orange",
                            border: "1px solid",
                            borderColor: "orange",
                          }}
                        />
                      )}
                    </div>
                  </Stack>
                  <Box sx={{ mb: "1rem" }}>
                    <Divider></Divider>
                  </Box>

                  {DoctorDetails?.firstname ? (
                    <Grid item xs zeroMinWidth>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Doctor Name:{" "}
                        </Box>
                        {DoctorDetails?.firstname}{" "}
                        {DoctorDetails?.middlename
                          ? DoctorDetails?.middlename
                          : ""}{" "}
                        {DoctorDetails?.lastname}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          DOB:{" "}
                        </Box>
                        {formatDate(
                          new Date(moment(DoctorDetails?.dateofbirth).format())
                        )}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Mobile:{" "}
                        </Box>
                        {DoctorDetails?.mobile}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Email:{" "}
                        </Box>
                        {DoctorDetails?.email}
                      </Typography>

                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Address:{" "}
                        </Box>
                        {DoctorDetails?.address}, {DoctorDetails?.city},{" "}
                        {DoctorDetails?.state}- {DoctorDetails?.postalcode}
                      </Typography>

                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          ABN:{" "}
                        </Box>
                        {DoctorDetails?.abn}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          ABN Associated Name:{" "}
                        </Box>
                        {DoctorDetails?.abnassociatedname}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Ahpra Registration Number:{" "}
                        </Box>
                        {DoctorDetails?.ahpraregistrationnumber}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Prescriber Number:{" "}
                        </Box>
                        {DoctorDetails?.prescribernumber}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Current or Previous Provider Number:{" "}
                        </Box>
                        {DoctorDetails?.currentorpreviousprovidernumber}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Financial Institute Name:{" "}
                        </Box>
                        {DoctorDetails?.financial_institution_name}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Account Name:{" "}
                        </Box>
                        {DoctorDetails?.account_name}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          BSB Number:{" "}
                        </Box>
                        {DoctorDetails?.bsb_number}
                      </Typography>
                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Account Number:{" "}
                        </Box>
                        {DoctorDetails?.account_number}
                      </Typography>

                      <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          JA Status:{" "}
                        </Box>
                        {DoctorDetails?.jAstatus}
                      </Typography>
                    </Grid>
                  ) : (
                    <Typography sx={{ my: 1, fontSize: 14 }} variant="body1">
                      <Box
                        component="span"
                        sx={{
                          fontWeight: 600,
                          textTransform: "capitalize",
                          color: "#464646",
                        }}
                      >
                        Doctor has not filled the form yet!
                      </Box>
                    </Typography>
                  )}
                </Grid>
              </Grid>
              <Grid container wrap="nowrap" mt={2}>
                <Grid item></Grid>
                <Grid item xs zeroMinWidth>
                  <Typography variant="h5" noWrap>
                    Agreement Detail
                  </Typography>
                  <Box sx={{ mb: "1rem" }}>
                    <Divider></Divider>
                  </Box>
                  <Grid item xs zeroMinWidth>
                    <Typography
                      sx={{ my: 1, fontSize: 14, textTransform: "capitalize" }}
                      variant="body1"
                    >
                      <Box
                        component="span"
                        sx={{ fontWeight: 600, color: "#464646" }}
                      >
                        Agreement Status:{" "}
                      </Box>
                      {AgreementDetailInformation?.currentAgreementStatus ===
                      null ? (
                        <span style={{ color: "orange" }}>pending</span>
                      ) : (
                        <span style={{ color: "green" }}>
                          {AgreementDetailInformation?.currentAgreementStatus}
                        </span>
                      )}
                    </Typography>
                    {AgreementDetailInformation?.viewAgreementLink?.url && (
                      <Typography
                        sx={{
                          mt: 2,
                          fontSize: 14,
                          textTransform: "capitalize",
                        }}
                        variant="body1"
                        noWrap
                      >
                        <Box
                          component="span"
                          sx={{ fontWeight: 600, color: "#464646" }}
                        >
                          Agreement Link:{" "}
                        </Box>
                        {AgreementDetailInformation?.currentAgreementStatus !==
                          null && (
                          <Link
                            onClick={viewPdf}
                            underline="none"
                            style={{
                              cursor: "pointer",
                              fontSize: "15px",
                              margin: "4px",
                              color: "brown",
                            }}
                          >
                            {" View Agreement"}
                          </Link>
                        )}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </StyledPaper>
          )}
          {isDoctorListLoading ? (
            <>
              <Stack
                spacing={3}
                sx={{ boxShadow: "1px 1px 5px 0px black", p: 2, flex: "1" }}
              >
                <>
                  <Grid
                    sx={{
                      gap: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    spacing={2}
                  >
                    <Typography variant="h5" noWrap mt={2}>
                      Clinic Detail
                    </Typography>
                  </Grid>
                  <Box sx={{ mb: "1rem" }}>
                    <Divider></Divider>
                  </Box>
                  {[...Array(10)].map(() => (
                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      spacing={2}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "3vh", width: "90%" }}
                        animation={"wave"}
                      />
                    </Grid>
                  ))}
                </>
              </Stack>
            </>
          ) : (
            <StyledPaper
              sx={{
                my: 1,
                ml: "0",
                p: 2,
                overflowY: "scroll",
                flex: 1,
                boxShadow: "1px 1px 5px 0px  black",
                position: "relative",
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item xs zeroMinWidth>
                  <Typography variant="h5" noWrap mt={2}>
                    Clinic Detail
                  </Typography>
                  <Box sx={{ mb: "1rem" }}>
                    <Divider></Divider>
                  </Box>
                  {ClinicsAndServices?.length > 0 ? (
                    ClinicsAndServices?.map((clinics, index) => (
                      <Grid item xs zeroMinWidth>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 600, color: "firebrick" }}
                          noWrap
                        >
                          <Box
                            component="span"
                            sx={{ fontWeight: 600, color: "#464646" }}
                          >
                            Clinic Name:{" "}
                          </Box>
                          {clinics?.clinicName}
                        </Typography>
                        <Box sx={{ mb: "1rem", width: "50%" }}>
                          <Divider></Divider>
                        </Box>

                        {clinics?.allServicesAndFeeLocation?.map(
                          (serviceFeeLoc, key) => (
                            <>
                              <Typography
                                sx={{
                                  my: 1,
                                  fontSize: 14,
                                }}
                                variant="body1"
                              >
                                <Box
                                  component="span"
                                  sx={{
                                    fontWeight: 600,
                                    color: "#464646",
                                  }}
                                >
                                  Location Name:{" "}
                                </Box>
                                {serviceFeeLoc?.locationName}
                              </Typography>
                              <Typography
                                sx={{ my: 1, fontSize: 14 }}
                                variant="body1"
                              >
                                <Box
                                  component="span"
                                  sx={{ fontWeight: 600, color: "#464646" }}
                                >
                                  Default Fees:{" "}
                                </Box>
                                {Number(serviceFeeLoc?.defaultFee)?.toFixed(2)}{" "}
                                %
                              </Typography>

                              <Typography
                                sx={{ my: 1, fontSize: 14 }}
                                variant="body1"
                              >
                                <Box
                                  component="span"
                                  sx={{ fontWeight: 600, color: "#464646" }}
                                >
                                  Public Holiday Fees:{" "}
                                </Box>
                                {serviceFeeLoc?.publicHolidayFee === null
                                  ? "NA"
                                  : `${Number(
                                      serviceFeeLoc?.publicHolidayFee
                                    )?.toFixed(2)} %`}
                              </Typography>
                              <Typography
                                sx={{ my: 1, fontSize: 14 }}
                                variant="body1"
                              >
                                <Box
                                  component="span"
                                  sx={{ fontWeight: 600, color: "#464646" }}
                                >
                                  After Hour Fees:{" "}
                                </Box>
                                {serviceFeeLoc?.afterHoursFee === null
                                  ? "NA"
                                  : `${Number(
                                      serviceFeeLoc?.afterHoursFee
                                    )?.toFixed(2)} %`}
                              </Typography>
                              <Typography
                                sx={{ my: 1, fontSize: 14 }}
                                variant="body1"
                              >
                                <Box
                                  component="span"
                                  sx={{ fontWeight: 600, color: "#464646" }}
                                >
                                  Weekend Fees:{" "}
                                </Box>

                                {serviceFeeLoc?.weekendFee === null
                                  ? "NA"
                                  : `${Number(
                                      serviceFeeLoc?.weekendFee
                                    )?.toFixed(2)} %`}
                              </Typography>

                              {serviceFeeLoc?.allServicesAndFee?.length ===
                              0 ? (
                                <>
                                  <Grid
                                    container
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingBottom: "7px",
                                      gap: 1,
                                    }}
                                  >
                                    <Box
                                      component="span"
                                      sx={{
                                        color: "#464646",
                                      }}
                                    >
                                      <span style={{ fontWeight: 600 }}>
                                        Fee:{" "}
                                      </span>
                                      NA
                                    </Box>
                                    <Box
                                      component="span"
                                      sx={{
                                        color: "#464646",
                                      }}
                                    >
                                      <span style={{ fontWeight: 600 }}>
                                        Services:{" "}
                                      </span>
                                      NA
                                    </Box>
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  {serviceFeeLoc?.allServicesAndFee?.map(
                                    (serviceFee, key) => (
                                      <>
                                        <Typography
                                          sx={{ my: 1, fontSize: 14 }}
                                          variant="body1"
                                        >
                                          <Box
                                            component="span"
                                            sx={{
                                              fontWeight: 600,
                                              color: "#464646",
                                            }}
                                          >
                                            Fee:{" "}
                                          </Box>
                                          {serviceFee?.fees === null
                                            ? "NA"
                                            : `${Number(
                                                serviceFee?.fees
                                              )?.toFixed(2)} %`}
                                        </Typography>
                                        <Typography
                                          sx={{ my: 1, fontSize: 14 }}
                                          variant="body1"
                                        >
                                          <Box
                                            component="span"
                                            sx={{
                                              fontWeight: 600,
                                              color: "#464646",
                                            }}
                                          >
                                            Services:
                                          </Box>
                                          <>
                                            <List sx={{ py: 0 }}>
                                              {serviceFee?.services.map(
                                                (service, i) => (
                                                  <>
                                                    <ListItem>
                                                      {service?.serviceName}
                                                    </ListItem>
                                                  </>
                                                )
                                              )}
                                            </List>
                                          </>
                                        </Typography>
                                      </>
                                    )
                                  )}
                                </>
                              )}
                            </>
                          )
                        )}
                      </Grid>
                    ))
                  ) : (
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 600, color: "firebrick" }}
                      noWrap
                    >
                      <Box
                        component="span"
                        sx={{
                          fontWeight: 600,
                          textTransform: "capitalize",
                          color: "#464646",
                        }}
                      >
                        No clinics assigned!
                      </Box>
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </StyledPaper>
          )}
        </Box>
      </Container>
    </Box>
  );
};
