import {
  Box,
  Button,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getDoctorData } from "../../api/doctor-api/Api.service";
import { AppDispatch, RootState } from "../../store/store";
import EditIcon from "@mui/icons-material/Edit";

import moment from "moment";

export const PersonalInformation = (): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileView } = useSelector((state: any) => state.globalAppState);

  const { editeDoctorData, isDoctorLoading } = useSelector(
    (state: RootState) => state.doctorState
  );
  const { userInfo } = useSelector((state: RootState) => state.globalAppState);

  const onEdit = () => {
    const id = userInfo?.ID;
    navigate(`/doctorManagement/${id}`);
  };
  useEffect(() => {
    // const data = { encrypted_ID: userInfo?.ID };
    const id = userInfo?.ID;
    const data = {
      encrypted_ID: id,
      callback: () => {},
    };
    dispatch(getDoctorData(data));
  }, []);
  const addNot = (num: any) => {
    return num < 10 ? "0" + num : num;
  };
  const formatDate = (date: Date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date);
    return [addNot(d.getDate()), months[d.getMonth()], d.getFullYear()].join(
      " "
    );
  };
  return (
    <Box py={2} pl={1} pr={1}>
      <Container maxWidth={false}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems={"baseline"}
        >
          <Typography variant={isMobileView ? "h5" : "h4"}>
            Personal Information
          </Typography>
          <Box>
            {isMobileView ? (
              <div
                style={{
                  borderRadius: "50px",
                  backgroundColor: "brown",
                  height: "35px",
                  width: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 0px 2px 1px black",
                }}
                onClick={onEdit}
              >
                <EditIcon
                  style={{ color: "white" }}
                  onClick={onEdit}
                ></EditIcon>
              </div>
            ) : (
              <Button variant="contained" onClick={onEdit}>
                Edit Personal Information
              </Button>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "white",
          }}
          mt={2}
        >
          {isDoctorLoading && Object.keys(editeDoctorData).length === 0 ? (
            <>
              <Stack spacing={3} p={3}>
                {[...Array(9)].map(() => (
                  <Grid
                    sx={{
                      gap: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    spacing={2}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                  </Grid>
                ))}
              </Stack>
            </>
          ) : (
            <Box p={3}>
              <Typography variant="subtitle1" color={"#d32f2f"} mt={1}>
                Personal Details
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Full Name:</strong> {editeDoctorData?.firstname}{" "}
                {editeDoctorData?.middlename ? editeDoctorData?.middlename : ""}{" "}
                {editeDoctorData?.lastname}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>DOB:</strong>{" "}
                {formatDate(
                  new Date(moment(editeDoctorData?.dateofbirth).format())
                )}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Mobile:</strong> {editeDoctorData?.mobile}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Email:</strong> {editeDoctorData?.email}
              </Typography>

              <Typography variant="body2" gutterBottom>
                <strong>Address:</strong> {editeDoctorData?.address},{" "}
                {editeDoctorData?.city}, {editeDoctorData?.state}-{" "}
                {editeDoctorData?.postalcode}
              </Typography>
              <Typography variant="subtitle1" color={"#d32f2f"} mt={2}>
                Additional Details
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>ABN:</strong> {editeDoctorData?.abn}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>ABN Associated Name:</strong>{" "}
                {editeDoctorData?.abnassociatedname}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Ahpra Registration Number:</strong>{" "}
                {editeDoctorData?.ahpraregistrationnumber}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Prescriber Number:</strong>{" "}
                {editeDoctorData?.prescribernumber}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Current or Previous Provider Number:</strong>{" "}
                {editeDoctorData?.currentorpreviousprovidernumber}
              </Typography>
              <Typography variant="subtitle1" color={"#d32f2f"} mt={2}>
                Banking Details
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Financial Institution Name:</strong>{" "}
                {editeDoctorData?.financial_institution_name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Account Name:</strong> {editeDoctorData?.account_name}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>BSB Number:</strong> {editeDoctorData?.bsb_number}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Account Number:</strong>{" "}
                {editeDoctorData?.account_number}
              </Typography>
              <Typography variant="subtitle1" color={"#d32f2f"} mt={2}>
                Job Added Status
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>JA Status:</strong> {editeDoctorData?.jAstatus}
              </Typography>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
};
