import { Backdrop, LinearProgress, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

function PageLoaderComponent(props: any) {
  const open = useSelector(
    (state: RootState) => state.globalAppState.isLoading
  );

  return (
    <div>
      <Backdrop style={{ zIndex: 10000 }} open={open}>
        {open && (
          <Stack
            sx={{
              width: "100%",
              color: "brown",
              position: "absolute",
              top: "0px",
            }}
            spacing={2}
          >
            <LinearProgress color="error" />
          </Stack>
        )}
      </Backdrop>
    </div>
  );
}

export default PageLoaderComponent;
