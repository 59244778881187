import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import * as React from "react";
// import TextField from "@mui/material/TextField";
import {
  Box,
  Button,
  Container,
  Divider,
  MenuItem,
  Skeleton,
  Stack,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as yup from "yup";
import { getStates } from "../../api/clinic-api/Clinic.service";
import { updateDoctor } from "../../api/doctor-api/Api.service";
import { AppDispatch, RootState } from "../../store/store";
import { intialDoctorData } from "./Data";
import "./styles.css";
import { AddDoctorScreenProps } from "./types";

export interface StateStruct {
  state: string;
  abbrevation: string;
}
export const AddDoctorScreen = ({
  data = [],
}: AddDoctorScreenProps): JSX.Element => {
  const dispatch: AppDispatch = useDispatch();
  const { states } = useSelector((state: RootState) => state.ClinicState);
  const { globalAppState, doctorState } = useSelector(
    (state: RootState) => state
  );
  const [flowChecker, setFlowChecker] = React.useState("");
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const navigate = useNavigate();
  const params = useParams();
  const { editeDoctorData }: any = useSelector(
    (state: RootState) => state.doctorState
  );

  React.useEffect(() => {
    dispatch(getStates());
  }, []);
  const redirectCallback = () => {
    if (globalAppState?.userInfo?.Role === "2") navigate(`/doctorManagement`);
    else navigate("/personalInformation");
  };

  const redirectCallbackAddNewFeeFlow = () => {
    if (globalAppState?.userInfo?.Role === "2")
      navigate(`/feeManagement/add/${editeDoctorData?.encrypted_ID}`);
    else navigate("/personalInformation");
  };

  const validationSchema = yup.object({
    firstname: yup
      .string()
      .required("First Name Is Required")
      .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter Valid First Name"),
    middlename: yup
      .string()
      .nullable()
      .optional()
      .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter Valid Middle Name"),
    lastname: yup
      .string()
      .required("Last Name Is Required")
      .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, "Enter Valid Last Name"),
    account_name: yup
      .string()
      .required("Account Name Is Required")
      .matches(
        /^[a-zA-Z0-9]+(?:[\s-][a-zA-Z0-9]+)*$/,
        "Enter Valid Account Name"
      ),
    bsb_number: yup
      .string()
      .required("BSB Number Is Required")
      .matches(/^\d{6}$/, "Enter Valid BSB Number"),
    account_number: yup
      .string()
      .required("Account Number Is Required")
      .matches(/^[0-9]+$/, "Enter Valid Account Number"),
    financial_institution_name: yup
      .string()
      .required("Financial Institution Name Is Required")
      .matches(
        /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
        "Enter Valid Financial Institution Name"
      ),
    dateofbirth: yup.string().required("Date Of Birth Is Required"),
    mobile: yup
      .string()
      .test("is-valid1", "Please Enter Valid Mobile Number", (val: any) => {
        if (val.substring(3, 4) === 0) {
          return false;
        }
        return true;
      })
      .test("is-valid2", "Please Enter Valid Mobile Number", (val: any) => {
        if (val != undefined) {
          return /^\+D*61(\D*\d){9}\D*$/.test(val);
        }
        return true;
      })
      .min(9, "Mobile Number Should Be Minimun 9 Characters Length")
      .max(14, "Mobile Number Should Be Maximum 14 Characters Length")
      .required("Mobile Number Is Required"),
    email: yup
      .string()
      .required("Valid Email Is Required")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Enter Valid Email"),
    address: yup.string().required("Address Is Required"),
    city: yup
      .string()
      .required("City Is Required")
      .matches(/^[a-zA-Z0-9]+(?:[\s-][a-zA-Z0-9]+)*$/, "Enter Valid City"),
    state: yup.string().required("State Is Required"),
    postalcode: yup
      .string()
      .required("Postal Code Is Required")
      .matches(/^\d{4}$/, "Enter Valid Postal Code"),
    abn: yup
      .string()
      .required("ABN Is Required")
      .matches(/^\d{11}$/, "Enter Valid ABN"),
    abnassociatedname: yup
      .string()
      .required("ABN Associated Name Is Required")
      .matches(
        /^[a-zA-Z0-9]+(?:[\s-][a-zA-Z0-9]+)*$/,
        "Enter Valid ABN Associated Name"
      ),
    currentorpreviousprovidernumber: yup
      .string()
      .required("Current or Previous Provider Number Is Required")
      .matches(
        /^[a-zA-Z0-9]+(?:[\s-][a-zA-Z0-9]+)*$/,
        "Enter Valid Current or Previous Provider Number"
      ),
    prescribernumber: yup
      .string()
      .required("Prescriber Number Is Required")
      .matches(
        /^[a-zA-Z0-9]+(?:[\s-][a-zA-Z0-9]+)*$/,
        "Enter Valid Prescriber Number"
      ),
    ahpraregistrationnumber: yup
      .string()
      .required("Ahpra Registration Number Is Required")
      .matches(
        /^[A-Z]{3}[0-9]{10}$/,
        "Please Enter Valid AHPRA Registration Number"
      ),
    myoB_customer_ID: yup
      .string()
      .matches(/\S/, "Please Enter Valid MYOB Customer ID")
      .nullable()
      .optional(),
    customer_account_code: yup
      .string()
      .matches(/\S/, "Please Enter Valid Customer Account Code")
      .nullable()
      .optional(),
  });

  return (
    <React.Fragment>
      <Container sx={{ width: "100%", p: 2, ml: 0, maxWidth: "100%" }}>
        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color="inherit"
          mb={2}
          noWrap
        >
          {params.id ? "Edit Doctor Information" : "Add Doctor Information"}
        </Typography>
        {doctorState.isDoctorEditLoading ? (
          <>
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              <Stack spacing={3}>
                {[...Array(11)].map(() => (
                  <Grid
                    sx={{
                      gap: "30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    spacing={2}
                  >
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "3", width: "50%" }}
                      animation={"wave"}
                    />
                  </Grid>
                ))}
                <Grid
                  sx={{
                    gap: "30px",
                    display: "flex",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                  spacing={2}
                >
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    sx={{ height: "6vh", width: "150px" }}
                  />
                  {/* <Skeleton
                variant="rounded"
                sx={{ height: "6vh", width: "150px" }}
              /> */}
                </Grid>
              </Stack>
            </Box>
          </>
        ) : (
          <Formik
            initialValues={
              params.id
                ? editeDoctorData
                  ? editeDoctorData
                  : intialDoctorData
                : intialDoctorData
            }
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              if (params.id) {
                dispatch(
                  updateDoctor({
                    ...values,
                    id: params.id,
                    dateofbirth: values.dateofbirth.substring(0, 19),
                    callback:
                      flowChecker === "Special"
                        ? redirectCallbackAddNewFeeFlow
                        : redirectCallback,
                  })
                );
              }
            }}
          >
            {({
              values,
              submitForm,
              resetForm,
              isSubmitting,
              touched,
              errors,
              setFieldValue,
            }) => (
              <>
                <Box
                  ml={0}
                  sx={{
                    p: 3,
                    backgroundColor: "#fff ",
                    height: "100%",
                    ml: 0,
                  }}
                >
                  <Form>
                    <Grid container spacing={2.5}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="subtitle1" color="grey">
                          <strong>JA Doctor's Name:</strong>{" "}
                          {editeDoctorData?.fullName}
                        </Typography>
                        <Box sx={{ mb: "1rem" }}>
                          <Divider></Divider>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="subtitle1" color={"#d32f2f"}>
                          Personal Details
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item xs={12} sm={4} md={4}>
                        <Field
                          component={TextField}
                          id="firstname"
                          name="firstname"
                          size="small"
                          label="First Name*"
                          fullWidth
                          variant="outlined"
                          error={touched.firstname && Boolean(errors.firstname)}
                          helperText={touched.firstname && errors.firstname}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Field
                          component={TextField}
                          id="middleName"
                          name="middlename"
                          size="small"
                          label="Middle Name"
                          fullWidth
                          variant="outlined"
                          error={
                            touched.middlename && Boolean(errors.middlename)
                          }
                          helperText={touched.middlename && errors.middlename}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Field
                          component={TextField}
                          id="lastname"
                          name="lastname"
                          size="small"
                          label="Last Name*"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Field
                            component={DatePicker}
                            id="date"
                            name="dateofbirth"
                            size="small"
                            label="DOB*"
                            fullWidth
                            variant="outlined"
                            className="date_picker"
                            disableFuture
                            format="DD-MM-YYYY"
                            defaultValue={
                              values.dateofbirth
                                ? dayjs(values.dateofbirth)
                                : ""
                            }
                            onChange={(date: any) => {
                              setFieldValue(
                                "dateofbirth",
                                moment(date.$d).format()
                              );
                            }}
                            error={
                              touched.dateofbirth && Boolean(errors.dateofbirth)
                            }
                            helperText={
                              touched.dateofbirth && errors.dateofbirth
                            }
                          />
                          <span
                            style={{ color: "#d32f2f", fontSize: "0.75rem" }}
                          >
                            {values.dateofbirth
                              ? ""
                              : "Date Of Birth Is Required"}
                          </span>
                        </LocalizationProvider>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Field
                          component={TextField}
                          id="mobile"
                          name="mobile"
                          size="small"
                          label="Mobile*"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Field
                          component={TextField}
                          id="email"
                          name="email"
                          size="small"
                          label="Email*"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="address"
                          name="address"
                          size="small"
                          label="Address*"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Field
                          component={TextField}
                          id="city"
                          name="city"
                          size="small"
                          label="City*"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Field
                          component={TextField}
                          type="text"
                          name="state"
                          id="state"
                          label="State*"
                          select
                          variant="outlined"
                          fullWidth
                          size="small"
                        >
                          {states?.map((option: StateStruct, index: number) => (
                            <MenuItem key={index} value={option.abbrevation}>
                              {option.state}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4}>
                        <Field
                          component={TextField}
                          id="postalCode"
                          name="postalcode"
                          size="small"
                          label="Postal Code*"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Typography variant="subtitle1" color={"#d32f2f"}>
                          Additional Details
                        </Typography>
                      </Grid>
                      <Divider />
                      <Grid item xs={12} sm={6} md={6}>
                        <Field
                          component={TextField}
                          id="ABNnumber"
                          name="abn"
                          size="small"
                          label="ABN*"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Field
                          component={TextField}
                          id="ABNAssociatedName"
                          name="abnassociatedname"
                          label="ABN Associated Name*"
                          size="small"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="ahpraregistrationnumber"
                          name="ahpraregistrationnumber"
                          label="Ahpra Registration Number(Including Medical)*"
                          size="small"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="prescriberNumber"
                          name="prescribernumber"
                          label="Prescriber Number*"
                          size="small"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Field
                          component={TextField}
                          id="currentorpreviousprovidernumber"
                          name="currentorpreviousprovidernumber"
                          label="Current or Previous Provider Number*"
                          size="small"
                          fullWidth
                          variant="outlined"
                        />
                      </Grid>

                      <Divider />
                      <Grid container spacing={2.5} p={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="subtitle1" color={"#d32f2f"}>
                            Banking Details
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Field
                            component={TextField}
                            id="financial_institution_name"
                            name="financial_institution_name"
                            label="Financial Institution Name*"
                            size="small"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Field
                            component={TextField}
                            id="account_name"
                            name="account_name"
                            label="Account Name*"
                            size="small"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2.5} p={2}>
                        <Grid item xs={12} sm={6} md={6}>
                          <Field
                            component={TextField}
                            id="bsb_number"
                            name="bsb_number"
                            label="BSB Number*"
                            size="small"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Field
                            component={TextField}
                            id="account_number"
                            name="account_number"
                            label="Account Number*"
                            size="small"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2.5} p={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Typography variant="subtitle1" color={"#d32f2f"}>
                            MYOB Details
                          </Typography>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Field
                            component={TextField}
                            id="myoB_customer_ID"
                            name="myoB_customer_ID"
                            label="MYOB Customer ID"
                            size="small"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Field
                            component={TextField}
                            id="customer_account_code"
                            name="customer_account_code"
                            label="Customer Account Code"
                            size="small"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <div className="clinic-management-add-footer">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Button
                          variant="contained"
                          sx={{ mt: 3, mr: 2 }}
                          style={{
                            marginBottom: "20px",
                            fontSize: isMobileView ? "10px" : "15px",
                          }}
                          disabled={isSubmitting}
                          type="submit"
                          onClick={() => {
                            setFlowChecker("Normal");
                          }}
                        >
                          Update
                        </Button>
                        {editeDoctorData?.is_form_filled &&
                          globalAppState?.userInfo?.Role === "2" &&
                          editeDoctorData?.status && (
                            <Button
                              variant="contained"
                              type="submit"
                              sx={{ mt: 3, mr: 2 }}
                              style={{
                                marginBottom: "20px",
                                fontSize: isMobileView ? "10px" : "15px",
                              }}
                              disabled={isSubmitting}
                              onClick={() => {
                                setFlowChecker("Special");
                              }}
                            >
                              Update & Add New Fee
                            </Button>
                          )}
                        <Button
                          variant="outlined"
                          sx={{ mt: 3 }}
                          style={{
                            marginBottom: "20px",
                            fontSize: isMobileView ? "10px" : "15px",
                          }}
                          onClick={redirectCallback}
                        >
                          Cancel
                        </Button>
                      </Box>
                      {/* <Box margin={1}>
                    <Field component={TimePicker} name="time" label="Time" />
                  </Box> */}
                    </div>
                  </Form>
                </Box>
              </>
            )}
          </Formik>
        )}
      </Container>
    </React.Fragment>
  );
};
