import { any } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { viewAgreementData } from "../../api/agreement-api/Agreement.service";
import { AppDispatch, RootState } from "../../store/store";
import { AgreementDoctorData, AgreementScreenProps } from "./types";

export const ViewAgreement = ({
  data = [],
}: AgreementScreenProps): JSX.Element => {
  const dispatch:AppDispatch= useDispatch();
  const {agreementUrl} = useSelector((state:RootState) => state.agreementState);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const agreementId = params.id;
    console.log('agreementId');
    console.log(agreementId);
    dispatch(viewAgreementData(agreementId));
    if(agreementUrl?.url){
      window.open(agreementUrl.url, '_blank', 'noopener,noreferrer')
      navigate("/agreementManagement");
    }
  }, []);

  return (
      <div>View PDF</div>
  );
}
