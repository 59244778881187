import React from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const DoctorManagementHeader = ({ onClickHandle }: any): JSX.Element => {
  const { isMobileView } = useSelector((state: any) => state.globalAppState);

  return (
    <div>
      <Box component="main">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Stack spacing={1}>
              <Typography variant={isMobileView ? "h5" : "h4"}>
                Doctor Management
              </Typography>
            </Stack>
            <div>
              {/* <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={onClickHandle}
              >
                Add
              </Button> */}
            </div>
          </Stack>
        </Stack>
      </Box>
    </div>
  );
};

export default DoctorManagementHeader;
