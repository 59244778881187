import React from "react";
import { Grid, Button, Box, Stack, Typography, useMediaQuery } from "@mui/material";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-mui";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { forgotPassword } from "../../api/Api.service";
import { useNavigate } from "react-router-dom";
var ImgWithDoc = require("../../assets/famdocwithdoc.jpg");
var ImgWithoutDoc = require("../../assets/famdoclogo.jpg");

interface Values {
  email: string;
}

const ConfirmPwd = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  return (
    <>
      <div className="login-container">
        {!isMobileView && <div className="left-login-container">
          <img src={ImgWithDoc} alt="" className="left-login-container-img" />
        </div>}
        <div className="right-login-container">
          <img
            src={ImgWithoutDoc}
            alt=""
            className="right-login-container-img"
          />
          <div className="login-form-container">
            <div className="heading-reset-pwd-container">
              <Box component="main">
                <Stack spacing={3}>
                  <Stack direction="row" justifyContent="center" spacing={4}>
                    <Stack spacing={1}>
                      <Typography variant="h5">Forgot Password</Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </div>
            <div style={{ padding: 30 }}>
              <Formik
                initialValues={{
                  email: "",
                }}
                validate={(values) => {
                  let errors: Partial<Values> = {};
                  if (!values.email) {
                    errors.email = "Please Enter Email";
                  } else if (
                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                      values.email
                    )
                  ) {
                    errors.email = "Invalid Email Address";
                  }
                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  const resetEmail = () => {
                    return (values.email = "");
                  };
                  dispatch(forgotPassword({ values, callback: resetEmail }));
                }}
              >
                {({
                  values,
                  submitForm,
                  resetForm,
                  isSubmitting,
                  touched,
                  errors,
                }) => (
                  <>
                    <Form>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Field
                            component={TextField}
                            fullWidth
                            name="email"
                            id="email"
                            label="Email"
                            variant="outlined"
                            required
                            size="small"
                          ></Field>
                        </Grid>

                        <Grid item xs={6}>
                          <Button
                            onClick={() => {
                              navigate(`/`);
                            }}
                            fullWidth
                            size="small"
                            variant="outlined"
                            style={{
                              color: "brown",
                              border: "2px solid brown",
                            }}
                          >
                            back to login
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            onClick={submitForm}
                            fullWidth
                            size="small"
                            variant="contained"
                            disabled={isSubmitting}
                          >
                            Confirm
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmPwd;
