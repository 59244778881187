import { Avatar, Box, Stack, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { PopoverComponent } from "./popover";
import { usePopover } from "../../hooks";
import { useSelector } from "react-redux";
//@ts-ignore
import AppLogo from "../../assets/doctorLogo.png";
import { useNavigate } from "react-router-dom";
const TOP_NAV_HEIGHT = 64;

const Navbar = () => {
  const state = useSelector((state): any => state);
  const navigate = useNavigate();
  const { isMobileView } = useSelector((state: any) => state.globalAppState);
  const accountPopover = usePopover();
  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: "blur(6px)",
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.8),
          position: "sticky",
          top: 0,
          zIndex: (theme) => theme.zIndex.appBar,
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2,
          }}
        >
          {isMobileView && (
            <div className="logo-container">
              <img
                style={{ cursor: "pointer", width: "100px", height: "40px" }}
                onClick={() => {
                  navigate("/");
                }}
                src={
                  "https://familydoctor.com.au/wp-content/uploads/elementor/thumbs/logo-p2821perot33o3dcnse8ygl6hwe0j9v2u29q5qxowo.jpg"
                }
                alt="none"
                className="fam-doc-logo"
              />
            </div>
          )}
          <div></div>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography variant="h6">
              {state.globalAppState?.userInfo?.Role === "2" ? "Admin" : "User"}
            </Typography>

            <Avatar
              onClick={accountPopover.handleOpen}
              ref={accountPopover.anchorRef}
              sx={{
                cursor: "pointer",
                height: 40,
                width: 40,
              }}
              src="/assets/avatars/avatar-anika-visser.png"
            >
              A
            </Avatar>
          </Stack>
        </Stack>
      </Box>
      <PopoverComponent
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};

export default Navbar;
