import { createSlice } from "@reduxjs/toolkit";
import {
  getAllDoctorServices,
  getDoctorServicesAndFees,
  getFeesListing,
} from "../../../api/fee-management-api/Fee.service";
import { getDoctorsName } from "../../../api/doctor-api/Api.service";
// import { feeData } from "../Data";

export interface ServiceAndFeesArray {
  encryptedServicesIds: string[];
  fees: number | null;
}

export interface FeeStateData {
  id?: string | number;
  encryptedDoctorId: string;
  encryptedClinicId: string;
  publicHolidayFee?: number;
  afterHoursFee?: number;
  weekendFee?: number;
  servicesAndFees?: ServiceAndFeesArray[];
  encryptedFeesMasterId?: string;
  serviceFeesStatus: null | 0 | 1;
}

interface AllServicesStruct {
  encryptedServiceId: string;
  serviceCode: string;
  serviceName: string;
}

interface DoctorsName {
  id: string;
  name: string;
}

interface FeeState {
  fees: FeeStateData[];
  editeFeeData: FeeStateData | {};
  totalRecords: number;
  allServices: AllServicesStruct[] | [];
  dropDownDoctors: DoctorsName[];
  feeLoading: boolean;
  serviceFeesStatusLoading: boolean;
  feeAddPageLoading: boolean;
  feeEditPageLoading: boolean;
}

const initialState: FeeState = {
  fees: [],
  editeFeeData: {},
  totalRecords: 0,
  allServices: [],
  dropDownDoctors: [],
  feeLoading: false,
  serviceFeesStatusLoading: false,
  feeAddPageLoading: false,
  feeEditPageLoading: false,
};

const FeeSlice = createSlice({
  name: "fee",
  initialState,
  reducers: {
    feeTableIsLoading: (state, { payload }) => {
      state.feeLoading = payload;
    },
    serviceFeesStatusIsLoading: (state, { payload }) => {
      state.serviceFeesStatusLoading = payload;
    },
    feeAddIsLoading: (state, { payload }) => {
      state.feeAddPageLoading = payload;
    },
    feeEditIsLoading: (state, { payload }) => {
      state.feeEditPageLoading = payload;
    },
    serviceFeesStatusToggle: (state, { payload }) => {
      state.fees = state.fees.map((e: any) => {
        if (e.encryptedFeesMasterId === payload) {
          if (e.feeStatus === 0) {
            return { ...e, feeStatus: 1 };
          } else {
            return { ...e, feeStatus: 0 };
          }
        } else {
          return e;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFeesListing.fulfilled, (state, { payload }: any) => {
      state.totalRecords = payload?.result.totalRecords;
      state.fees = payload.result.data.map((e: any, i: number) => ({
        id: i,
        ...e,
      }));
    });
    builder.addCase(
      getDoctorServicesAndFees.fulfilled,
      (state, { payload }: any) => {
        if (payload.result.servicesAndFees === null) {
          state.editeFeeData = {
            ...payload.result,
            servicesAndFees: [{ encryptedServicesIds: [], fees: null }],
          };
        } else {
          state.editeFeeData = payload.result;
        }
      }
    );
    builder.addCase(
      getAllDoctorServices.fulfilled,
      (state, { payload }: any) => {
        state.allServices = payload.result;
      }
    );
    builder.addCase(getDoctorsName.fulfilled, (state, { payload }: any) => {
      state.dropDownDoctors = payload.result;
    });
  },
});
export const FeeSliceReducer = FeeSlice.reducer;
export const {
  feeTableIsLoading,
  serviceFeesStatusIsLoading,
  serviceFeesStatusToggle,
  feeEditIsLoading,
  feeAddIsLoading,
} = FeeSlice.actions;
