import {
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import { TextField } from "formik-mui";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.css";
import Select, { SelectChangeEvent } from "@mui/material/Select/Select";
import { Box } from "@mui/material";
import { AppDispatch, RootState } from "../../store/store";
import {
  agreementAllApiResolve,
  getDoctorData,
  getDoctorNameList,
  saveAgreement,
} from "../../api/agreement-api/Agreement.service";
import { useDispatch, useSelector } from "react-redux";
import {
  AgreementDoctorData,
  AgreementStateData,
  DoctorNameList,
} from "./types";
import {
  DoctorList,
  clearAgreementDoctorDataAgreements,
} from "./slice/AgreementSlice";
import { styled } from "@mui/material/styles";
const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  maxWidth: "100%",
  color: theme.palette.text.primary,
}));

const emptyServiceObject = {
  selectServices: "",
  ManagementFees: "",
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface AddAgreementPayload {
  data: string;
  callback: Function;
}

interface Values {
  selectDoctor: string;
}

export function AddAgreement() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { doctorNameList } = useSelector(
    (state: RootState) => state.agreementState
  );
  const { agreementDoctorData, addAgreementIsLoading } = useSelector(
    (state: RootState) => state.agreementState
  );

  const clinicsAndServices = useSelector(
    (state: RootState) =>
      state.agreementState?.agreementDoctorData?.consultationServiceCharges
        ?.clinicsAndServices
  );
  const [doctorName, setDoctorName] = useState<DoctorNameList[]>([]);

  useEffect(() => {
    if (params.id) {
      dispatch(agreementAllApiResolve(params.id));
    } else {
      dispatch(getDoctorNameList());
    }
    return () => {
      dispatch(clearAgreementDoctorDataAgreements());
    };
  }, []);

  const handleChange = (event: SelectChangeEvent<string>, fieldName: any) => {
    const {
      target: { value },
    } = event;
    dispatch(getDoctorData(value));
    fieldName("selectDoctor", value);
  };

  const createList = (services: string) => {
    const serviceList = services.split(": ,");
    return (
      <List>
        {serviceList.map((service: string, index: number) => {
          return <ListItem key={index}> ◾ {service}</ListItem>;
        })}
      </List>
    );
  };
  const { isMobileView } = useSelector((state: any) => state.globalAppState);

  return (
    <Box>
      <Container sx={{ width: "100%", p: 2, ml: 0 }}>
        <Typography
          variant={isMobileView ? "h5" : "h4"}
          color="inherit"
          mb={2}
          noWrap
        >
          Add Agreement
        </Typography>

        <Formik
          initialValues={{
            selectDoctor: params.id ? params.id : "",
            doctorNameList: [],
            agreementDoctorData,
          }}
          validate={(values) => {
            const errors: Partial<Values> = {};
            if (values.selectDoctor == "") {
              errors.selectDoctor = "Please select doctor";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            const navigateback = () => {
              return navigate(`/agreementManagement`);
            };
            const payloadData = {
              data: values.selectDoctor,
              callback: navigateback,
            };
            dispatch(saveAgreement(payloadData));
          }}
        >
          {({
            values,
            submitForm,
            errors,
            isSubmitting,
            isValid,
            setFieldValue,
          }) => (
            <Box
              ml={0}
              sx={{
                p: 3,
                backgroundColor: "#fff ",
                height: "100%",
                ml: 0,
              }}
            >
              {addAgreementIsLoading ? (
                <>
                  <Stack spacing={3}>
                    <Skeleton
                      variant="rounded"
                      sx={{ height: "5vh", width: "100%" }}
                      animation={"pulse"}
                    />
                    {[...Array(3)].map(() => (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                        spacing={2}
                      >
                        ◾
                        <Skeleton
                          variant="rounded"
                          sx={{ height: "3", width: "70%" }}
                          animation={"wave"}
                        />
                      </Grid>
                    ))}
                    <Grid
                      sx={{
                        gap: "30px",
                        display: "flex",
                        marginTop: "20px",
                      }}
                      spacing={2}
                    >
                      <Skeleton
                        variant="rounded"
                        sx={{ height: "6vh", width: "150px" }}
                      />
                    </Grid>
                  </Stack>
                </>
              ) : (
                <Form autoComplete="off">
                  <Grid container direction="column" spacing={2}>
                    <Grid item xs={12} width={"100%"}>
                      <Field
                        component={TextField}
                        required
                        type="text"
                        name="selectDoctor"
                        id="selectDoctor"
                        label="Select Doctor"
                        select
                        /* value={selectedDoctor} */
                        onChange={(event: any) =>
                          handleChange(event, setFieldValue)
                        }
                        variant="outlined"
                        fullWidth
                        size="small"
                      >
                        {Array.isArray(doctorNameList) &&
                          doctorNameList?.map(
                            (option: DoctorNameList, index: number) => (
                              <MenuItem key={index} value={option?.encryptedId}>
                                {option?.firstName} {option?.lastName}
                              </MenuItem>
                            )
                          )}
                      </Field>
                    </Grid>
                    {Object.keys(agreementDoctorData)?.length !== 0 &&
                      values.selectDoctor && (
                        <>
                          <Box
                            sx={{ width: "100%", maxWidth: 600 }}
                            mt={2}
                            ml={2}
                          >
                            <Typography variant="h6" gutterBottom>
                              Contract Information
                            </Typography>
                          </Box>

                          <Box
                            mt={2}
                            mb={3}
                            sx={{
                              flexGrow: 1,
                              px: 3,
                              display: "flex",
                              flexDirection: isMobileView ? "column" : "row",
                              gap: "30px",
                              width: "90%",
                            }}
                          >
                            <StyledPaper
                              sx={{
                                my: 1,
                                ml: "0",
                                p: 2,
                                flex: 1,
                                boxShadow: "1px 1px 5px 0px black",
                                position: "relative",
                              }}
                            >
                              <Grid container wrap="nowrap" spacing={2}>
                                <Grid item xs zeroMinWidth>
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems={"center"}
                                    spacing={4}
                                  >
                                    <Stack spacing={1}>
                                      <Typography variant="h5" noWrap mt={2}>
                                        Doctor Details
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                  <Box sx={{ mb: "1rem" }}>
                                    <Divider></Divider>
                                  </Box>

                                  {agreementDoctorData?.firstName ? (
                                    <Grid item xs zeroMinWidth>
                                      <Typography
                                        sx={{ my: 1, fontSize: 14 }}
                                        variant="body1"
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: 600,
                                            color: "#464646",
                                          }}
                                        >
                                          Full Name:{" "}
                                        </Box>
                                        {agreementDoctorData?.firstName}{" "}
                                        {agreementDoctorData?.middleName
                                          ? agreementDoctorData?.middleName
                                          : ""}{" "}
                                        {agreementDoctorData?.lastName}
                                      </Typography>
                                      <Typography
                                        sx={{ my: 1, fontSize: 14 }}
                                        variant="body1"
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: 600,
                                            color: "#464646",
                                          }}
                                        >
                                          ABN:{" "}
                                        </Box>
                                        {agreementDoctorData?.abn}
                                      </Typography>
                                      <Typography
                                        sx={{ my: 1, fontSize: 14 }}
                                        variant="body1"
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: 600,
                                            color: "#464646",
                                          }}
                                        >
                                          Email:{" "}
                                        </Box>
                                        {agreementDoctorData?.email}
                                      </Typography>
                                      <Typography
                                        sx={{ my: 1, fontSize: 14 }}
                                        variant="body1"
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: 600,
                                            color: "#464646",
                                          }}
                                        >
                                          Phone:{" "}
                                        </Box>
                                        {agreementDoctorData?.mobile}
                                      </Typography>

                                      <Typography
                                        sx={{ my: 1, fontSize: 14 }}
                                        variant="body1"
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: 600,
                                            color: "#464646",
                                          }}
                                        >
                                          Account Name:{" "}
                                        </Box>
                                        {agreementDoctorData?.accountName}
                                      </Typography>
                                      <Typography
                                        sx={{ my: 1, fontSize: 14 }}
                                        variant="body1"
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: 600,
                                            color: "#464646",
                                          }}
                                        >
                                          Account Number:{" "}
                                        </Box>
                                        {agreementDoctorData?.accountNumber}
                                      </Typography>
                                      <Typography
                                        sx={{ my: 1, fontSize: 14 }}
                                        variant="body1"
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: 600,
                                            color: "#464646",
                                          }}
                                        >
                                          BSB Number:{" "}
                                        </Box>
                                        {agreementDoctorData?.bsbNumber}
                                      </Typography>
                                      <Typography
                                        sx={{ my: 1, fontSize: 14 }}
                                        variant="body1"
                                      >
                                        <Box
                                          component="span"
                                          sx={{
                                            fontWeight: 600,
                                            color: "#464646",
                                          }}
                                        >
                                          Financial Institution Name:{" "}
                                        </Box>
                                        {
                                          agreementDoctorData?.financialInstitutionName
                                        }
                                      </Typography>
                                    </Grid>
                                  ) : (
                                    <Typography
                                      sx={{ my: 1, fontSize: 14 }}
                                      variant="body1"
                                    >
                                      <Box
                                        component="span"
                                        sx={{
                                          fontWeight: 600,
                                          textTransform: "capitalize",
                                          color: "#464646",
                                        }}
                                      >
                                        Doctor has not filled the form yet!
                                      </Box>
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </StyledPaper>
                            <StyledPaper
                              sx={{
                                my: 1,
                                ml: "0",
                                p: 2,
                                overflowY: "scroll",
                                flex: 1,
                                boxShadow: "1px 1px 5px 0px  black",
                                position: "relative",
                              }}
                            >
                              <Grid container wrap="nowrap" spacing={2}>
                                <Grid item xs zeroMinWidth>
                                  <Typography variant="h5" noWrap mt={2}>
                                    Consultation Service Charges
                                  </Typography>
                                  <Box sx={{ mb: "1rem" }}>
                                    <Divider></Divider>
                                  </Box>
                                  {clinicsAndServices?.length !== 0 ? (
                                    clinicsAndServices?.map(
                                      (clinics, index) => (
                                        <Grid item xs zeroMinWidth>
                                          <Typography
                                            variant="body1"
                                            sx={{
                                              fontWeight: 600,
                                              color: "firebrick",
                                            }}
                                            noWrap
                                          >
                                            <Box
                                              component="span"
                                              sx={{
                                                fontWeight: 600,
                                                color: "#464646",
                                              }}
                                            >
                                              Clinic Name:{" "}
                                            </Box>
                                            {clinics?.clinicName}
                                          </Typography>
                                          <Box
                                            sx={{ mb: "1rem", width: "50%" }}
                                          >
                                            <Divider></Divider>
                                          </Box>
                                          {clinics?.allServicesAndFeeLocation?.map(
                                            (serviceFeeLoc, key) => (
                                              <>
                                                <Typography
                                                  sx={{ my: 1, fontSize: 14 }}
                                                  variant="body1"
                                                >
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      fontWeight: 600,
                                                      color: "#464646",
                                                    }}
                                                  >
                                                    Location Name:{" "}
                                                  </Box>
                                                  {serviceFeeLoc?.locationName}
                                                </Typography>
                                                <Typography
                                                  sx={{ my: 1, fontSize: 14 }}
                                                  variant="body1"
                                                >
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      fontWeight: 600,
                                                      color: "#464646",
                                                    }}
                                                  >
                                                    Default Fees:{" "}
                                                  </Box>
                                                  {Number(
                                                    serviceFeeLoc?.defaultFee
                                                  )?.toFixed(2)}{" "}
                                                  %
                                                </Typography>

                                                <Typography
                                                  sx={{ my: 1, fontSize: 14 }}
                                                  variant="body1"
                                                >
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      fontWeight: 600,
                                                      color: "#464646",
                                                    }}
                                                  >
                                                    Public Holiday Fees:{" "}
                                                  </Box>
                                                  {serviceFeeLoc?.publicHolidayFee ===
                                                  null
                                                    ? "NA"
                                                    : `${Number(
                                                        serviceFeeLoc?.publicHolidayFee
                                                      )?.toFixed(2)} %`}
                                                </Typography>
                                                <Typography
                                                  sx={{ my: 1, fontSize: 14 }}
                                                  variant="body1"
                                                >
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      fontWeight: 600,
                                                      color: "#464646",
                                                    }}
                                                  >
                                                    After Hour Fees:{" "}
                                                  </Box>
                                                  {serviceFeeLoc?.afterHoursFee ===
                                                  null
                                                    ? "NA"
                                                    : `${Number(
                                                        serviceFeeLoc?.afterHoursFee
                                                      )?.toFixed(2)} %`}
                                                </Typography>
                                                <Typography
                                                  sx={{ my: 1, fontSize: 14 }}
                                                  variant="body1"
                                                >
                                                  <Box
                                                    component="span"
                                                    sx={{
                                                      fontWeight: 600,
                                                      color: "#464646",
                                                    }}
                                                  >
                                                    Weekend Fees:{" "}
                                                  </Box>
                                                  {serviceFeeLoc?.weekendFee ===
                                                  null
                                                    ? "NA"
                                                    : `${Number(
                                                        serviceFeeLoc?.weekendFee
                                                      )?.toFixed(2)} %`}
                                                </Typography>
                                                {serviceFeeLoc
                                                  ?.allServicesAndFee
                                                  ?.length === 0 ? (
                                                  <>
                                                    <Grid
                                                      container
                                                      sx={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        paddingBottom: "7px",
                                                        gap: 1,
                                                      }}
                                                    >
                                                      <Box
                                                        component="span"
                                                        sx={{
                                                          color: "#464646",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          Fee:{" "}
                                                        </span>
                                                        NA
                                                      </Box>
                                                      <Box
                                                        component="span"
                                                        sx={{
                                                          color: "#464646",
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontWeight: 600,
                                                          }}
                                                        >
                                                          Services:{" "}
                                                        </span>
                                                        NA
                                                      </Box>
                                                    </Grid>
                                                  </>
                                                ) : (
                                                  <>
                                                    {serviceFeeLoc?.allServicesAndFee?.map(
                                                      (serviceFee, key) => (
                                                        <>
                                                          <Typography
                                                            sx={{
                                                              my: 1,
                                                              fontSize: 14,
                                                            }}
                                                            variant="body1"
                                                          >
                                                            <Box
                                                              component="span"
                                                              sx={{
                                                                fontWeight: 600,
                                                                color:
                                                                  "#464646",
                                                              }}
                                                            >
                                                              Fee:{" "}
                                                            </Box>
                                                            {serviceFee?.fees ===
                                                            null
                                                              ? "NA"
                                                              : `${Number(
                                                                  serviceFee?.fees
                                                                )?.toFixed(
                                                                  2
                                                                )} %`}
                                                          </Typography>
                                                          <Typography
                                                            sx={{
                                                              my: 1,
                                                              fontSize: 14,
                                                            }}
                                                            variant="body1"
                                                          >
                                                            <Box
                                                              component="span"
                                                              sx={{
                                                                fontWeight: 600,
                                                                color:
                                                                  "#464646",
                                                              }}
                                                            >
                                                              Services:
                                                            </Box>
                                                            <>
                                                              <List
                                                                sx={{ py: 0 }}
                                                              >
                                                                {serviceFee?.services.map(
                                                                  (
                                                                    service,
                                                                    i
                                                                  ) => (
                                                                    <>
                                                                      <ListItem>
                                                                        {
                                                                          service?.serviceName
                                                                        }
                                                                      </ListItem>
                                                                    </>
                                                                  )
                                                                )}
                                                              </List>
                                                            </>
                                                          </Typography>
                                                        </>
                                                      )
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )
                                          )}
                                        </Grid>
                                      )
                                    )
                                  ) : (
                                    <Typography
                                      variant="body1"
                                      sx={{
                                        fontWeight: 600,
                                        color: "firebrick",
                                      }}
                                      noWrap
                                    >
                                      <Box
                                        component="span"
                                        sx={{
                                          fontWeight: 600,
                                          textTransform: "capitalize",
                                          color: "#464646",
                                        }}
                                      >
                                        No clinics assigned!
                                      </Box>
                                    </Typography>
                                  )}
                                </Grid>
                              </Grid>
                            </StyledPaper>
                          </Box>
                        </>
                      )}

                    <Box sx={{ width: "100%", maxWidth: 600 }} mt={1} ml={2}>
                      {Object.keys(agreementDoctorData).length !== 0 &&
                        values.selectDoctor && (
                          <>
                            <Button
                              variant="contained"
                              sx={{ mt: 3 }}
                              style={{
                                marginRight: "20px",
                                marginBottom: "20px",
                              }}
                              disabled={isSubmitting}
                              onClick={submitForm}
                            >
                              Save
                            </Button>
                          </>
                        )}
                      <Button
                        variant="outlined"
                        sx={{ mt: 3, ml: 1, mx: 2 }}
                        style={{
                          marginLeft: "0",
                          marginBottom: "20px",
                        }}
                        onClick={() => navigate(`/agreementManagement`)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  </Grid>
                </Form>
              )}
            </Box>
          )}
        </Formik>
      </Container>
    </Box>
  );
}
