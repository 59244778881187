import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  authLogin,
  refreshUserData,
  verifyResetPasswordLink,
} from "../../api/Api.service";
import { RootState } from "../../store/store";

enum Roles {
  Doctor = "1",
  Admin = "2",
}

interface UserDetails {
  fullName: string;
  Role: Roles;
  ID: String;
}

interface PayloadUserInfo {
  userInfo: UserDetails;
  authToken: string;
}
interface AppState {
  userInfo: UserDetails | null;
  authToken: string;
  isLoading: boolean;
  isSnackBarOpen: boolean;
  snackBarVariant: AlertColor;
  snackBarMessage: string;
  refreshToken: string;
  isAuthenticated: boolean;
  expiryTime: string | number;
  isMobileView: boolean;
  verifyResetLinkFailed: boolean;
}

const initialState: AppState = {
  userInfo: null,
  authToken: "",
  isLoading: false,
  isSnackBarOpen: false,
  snackBarVariant: "success",
  snackBarMessage: "",
  isAuthenticated: false,
  refreshToken: "",
  expiryTime: "",
  isMobileView: false,
  verifyResetLinkFailed: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    refreshStatePersist: (state, { payload }) => {
      state.authToken = payload.token;
      state.userInfo = payload.userinfo;
      state.isAuthenticated = payload.token ? true : false;
    },
    triggrViewChange: (state, { payload }) => {
      state.isMobileView = payload;
    },
    toggleSnacker: (state, { payload }) => {
      const { isOpen, variant, message } = payload;
      state.isSnackBarOpen = isOpen;
      state.snackBarVariant = variant;
      state.snackBarMessage = message;
    },
    toggleLoader: (state, { payload }) => {
      const { isLoading } = payload;
      state.isLoading = isLoading;
    },
    SignoutTrigger: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authLogin.fulfilled, (state, { payload }: any) => {
      state.userInfo = payload.userInfo;
      state.authToken = payload.authToken;
      state.refreshToken = payload.refreshToken;
      state.expiryTime = payload.expiryTime;
      state.isAuthenticated = payload.authToken ? true : false;
      state.isLoading = false;
    });
    builder.addCase(refreshUserData.fulfilled, (state, { payload }: any) => {
      state.authToken = payload.authToken;
      state.refreshToken = payload.refreshToken;
      state.expiryTime = payload.expiryTime;
    });
    builder.addCase(
      verifyResetPasswordLink.fulfilled,
      (state, { payload }: any) => {
        state.verifyResetLinkFailed = payload;
      }
    );
  },
});

export const appSliceReducer = appSlice.reducer;
export const {
  SignoutTrigger,
  toggleSnacker,
  toggleLoader,
  refreshStatePersist,
  triggrViewChange,
} = appSlice.actions;
export const globalSelector = (state: RootState) => state.globalAppState;
