import { Alert, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeSnackbar } from "../../api/Api.service";
import { toggleSnacker } from "../../domian/app/AppSlice";
import { AppDispatch, RootState } from "../../store/store";

export default function SnackbarComponent() {
  const dispatch: AppDispatch = useDispatch();
  const open = useSelector(
    (state: RootState) => state.globalAppState.isSnackBarOpen
  );
  const snackbarMsg = useSelector(
    (state: RootState) => state.globalAppState.snackBarMessage
  );
  const snackbarVariant = useSelector(
    (state: RootState) => state.globalAppState.snackBarVariant
  );
  useEffect(() => {
    var timer: any;
    if (open) {
      timer = setTimeout(() => {
        dispatch(toggleSnacker(closeSnackbar));
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [open]);
  return (
    <React.Fragment>
      {open && (
        <Snackbar
          open={open}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity={snackbarVariant} sx={{ width: "100%" }}>
            {snackbarMsg}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
}
