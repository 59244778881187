import {
  GET_CLINICS,
  ADD_CLINICS,
  EDIT_CLINICS,
  UPDATE_CLINIC_STATUS,
} from "../ActionConstants";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toggleLoader, toggleSnacker } from "../../domian/app/AppSlice";
import API from "../Api.utils";
import {
  closeLoaderPayload,
  errorOpen,
  openLoaderPayload,
  successOpen,
} from "../Api.service";
import {
  AGREEMENT_LIST,
  CREATE_AGREEMENT,
  DOCTOR_DATA,
  DOCTOR_NAME_LIST,
  VIEW_AGREEMENT,
} from "../Constant";
import { AddAgreementPayload } from "../../pages/agreementManagement/AddAgreement";
import { AgreementManagementPayload } from "../../pages/agreementManagement/AgreementManagement";
import {
  agreementAddIsLoading,
  agreementTableIsLoading,
  clearAgreementDoctorDataAgreements,
} from "../../pages/agreementManagement/slice/AgreementSlice";

export const getAgreementList = createAsyncThunk(
  "getAgreements",
  async (payload: AgreementManagementPayload, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(agreementTableIsLoading(true));
      let queryStr = "";
      if (payload.doctor_name != "") {
        queryStr += `&doctor_name=${payload.doctor_name}`;
      }
      if (payload.agreement_status != "") {
        queryStr += `&agreement_status=${payload.agreement_status}`;
      }
      const response = await API.get(
        `AdobeAgreement/SearchAgreement?PageSize=${payload.PageSize}&PageNumber=${payload.PageNumber}${queryStr}`,
        { signal }
      );
      const data = response.data;
      //dispatch(toggleSnacker(successOpen("sucessfully login")));
      return data;
    } catch (error) {
      dispatch(toggleSnacker(errorOpen("Error While Login...!")));
      dispatch(agreementTableIsLoading(false));

      return error;
    } finally {
      dispatch(agreementTableIsLoading(false));
    }
  }
);

export const getDoctorNameList = createAsyncThunk(
  "getDoctorNames",
  async (_, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(agreementAddIsLoading(true));
      const response = await API.get(DOCTOR_NAME_LIST, { signal });
      const data = response.data.result;
      return data;
    } catch (error) {
      dispatch(toggleSnacker(errorOpen("Error While Login...!")));
      dispatch(agreementAddIsLoading(false));
      return error;
    } finally {
      dispatch(agreementAddIsLoading(false));
    }
  }
);

export const getDoctorNameListIsotope = createAsyncThunk(
  "GET_DOC_NAME_ISOTOPE",
  async (_, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const response = await API.get(DOCTOR_NAME_LIST, { signal });
      const data = response.data.result;
      //dispatch(toggleSnacker(successOpen("sucessfully login")));
      return data;
    } catch (error) {
      dispatch(toggleSnacker(errorOpen("Error While Login...!")));

      return error;
    }
  }
);

//getDoctorData
export const getDoctorData = createAsyncThunk(
  "getDoctorData",
  async (doctorID: string, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(agreementAddIsLoading(true));
      dispatch(clearAgreementDoctorDataAgreements());
      const response = await API.get(DOCTOR_DATA + "?user_id=" + doctorID, {
        signal,
      });
      const data = response.data.result;
      //dispatch(toggleSnacker(successOpen("sucessfully login")));
      return data;
    } catch (error) {
      dispatch(toggleSnacker(errorOpen("Something Went Wrong!")));
      dispatch(agreementAddIsLoading(false));
      return error;
    } finally {
      dispatch(agreementAddIsLoading(false));
    }
  }
);

export const getDoctorDataIsotope = createAsyncThunk(
  "GET_DOCTOR_DATA_ISOTOPE",
  async (doctorID: string, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      const response = await API.get(DOCTOR_DATA + "?user_id=" + doctorID, {
        signal,
      });
      const data = response.data.result;
      //dispatch(toggleSnacker(successOpen("sucessfully login")));
      return data;
    } catch (error) {
      dispatch(toggleSnacker(errorOpen("Something Went Wrong!")));

      return error;
    } finally {
    }
  }
);

export const saveAgreement = createAsyncThunk(
  "saveAgreement",
  async (postData: AddAgreementPayload, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(
        CREATE_AGREEMENT,
        { user_id: postData.data },
        { signal }
      );
      if (response) {
        postData.callback();
        const data = response?.data?.result;
        dispatch(toggleSnacker(successOpen("Agreement Added Successfully!")));
        return data;
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error While Saving Agreement, Please Try Again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const viewAgreementData = createAsyncThunk(
  "viewAgreement",
  async (reqData: object | any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.get(
        VIEW_AGREEMENT + "?agreement_id=" + reqData.id,
        { signal }
      );
      const data = response.data.result;
      reqData?.callback(data?.url);
      //dispatch(toggleSnacker(successOpen("sucessfully login")));
      return data;
    } catch (error) {
      dispatch(toggleSnacker(errorOpen("Error While Login...!")));
      dispatch(toggleLoader(closeLoaderPayload));
      return error;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const agreementAllApiResolve = createAsyncThunk(
  "AGREEMENT_ADD/EDIT_API",
  async (payload: any, thunkApi) => {
    //const token = authLogin;
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(agreementAddIsLoading(true));
      await Promise.all([
        dispatch(getDoctorNameList()),
        dispatch(getDoctorDataIsotope(payload)),
      ]);
    } catch (error) {
      dispatch(agreementAddIsLoading(false));
      return [];
    } finally {
      dispatch(agreementAddIsLoading(false));
    }
  }
);
