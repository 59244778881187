import { createContext, ReactNode, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { refreshUserData } from "../api/Api.service";
import { globalSelector } from "../domian/app/AppSlice";
import { AppDispatch } from "../store/store";
const AuthContext = createContext(undefined);
type AuthProviderProps = {
  children: ReactNode;
};
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { expiryTime, refreshToken, authToken } = useSelector(globalSelector);
  const refreshTimeoutRef = useRef<number>();
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    const timer = setTimeout(() => {
      // call api to refresh
      if (authToken && refreshToken) {
        const reqData = {
          accessToken: authToken,
          refreshToken: refreshToken,
        };
        dispatch(refreshUserData(reqData));
      }
    }, Number(expiryTime) * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [authToken, expiryTime, refreshToken]);
  return (
    <AuthContext.Provider value={undefined}>{children}</AuthContext.Provider>
  );
};
