export const status = [
  {
    label: "None",
    value: 2,
  },
  {
    label: "Active",
    value: 0,
  },
  {
    label: "Inactive",
    value: 1,
  },
];
