import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Skeleton,
  Switch,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { status } from "./Data";
import FeeManagementHeader from "./FeeManagementHeader";
import { TableSearch } from "../../component/search/SearchComponent";
import { AppDispatch, RootState } from "../../store/store";
import "./styles.css";
import EditIcon from "@mui/icons-material/Edit";
import AddBoxIcon from "@mui/icons-material/AddBox";
import {
  UpdateserviceFeesStatus,
  deleteFees,
  feeEditAllApiResolve,
  getDoctorServicesAndFees,
  getFeesListing,
} from "../../api/fee-management-api/Fee.service";
import { FaEdit } from "react-icons/fa";
import MobileCards, { ScreenType } from "../../component/cards/MobileCards";
import { SkeletonLoadingOverlay } from "../../utils/listingSkeleton";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

export interface DeleteFeesPayload {
  data: { EncryptedMasterId: string };
  callback: Function;
}

export interface SearchFeePayload {
  SearchText: string;
  status: number | string;
  PageSize: number | string;
  PageNumber: number | string;
}

export interface GetDoctorServicesAndFeesPayload {
  data: { EncryptedDoctorMasterId: string };
  callback: Function;
}

export interface UpdateDocserviceFeesStatusPayload {
  data: { EncryptedMasterId: string; status: number };
  callback: Function;
}

export const Fee = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { fees, totalRecords, feeLoading, serviceFeesStatusLoading } =
    useSelector((state: RootState) => state.feeState);
  const { isMobileView } = useSelector(
    (state: RootState) => state.globalAppState
  );
  const [open, setOpen] = useState(false);
  const [deletId, setDeletId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [statusState, setStatusState] = useState<number | string>("");
  const [pageState, setPageState] = useState({
    page: 1,
    pageSize: 20,
  });
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setDeletId("");
  };
  //please optimize this as this is calling 2 times in staging
  useEffect(() => {
    dispatch(
      getFeesListing({
        SearchText: searchText,
        status: statusState === 2 ? "" : statusState,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  }, [pageState]);

  const onSearch = (value: string) => {
    setSearchText(value);
    dispatch(
      getFeesListing({
        SearchText: value,
        status: statusState === 2 ? "" : statusState,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };

  const columns: GridColDef[] = [
    {
      field: "doctorFirstName",
      headerName: "Doctor Name",
      width: 200,
      minWidth: 250,
      maxWidth: 400,
      renderCell: (params: any) => {
        const currentRow = params.row;
        const hrefLink = `/doctorManagement/detail/${currentRow?.encryptedDoctorId}`;
        return (
          <Link to={hrefLink}>
            {currentRow.doctorFirstName} {currentRow.doctorLastName}
          </Link>
        );
      },
    },
    {
      field: "clinicName",
      headerName: "Clinic Name",
      width: 200,
      minWidth: 250,
      maxWidth: 350,
    },
    {
      field: "serviceFeesStatus",
      headerName: serviceFeesStatusLoading ? "Loading..." : "Status",
      width: 200,
      minWidth: 250,
      maxWidth: 350,
      renderCell: (params: any) => {
        const handleToggle = () => {
          let { encryptedFeesMasterId, feeStatus } = params.row;
          let statusUpdatePayload;
          if (feeStatus === 1) {
            statusUpdatePayload = {
              callback: encryptedFeesMasterId,
              data: {
                EncryptedMasterId: encryptedFeesMasterId,
                status: 0,
              },
            };
          } else {
            statusUpdatePayload = {
              callback: encryptedFeesMasterId,
              data: {
                EncryptedMasterId: encryptedFeesMasterId,
                status: 1,
              },
            };
          }
          dispatch(UpdateserviceFeesStatus(statusUpdatePayload));
        };

        const checkstate = () => {
          const currentRow = params.row;
          // return currentRow?.status;
          if (currentRow?.feeStatus === 0) {
            return true;
          } else if (currentRow?.feeStatus === 1) {
            return false;
          } else {
            return true;
          }
        };
        return (
          <Stack direction="row" spacing={2}>
            <Switch
              size={"small"}
              checked={checkstate()}
              onChange={handleToggle}
              inputProps={{ "aria-label": "controlled" }}
            />
            <span>{checkstate() ? "Active" : "Inactive"}</span>
          </Stack>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 180,
      sortable: false,
      // disableClickEventBubbling: false,

      renderCell: (params: any) => {
        const onClick = (e: any) => {
          const currentRow = params.row;
          setDeletId(currentRow?.encryptedFeesMasterId);
          handleOpen();
        };
        const onEdit = (e: any) => {
          const currentRow = params.row;
          const navigateback = () => {
            return navigate(
              `/feeManagement/${currentRow?.encryptedFeesMasterId}`
            );
          };
          let editDoctorServiceAndFeesPayload = {
            callback: navigateback,
            data: {
              EncryptedDoctorMasterId: currentRow?.encryptedFeesMasterId,
            },
          };
          dispatch(feeEditAllApiResolve(editDoctorServiceAndFeesPayload));
        };

        return (
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title="Edit">
              <IconButton>
                <EditIcon color="error" onClick={onEdit}></EditIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton>
                <DeleteForeverIcon
                  color="error"
                  onClick={onClick}
                ></DeleteForeverIcon>
              </IconButton>
            </Tooltip>

            {!params?.row?.doctorStatus && params?.row?.isDoctorFormFilled && (
              <Tooltip title="Add Agreement">
                <IconButton>
                  <AddBoxIcon
                    color="error"
                    onClick={() => {
                      navigate(
                        `/agreementManagement/add/${params?.row?.encryptedDoctorId}`
                      );
                    }}
                  ></AddBoxIcon>
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  const onClickHandle = () => {
    navigate(`/feeManagement/add`);
  };

  const statusChangeMv = (value: any) => {
    if (value === 2) {
      setStatusState("");
    } else {
      setStatusState(value);
    }
    setStatusState(value);
  };
  const statusChange = (value: any) => {
    if (value === 2) {
      setStatusState("");
    } else {
      setStatusState(value);
    }
    setStatusState(value);

    dispatch(
      getFeesListing({
        SearchText: searchText,
        status: value === 2 ? "" : value,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };
  const applyFilter = () => {
    dispatch(
      getFeesListing({
        SearchText: searchText,
        status: statusState === 2 ? "" : statusState,
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };
  const resetFilter = () => {
    setSearchText("");
    setStatusState("");
    dispatch(
      getFeesListing({
        SearchText: "",
        status: "",
        PageSize: pageState.pageSize,
        PageNumber: pageState.page,
      })
    );
  };
  return (
    <Box>
      <Container maxWidth={false}>
        <Box py={2}>
          <FeeManagementHeader onClickHandle={onClickHandle} />
        </Box>
        <div
          style={{
            height: isMobileView ? "100%" : "77vh",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          {isMobileView ? (
            <div className="cards-contaienr">
              <TableSearch
                onSearchFn={onSearch}
                statusData={status}
                statusState={statusState}
                statusChange={statusChange}
                searchText={searchText}
                setSearchText={setSearchText}
                componentName={"Doctor"}
                statusChangeMv={statusChangeMv}
                resetFilter={resetFilter}
                applyFilter={applyFilter}
              />

              {feeLoading ? (
                <Stack sx={{ display: "flex", gap: "30px" }}>
                  {[...Array(1)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "80%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "30%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                  {[...Array(2)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "60%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "90%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                  {[...Array(4)].map(() => {
                    return (
                      <Grid
                        sx={{
                          gap: "30px",
                          display: "flex",
                        }}
                        spacing={2}
                      >
                        <div className="mobilecard-outline">
                          <div className="card-top-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "40%" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "2vh", width: "50%" }}
                            />
                          </div>

                          <div className="card-bot-skeleton-outline">
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                            <Skeleton
                              variant="rounded"
                              sx={{ height: "6vh", width: "6vh" }}
                            />
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Stack>
              ) : (
                <div className="card-scroll">
                  {fees?.length === 0 ? (
                    <Typography
                      variant="subtitle2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "rgb(60, 60, 60)",
                      }}
                    >
                      <strong>No Records Available </strong>
                    </Typography>
                  ) : (
                    fees?.map((fee: any) => {
                      return (
                        <>
                          <MobileCards
                            screen={ScreenType.FEE_MANAGEMENT}
                            data={fee}
                            searchText={searchText}
                            statusState={statusState}
                            pageState={pageState}
                          />
                        </>
                      );
                    })
                  )}
                </div>
              )}

              <TablePagination
                component="div"
                rowsPerPageOptions={[]}
                count={totalRecords}
                page={pageState.page - 1}
                onPageChange={(
                  event: React.MouseEvent<HTMLButtonElement> | null,
                  newPage: number
                ) => {
                  setPageState((old) => ({ ...old, page: newPage + 1 }));
                }}
                rowsPerPage={pageState.pageSize}
              />
            </div>
          ) : (
            <DataGrid
              sx={{
                border: 1,
                borderColor: "#e0e0e07a",
                "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                  outline: "none !important",
                },
                "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
                  outline: "none !important",
                },
                ".MuiDataGrid-columnHeader": {
                  position: "unset",
                },
                "& .MuiDataGrid-cell a": {
                  color: "primary.main",
                },
              }}
              rows={feeLoading ? [] : fees}
              loading={feeLoading}
              columns={columns}
              density={"standard"}
              disableColumnMenu={true}
              components={{
                LoadingOverlay: SkeletonLoadingOverlay,
                Toolbar: TableSearch,
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    No Records Available
                  </Stack>
                ),
              }}
              disableSelectionOnClick={true}
              rowCount={totalRecords}
              rowsPerPageOptions={[]}
              page={pageState.page - 1}
              pageSize={pageState.pageSize}
              paginationMode="server"
              onPageChange={(newPage) => {
                setPageState((old) => ({ ...old, page: newPage + 1 }));
              }}
              componentsProps={{
                toolbar: {
                  onSearchFn: onSearch,
                  statusData: status,
                  statusState: statusState,
                  statusChange: statusChange,
                  searchText: searchText,
                  setSearchText: setSearchText,
                  componentName: "Doctor",
                },
              }}
            />
          )}
        </div>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="delete-modal-container">
            <div className="button-delete-modal-container">
              <DeleteForeverIcon
                style={{
                  fontSize: "100px",
                  color: "#d32f2f",
                }}
              />
            </div>
            <Typography variant="h5" sx={{ textTransform: "capitalize" }}>
              Are you sure you want to delete?
            </Typography>
            <div className="button-delete-modal-container">
              <Button
                style={{ width: "90px" }}
                variant="contained"
                onClick={() => {
                  const navigateback = () => {
                    return dispatch(
                      getFeesListing({
                        SearchText: "",
                        status: "",
                        PageSize: pageState.pageSize,
                        PageNumber: pageState.page,
                      })
                    );
                  };
                  let deleteServiceAndFeesPayload = {
                    callback: navigateback,
                    data: {
                      EncryptedMasterId: deletId,
                    },
                  };
                  dispatch(deleteFees(deleteServiceAndFeesPayload));
                  handleClose();
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ width: "90px" }}
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};
