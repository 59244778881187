import { FormControl, InputLabel, ListSubheader, Select } from "@mui/material";
import { FieldProps } from "formik";
import { OptionsType, ValueType } from "react-select/lib/types";
import { useState } from "react";
import { Box, Checkbox, ListItemText, MenuItem } from "@mui/material";

interface Option {
  label?: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  className?: string;
  placeholder?: string;
}

export const CategorySelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti,
}: CustomSelectProps) => {
  const [isSelectAll, setIsSelectAll] = useState(false);
  //   const [isSelectItem, setIsSelectItem] = useState([]);

  const onChange = (option: Option) => {
    const checker = field.value.filter((selectedloc: any) => {
      return selectedloc.value === option.value;
    });
    if (checker.length === 1) {
      const filtered = field.value.filter((selectedloc: any) => {
        return selectedloc.value !== option.value;
      });

      form.setFieldValue(field.name, filtered);
      setIsSelectAll(false);
    } else {
      form.setFieldValue(field.name, [...field.value, option]);
      setIsSelectAll(false);
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const checker = (x: any) => {
    return field.value.map((e: any) => e.value).indexOf(x) > -1;
  };
  const updateAllItems = () => {
    if (isSelectAll) {
      setIsSelectAll(false);
      form.setFieldValue(field.name, []);
    } else {
      setIsSelectAll(true);
      const allSelect = options.map((option: any) => {
        return option.options;
      });
      var merged = [].concat.apply([], allSelect);
      form.setFieldValue(field.name, merged);
    }
  };
  function makeItems(data: any) {
    const items = [];
    for (let clinicname of data) {
      items.push(
        <ListSubheader key={clinicname.value}>{clinicname.label}</ListSubheader>
      );
      for (let location of clinicname.options) {
        items.push(
          <MenuItem
            key={clinicname.value + location.value}
            value={location.value}
            onClick={() => onChange(location)}
          >
            <Checkbox checked={checker(location.value)} />
            {location.label}
          </MenuItem>
        );
      }
    }
    return items;
  }
  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel
        id="demo-multiple-checkbox-label"
        style={{ backgroundColor: "white" }}
      >
        Select Locations *
      </InputLabel>
      <Select
        className={className}
        name={field.name}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        placeholder={placeholder}
        multiple
        value={field.value}
        renderValue={(selected) => selected.map((e: any) => e.label).join(", ")}
        size="medium"
        MenuProps={MenuProps}
      >
        <Box mt={1.5}>
          <MenuItem value={"selectAll"} onClick={updateAllItems}>
            <Checkbox checked={isSelectAll} />
            <ListItemText primary={"Select All"} />
          </MenuItem>

          {makeItems(options)}
        </Box>
      </Select>
    </FormControl>
  );
};
