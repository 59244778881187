import { createSlice } from "@reduxjs/toolkit";
import {
  changeStatus,
  deleteDoctor,
  getDoctorData,
  getDoctorDetail,
  getDoctorListing,
  searchDoctor,
} from "../../../api/doctor-api/Api.service";
import {
  AgreementDetailInformation,
  DoctorDetail,
  ServiceAndFeeCollection,
} from "../DoctorDetail";
import { DoctorStateData } from "../types";

interface DoctorState {
  doctors: DoctorStateData[];
  editeDoctorData: DoctorStateData;
  DoctorDetails: DoctorDetail;
  ClinicsAndServices: ServiceAndFeeCollection[];
  AgreementDetailInformation: AgreementDetailInformation;
  isSnackbarOpen: boolean;
  snackbarMsg: string;
  isDoctorLoading: boolean;
  isDoctorStatusLoading: boolean;
  totalRecords: number;
  isDoctorEditLoading: boolean;
  isDoctorListLoading: boolean;
  isPasswordUpdating: boolean;
}

const initialState: DoctorState = {
  doctors: [],
  editeDoctorData: {},
  DoctorDetails: {},
  ClinicsAndServices: [],
  AgreementDetailInformation: {},
  isSnackbarOpen: false,
  snackbarMsg: "",
  totalRecords: 0,
  isDoctorLoading: false,
  isDoctorStatusLoading: false,
  isDoctorEditLoading: false,
  isDoctorListLoading: false,
  isPasswordUpdating: false,
};
const DoctorSlice = createSlice({
  name: "doctor",
  initialState,
  reducers: {
    clearDoctors: (state) => {
      state.doctors = [];
    },
    doctorTableIsLoading: (state, { payload }) => {
      state.isDoctorLoading = payload;
    },
    passwordIsUpdating: (state, { payload }) => {
      state.isPasswordUpdating = payload;
    },
    doctorPersonalInfoLoading: (state, { payload }) => {
      state.isDoctorLoading = payload;
    },
    doctorListIsLoading: (state, { payload }) => {
      state.isDoctorListLoading = payload;
    },
    doctorEditIsLoading: (state, { payload }) => {
      state.isDoctorEditLoading = payload;
    },
    doctorStatusIsLoading: (state, { payload }) => {
      state.isDoctorStatusLoading = payload;
    },
    doctorStatusToggle: (state, { payload }) => {
      state.doctors = state.doctors.map((e: any) => {
        if (e.encrypted_ID === payload) {
          if (e.status === true) {
            return { ...e, status: false };
          } else {
            return { ...e, status: true };
          }
        } else {
          return e;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDoctorListing.fulfilled, (state, { payload }) => {
      state.doctors = payload?.data.map((e: any, i: any) => ({ id: i, ...e }));
    });
    builder.addCase(getDoctorData.fulfilled, (state, { payload }) => {
      state.editeDoctorData = payload;
    });
    builder.addCase(deleteDoctor.fulfilled, (state, { payload }) => {});
    builder.addCase(changeStatus.fulfilled, (state, { payload }) => {});
    // builder.addCase(searchDoctor.fulfilled, (state, { payload }) => {
    //   state.doctors = payload.data.map((e: any, i: any) => ({ id: i, ...e }));
    // });
    builder.addCase(searchDoctor.fulfilled, (state, { payload }: any) => {
      state.totalRecords = payload?.totalRecords;
      state.doctors = payload?.data?.map((e: any, i: number) => ({
        id: i,
        ...e,
      }));
    });
    builder.addCase(getDoctorDetail.fulfilled, (state, { payload }) => {
      state.DoctorDetails = payload.result.doctorDetails;
      state.ClinicsAndServices = payload.result.clinicsAndServices;
      state.AgreementDetailInformation =
        payload.result.agreementDetailInformation;
    });
  },
});

export const DoctorSliceReducer = DoctorSlice.reducer;
export const {
  clearDoctors,
  doctorStatusToggle,
  doctorTableIsLoading,
  doctorEditIsLoading,
  doctorStatusIsLoading,
  doctorListIsLoading,
  doctorPersonalInfoLoading,
  passwordIsUpdating,
} = DoctorSlice.actions;
