import { createAsyncThunk } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";
import {
  SignoutTrigger,
  toggleLoader,
  toggleSnacker,
} from "../domian/app/AppSlice";
import {
  LOGIN,
  REFRESH_TOKEN,
  FORGOT_PWD,
  RESET_PWD,
  VERIFY_PWD_LINK,
} from "./ActionConstants";
import API from "./Api.utils";
import { LOGIN_URL } from "./Constant";
import { ResetPasswordPayload } from "../pages/resetPassword/ResetPassword";

export const openLoaderPayload = {
  isLoading: true,
};
export const closeLoaderPayload = {
  isLoading: false,
};

export const successOpen = (msg: string) => {
  return {
    isOpen: true,
    variant: "success",
    message: msg ? msg : "successfully done ...!",
  };
};
export const errorOpen = (msg: string) => {
  return {
    isOpen: true,
    variant: "error",
    message: msg ? msg : "err done ...!",
  };
};
export const closeSnackbar = {
  isOpen: false,
  variant: "",
  message: "",
};

export const authLogin = createAsyncThunk(
  LOGIN,
  async (postData: object, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(LOGIN_URL, postData, { signal });
      // logic to get data info
      const decoded = jwt_decode(response.data.token);
      console.log("decoded data", decoded);

      const { fullName, Role, ID }: any = decoded;
      const payloadData: any = {
        userInfo: { fullName, Role, ID },
        authToken: response.data.token,
        refreshToken: response.data.refreshToken,
        expiryTime: response.data.expires_in,
      };

      dispatch(toggleSnacker(successOpen("Successfully Logged In")));
      return payloadData;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error, please try again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
export const refreshUserData = createAsyncThunk(
  REFRESH_TOKEN,
  async (refreshData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      // dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(
        "Authentication/refreshToken",
        refreshData,
        { signal }
      );
      // logic to get data info
      const decoded = jwt_decode(response.data.token);

      const { fullName, Role, ID }: any = decoded;
      const payloadData: any = {
        userInfo: { fullName, Role, ID },
        authToken: response.data.token,
        refreshToken: response.data.refreshToken,
        expiryTime: response.data.expires_in,
      };
      // dispatch(toggleSnacker(successOpen("Sucessfully refreshed token")));
      return payloadData;
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error while refreshing token...!"
          )
        )
      );
      dispatch(SignoutTrigger());
      // dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      // dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const forgotPassword = createAsyncThunk(
  FORGOT_PWD,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(
        `Account/ForgotPassword`,
        postData.values,
        {
          signal,
        }
      );
      const data = response.data;

      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Sent email successfully"
          )
        )
      );

      postData.callback();
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error, please try again"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const resetPassword = createAsyncThunk(
  RESET_PWD,
  async (postData: ResetPasswordPayload, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(`Account/ResetPassword`, postData.data, {
        signal,
      });
      const data = response.data;
      postData.callback();
      dispatch(
        toggleSnacker(
          successOpen(
            data?.responseMessage
              ? data?.responseMessage
              : "Password updated successfully"
          )
        )
      );
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error please try again in sometime"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);

export const verifyResetPasswordLink = createAsyncThunk(
  VERIFY_PWD_LINK,
  async (postData: any, thunkApi) => {
    const { rejectWithValue, signal, dispatch } = thunkApi;
    try {
      dispatch(toggleLoader(openLoaderPayload));
      const response = await API.post(
        `Account/CheckPasswordLinkValidity`,
        {
          encrypted_Request_Id: postData.data,
        },
        {
          signal,
        }
      );
      if (response.data.result === false) {
        // dispatch(toggleSnacker(errorOpen("Link Expired!")));
        return true;
      } else {
        dispatch(toggleSnacker(successOpen("Link Verified!")));
      }
    } catch (err: any) {
      dispatch(
        toggleSnacker(
          errorOpen(
            err?.response?.data?.responseMessage
              ? err?.response?.data?.responseMessage
              : "Error please try again in sometime"
          )
        )
      );
      dispatch(toggleLoader(closeLoaderPayload));
      return err;
    } finally {
      dispatch(toggleLoader(closeLoaderPayload));
    }
  }
);
