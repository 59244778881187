import { FieldProps } from "formik";
import { OptionsType, ValueType } from "react-select/lib/types";

import { useState } from "react";

import {
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";

interface Option {
  label?: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  options: OptionsType<Option>;
  isMulti?: boolean;
  className?: string;
  placeholder?: string;
}

export const CustomSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
}: CustomSelectProps) => {
  const onChange = (option: ValueType<Option>) => {
    const isDataAvilable =
      getValue()
        .map((e: any) => e.value)
        .indexOf((option as Option).value) > -1;
    if (isDataAvilable) {
      const data = getValue().filter(
        (e: any) => e.value !== (option as Option).value
      );
      setIsSelectAll(false);
      form.setFieldValue(
        field.name,
        isMulti ? data?.map((item: Option) => item.value) : data[0].value
      );
    } else {
      form.setFieldValue(
        field.name,
        isMulti
          ? [...getValue(), option]?.map((item: Option) => item.value)
          : (option as Option).value
      );
    }
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option: any) => field.value.indexOf(option.value) >= 0
          )
        : options.find((option: any) => option.value === field.value);
    } else {
      return isMulti ? [] : ("" as any);
    }
  };
  const [isSelectAll, setIsSelectAll] = useState(false);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const updateAllItems = () => {
    setIsSelectAll((ps): any => !ps);
    if (!isSelectAll) {
      form.setFieldValue(
        field.name,
        isMulti ? options?.map((item: Option) => item.value) : options[0].value
      );
    } else {
      form.setFieldValue(field.name, []);
    }
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel
        id="demo-multiple-checkbox-label"
        style={{ backgroundColor: "white" }}
      >
        Services
      </InputLabel>
      <Select
        className={className}
        name={field.name}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        placeholder={placeholder}
        multiple
        value={getValue()}
        size="medium"
        renderValue={(selected) => selected.map((e: any) => e.label).join(", ")}
        MenuProps={MenuProps}
      >
        <Box mt={1.5}>
          <MenuItem value={"selectAll"} onClick={updateAllItems}>
            <Checkbox checked={isSelectAll} />
            <ListItemText primary={"Select All"} />
          </MenuItem>
          {options?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => onChange(option)}
            >
              <Checkbox
                checked={
                  getValue()
                    .map((e: any) => e.label)
                    .indexOf(option.label) > -1
                }
              />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Box>
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
